import { ACCESS_TOKEN, getItem } from "local-storage";
import { getUserProfileData } from "services/apis/apis";
import useSWR from "swr";
import { useSnapshot } from "valtio";
import { navigateTab, setTab } from "proxy-state/global";
import { useEffect } from "react";

export const useController = () => {
  const snapNavigateTab = useSnapshot(navigateTab);
  const currentTab = snapNavigateTab.currentTab.Notification.currentTab;
  const accessToken = getItem(ACCESS_TOKEN) || "";

  const handleChangeTab = (value: string) => {
    setTab("Notification", value);
  };

  const { data } = useSWR(["getUserProfileData"], async () => {
    if (!accessToken) return;
    return getUserProfileData(accessToken);
  });

  return { currentTab, handleChangeTab, state: { id: data?.id } };
};
