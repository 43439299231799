import { useEffect, useState } from "react";

const SCREENS = ["xs", "sm", "tl", "md", "lg", "xl", "2xl"];
const SCREENS_WIDTH = [0, 480, 520, 768, 1023, 1280, 1536];

export const getScreenName = (w: any) => {
  let i = SCREENS?.length - 1;
  while (i > 0 && w < SCREENS_WIDTH[i]) i--;
  return SCREENS[i];
};

export const Responsive = ({ from, upto, to, children }: any) => {
  const [screen, setScreen] = useState(0);

  // setup monitor ww
  useEffect(() => {
    const onResize = () => {
      // setWw(window.innerWidth)
      const w = window.innerWidth;
      let i = 0;
      while (i < SCREENS?.length - 1 && w >= SCREENS_WIDTH[i + 1]) i++;
      setScreen(i);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  let isValid = false;
  if (
    from &&
    to &&
    SCREENS.indexOf(to) > screen &&
    SCREENS.indexOf(from) <= screen
  ) {
    // from this down
    isValid = true;
    return isValid ? children : "";
  }
  if (from && !to && SCREENS.indexOf(from) <= screen) {
    // from this up
    isValid = true;
    return isValid ? children : "";
  }
  if (upto && SCREENS.indexOf(upto) >= screen) {
    // from this down
    isValid = true;
    return isValid ? children : "";
  }

  return isValid ? children : "";
};
