import { Loading, NoData } from "components/commons";
import { ACCESS_TOKEN, getItem } from "local-storage";
import slugs from "navigation/slugs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLikedUsersPostId } from "services/apis/apis";
import { currencyFormatter } from "utils/number/number";
import { DEFAULT_IMG } from "config";
import { CloseModal } from "components/commons/Modal/CloseModal";

export const ListLikedUserCard: React.FC<any> = (props) => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const f = async () => {
      const accessToken = getItem(ACCESS_TOKEN) || "";
      if (!accessToken || !props?.id) {
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        const result = await getLikedUsersPostId(props?.id!);
        setData(result);
      } catch (error) {
        console.log("🚀 ~ file: HoldCard.tsx:21 ~ f ~ error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    f();
  }, [props?.id]);

  return (
    <div className="flex flex-col gap-3 px-3 min-h-[50vh] relative">
      {isLoading && <Loading.View />}

      {!isLoading && data && (
        <div className="w-full flex justify-center">
          <h1>Liked</h1>
        </div>
      )}

      {!isLoading && data?.length === 0 && <NoData />}
      <CloseModal onClose={() => props?.onClose()} />

      {!isLoading &&
        data?.map((item: any) => (
          <div
            className="border-b border-dk-gray-800 w-full"
            key={item?.id}
            onClick={(e) => {
              e.preventDefault();
              navigate(`${slugs.profile}/${item?.id}`, {
                state: { from: true },
              });
            }}
          >
            <div
              className={`flex px-4 py-3 gap-4 justify-between items-center `}
            >
              <div className="flex items-center gap-4 w-full">
                <div className="h-8 w-8 shadow-sm rounded-full overflow-hidden flex justify-center items-center">
                  <img
                    className="h-8 w-8 shadow-sm rounded-full object-fill"
                    src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                    alt=""
                  />
                </div>

                <div className="flex-1 flex justify-between items-center">
                  <div className={`text-base font-normal leading-none mr-1`}>
                    <div>{item?.twitter_user?.username ?? "-"}</div>
                    <div className="text-sm text-slate-400 mt-1">
                      {currencyFormatter(item?.current_price)}
                    </div>
                  </div>
                  <div className="text-base font-normal text-slate-300 flex items-center gap-2">
                    {/* <span>{item?.balance ?? 0}</span>
                    <div className="w-6 h-6 rounded-full bg-[#FF1156] border-[3px] border-[#ff4d7f] text-gray-100 text-sm font-bold flex justify-center items-center">
                      A
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
