import { ReactComponent as Gift } from "assets/icons/coming-soon.svg";

export const ReOpenPWA = () => {
  return (
    <div className="p-4 h-[100dvh]">
      <div className="flex flex-col justify-center items-center gap-4 py-6 h-full">
        <div className="gap-2 flex flex-col justify-center items-center">
          <Gift className="w-24" />
          <div className="text-xl text-center">
            Close Stars League and open again!
          </div>
        </div>
      </div>
    </div>
  );
};
