import { proxy } from "valtio";

interface CurrentTab {
  Home:
  | {
    Trending?: {
      subTab?: string;
    };
    SGE?: {
      subTab?: string;
    };
    "3,3": {
      subTab?: string;
    };
    Following?: {
      subTab?: string;
    };
    currentTab: string; // "Top" | "SGE" | "Following"
  }
  | any;

  Leaderboard:
  | {
    Creators?: { subTab: string };
    Traders?: { subTab: string };
    Whales?: { subTab: string };
    Collectors?: { subTab: string };

    currentTab: string; // "Creators" | "Traders" | "Whales" | "Collectors"
  }
  | any;

  RightSlide:
  | {
    currentTab: string; // "Global" | "Feed" | "Trade"
  }
  | any;

  Stakes:
  | {
    currentTab: string; // "All" | "Clubs" | "Players"
  }
  | any;

  Staking:
  | {
    currentTab: string; // "Info" | "Staker" | "Activities"
  }
  | any;

  Search:
  | {
    currentTab: string;
  }
  | any;

  Notification:
  | {
    currentTab: string;
  }
  | any;

  Inbox:
  | {
    currentTab: string;
  }
  | any;

  Profile:
  | {
    currentTab: string;
  }
  | any;

  OtherProfile:
  | {
    currentTab: string;
  }
  | any;
  Feed: { currentTab: string } | any;
  AGE: { currentTab: string } | any;
}

export const tabDefault: CurrentTab = {
  Home: {
    Trending: { subTab: "Daily" },
    SGE: { subTab: "Happening" },
    Following: { subTab: "On X" },
    // "🏆": { subTab: "Creators" },
    "3,3": { subTab: "3,3" },
    currentTab: "Trending",
  },

  Leaderboard: {
    Creators: { subTab: "All" },
    Traders: { subTab: "All" },
    Whales: { subTab: "All" },
    Collectors: { subTab: "All" },
    currentTab: "Creators",
  },

  RightSlide: {
    currentTab: "Trade",
  },

  Stakes: {
    currentTab: "All",
  },

  Staking: {
    currentTab: "Info",
  },

  Search: {
    currentTab: "On Stars League",
  },
  Notification: {
    currentTab: "Activities",
  },
  Inbox: {
    currentTab: "Chat",
  },
  Profile: {
    currentTab: "Posts",
  },
  OtherProfile: {
    currentTab: "Posts",
  },
  Feed: {
    currentTab: "Trending",
  },
  AGE: {
    currentTab: "Live",
  },
};

export const navigateTab = proxy<{ currentTab: CurrentTab }>({
  currentTab: tabDefault,
});

export const setTab = (
  page:
    | "Home"
    | "Leaderboard"
    | "Search"
    | "Notification"
    | "Inbox"
    | "RightSlide"
    | "Profile"
    | "OtherProfile"
    | "Feed"
    | "Stakes"
    | "Staking"
    | "AGE",
  key: any,
  value?: any
) => {
  navigateTab.currentTab[page].currentTab = key;

  if (!value) return;
  navigateTab.currentTab[page][key].subTab = value;
};

export const countdownTimer = proxy<{
  remainingHours: number;
  remainingSeconds: number;
  remainingMinutes: number;

  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}>();

export const setCountDownTimer = (seconds: number) => {
  if (seconds < 0) return;
  --countdownTimer.seconds;

  countdownTimer.seconds = seconds;
  countdownTimer.minutes = Math.floor(seconds / 60);
  countdownTimer.hours = Math.floor(seconds / 3600);
  countdownTimer.days = Math.floor(seconds / 86400);

  countdownTimer.remainingHours = Math.floor(seconds / 3600) % 24;
  countdownTimer.remainingMinutes = Math.floor(seconds / 60) % 60;
  countdownTimer.remainingSeconds = seconds % 60;
};

export const filterProfile = proxy<{
  time: string;
  sorting: string;
}>();

export const setFilterProfile = (key: "time" | "sorting", value: string) => {
  filterProfile[key] = value;
};

export const scrollingProxy = proxy<{
  isScrolling: boolean;
  scrollTop: number;
}>({ isScrolling: false, scrollTop: 0 });

export const checkSgeProxy = proxy<{
  isLive: boolean;
}>({ isLive: false });

export const pointProxy = proxy<{
  totalPoint: number;
  totalEth: number;
  lastClaimedPoint: number;
  lastClaimedEth: number;
}>({ totalPoint: 0, lastClaimedPoint: 0, lastClaimedEth: 0, totalEth: 0 });

export const globalProxy = proxy<{
  ethPrice: number;
  mntPrice: number;
  chzPrice: number;
  currency: "CHZ" | "ETH";
  holders: Array<any>;
  holdings: Array<any>;
  idsMap: {
    [key: string]: {
      userId: string;
      youOwn: boolean;
      theyOwn: boolean;
      status: "3,3" | "3,0" | "0,3" | undefined;
    };
  };
}>({
  ethPrice: 1600,
  mntPrice: 0.4,
  chzPrice: 0.06,
  currency: "CHZ",
  holders: [],
  holdings: [],
  idsMap: {},
});

export enum TabPanel {
  DM = "DM",
  GROUP = "GROUP",
}

export const tabSendbirdProxy = proxy<{ tab: TabPanel }>({
  tab: TabPanel.GROUP,
});
export const unreadInboxProxy = proxy<{ unreadCount: number }>({
  unreadCount: 0,
});
