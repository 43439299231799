import { useLogout } from "@privy-io/react-auth";
import { actions } from "proxy-state/author";

import {
  ACCESS_TOKEN,
  AUTH_ERROR,
  deleteItem,
  getItem,
  saveItem,
} from "local-storage";
import { createContext, useCallback, useContext, useState } from "react";
import Talk from "talkjs";
import OneSignal from "react-onesignal";

const AuthContext = createContext<any>(null);

export function AuthProvider({ children }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginOneSignal, setIsLoginOneSignal] = useState(false);
  const [talkjsSession, setTalkjsSession] = useState<Talk.Session>();

  const { logout } = useLogout();

  const accessToken = getItem(ACCESS_TOKEN);
  const currentError = getItem(AUTH_ERROR);

  const [token, setToken] = useState(accessToken || "");

  const [error, setError] = useState<null | string>(currentError || "");

  const [balance, setBalance] = useState("");

  const updateToken = (newValue: string) => {
    saveItem(ACCESS_TOKEN, newValue);
    setToken(newValue);
  };

  const updateError = useCallback((newValue: string) => {
    saveItem(AUTH_ERROR, newValue);
    setError(newValue);
  }, []);

  const handlelogout = async () => {
    try {
      await logout();
      updateError("");
      setToken("");
      actions.setIsAuth();
      deleteItem();
      await OneSignal.User.PushSubscription.optOut();
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        updateToken,
        error,
        updateError,
        handlelogout,
        isLoading,
        setIsLoading,
        isLoginOneSignal,
        setIsLoginOneSignal,
        setBalance,
        balance,
        talkjsSession,
        setTalkjsSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
