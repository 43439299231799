import { formatEther } from "@ethersproject/units";
import axios from "axios";
import { BigNumber } from "ethers";

const COINGECKO_API = "https://api.coingecko.com/api/v3/simple/price";

export const getMantlePrice = async () => {
  try {
    const result = await axios.get(
      `${COINGECKO_API}?ids=mantle&vs_currencies=usd`
    );
    return result?.data?.mantle?.usd;
  } catch (error) {
    throw error;
  }
};

export const getEthPrice = async () => {
  try {
    const result = await axios.get(
      `${COINGECKO_API}?ids=ethereum&vs_currencies=usd`
    );
    return result?.data?.ethereum?.usd;
  } catch (error) {
    throw error;
  }
};

export const getPrices = async (tokens: Array<"mantle" | "ethereum" | "chiliz">) => {
  const tokenStrings = tokens.join(",");

  try {
    const result = await axios.get(
      `${COINGECKO_API}?ids=${tokenStrings}&vs_currencies=usd`
    );
    return result?.data;
  } catch (error) {
    throw error;
  }
};

export const convertEtherMantleToUsd = async (value: BigNumber) => {
  try {
    const mantlePrice = await getMantlePrice();
    const result = (Number(formatEther(value)) * mantlePrice).toFixed(2);
    return result;
  } catch (error) {
    throw error;
  }
};
