import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const Explore = React.lazy(() => import('../page/Explore'));

const routes: AppRouteType[] = [
  {
    path: slugs.explore,
    component: Explore,
    container: MainLayout,
    isAuthNeeded: true,
  },
];

export default routes;
