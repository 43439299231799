import { currencyFormatterChz } from "utils/number/number";
import { ReactComponent as ChzToken } from "assets/images/CHZ.svg";
import { ReactComponent as ChzTokenMini } from "assets/images/chz-mini.svg";
import clsx from "clsx";

export const Currency = ({
  value,
  textClassName = "text-md font-bold",
  iconClassName = "w-4 h-4",
  isMinimal = false,
}: {
  value?: string;
  textClassName?: string;
  iconClassName?: string;
  isMinimal?: boolean;
}) => {
  return (
    <div
      className={clsx("flex items-center", {
        "gap-1": !isMinimal,
        "": isMinimal,
      })}
    >
      <span className={textClassName}>{currencyFormatterChz(value)}</span>{" "}
      {isMinimal ? (
        <ChzTokenMini className={iconClassName} />
      ) : (
        <ChzToken className={iconClassName} />
      )}
    </div>
  );
};
