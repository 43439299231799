import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const AppDetail = React.lazy(() => import("../page"));

const routes: AppRouteType[] = [
  {
    path: slugs.appDetail,
    component: AppDetail,
    container: MainLayout,
    isAuthNeeded: true,
  },
];

export default routes;
