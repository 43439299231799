import { deepArrayCompare } from "utils/array";
import { proxy } from "valtio";

interface State {
  data: Array<any>;
  page: number;
  updatedAt: Date;
  isLoading: boolean;
  isFirstTimeFetch: boolean;
}

const defaultState: State = {
  data: [],
  page: 1,
  updatedAt: new Date(),
  isLoading: true,
  isFirstTimeFetch: true,
};


const state = proxy<State>(defaultState);

export const recentService = {
  state,
  setData: (data: any) => {
    const isSame = deepArrayCompare(data, state.data);
    if (isSame) return;
    state.data = data;
    state.updatedAt = new Date();
  },
  concatData: (data: any) => {
    const seenIds = new Set();

    const filteredData = state.data.concat(data).filter((item: any) => {
      const currentId = item.id;
      if (!seenIds.has(currentId)) {
        seenIds.add(currentId);
        return true;
      }
      return false; 
    });
    state.data = filteredData;
    state.updatedAt = new Date();
  },
  setIsLoading: (value: boolean) => {
    state.isLoading = value;
  },
  setIsFirstTimeFetch: (value: boolean) => {
    state.isFirstTimeFetch = value;
  },
  getIndex: (index: number) => {
    return state.data[index];
  },
  nextPage() {
    state.page = state.page + 1
  }
};
