import { sharePost } from "utils/document/share-public";

export const SharePostLink = (props: {
  slug: string;
  content: string;
  //   image?: string;
}) => {
  const content =
    props?.content.length > 210 ? props?.content.slice(0, 210) : props.content;

  return (
    <div
      className="px-4 py-2.5 text-left flex items-center justify-between cursor-pointer gap-2"
      onClick={(e) => {
        e.stopPropagation();
        if (!props.slug) return;
        window.open(sharePost(content, props.slug));
      }}
    >
      <button className="">Share on</button>
      <i className="fa-brands fa-twitter w-4 h-4"></i>
    </div>
  );
};
