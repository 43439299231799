import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Logo from "assets/icons/fantechLogoDesk.svg";
import Logo from "assets/icons/st-logo-small.svg";
import { navigations } from "helpers/constants";
import avatarDefault from "assets/images/fake-topstake/avatar/avatar1.png";
import slugs from "navigation/slugs";
import clsx from "clsx";
import { Currency } from "../Currency";
import { PlusSmallIcon, WalletIcon } from "@heroicons/react/24/outline";
import { useAuth } from "contexts/authContext";
import { usePrivy } from "@privy-io/react-auth";
import { pointProxy } from "proxy-state/global";
import { getUserProfileData } from "services/apis/apis";
import { ACCESS_TOKEN, getItem } from "local-storage";
import { redirect } from "react-router";
import { profileService } from "proxy-state/user/profile";
import { useSnapshot } from "valtio";

export const TabletNavigation: React.FC = () => {
  const pathName = useLocation().pathname;
  const profileState = useSnapshot(profileService.state);
  const { updateToken } = useAuth();
  const { authenticated, user, logout, sendTransaction } = usePrivy();
  const navigate = useNavigate();

  // Main Navigation
  const mainNavClass =
    "flex flex-col w-full h-full bg-transparent border-r border-dk-gray-800";
  const navItemClass =
    "flex items-center pl-3 pl-2 py-3 text-base w-full rounded-box transition mb-3";
  const navItemNormalClass =
    "font-medium text-slate-500 hover:text-gray-100 hover:bg-base-content/20";
  const navItemActiveClass =
    "font-semibold bg-base-content/10 text-gray-100 hover:bg-base-content/20 ";

  // useEffect(() => {
  //   if (!authenticated) {
  //     updateToken("");
  //     redirect(slugs.login);
  //   }
  // }, [authenticated, updateToken]);

  useEffect(() => {
    const f = async () => {
      try {
        const accessToken = getItem(ACCESS_TOKEN) || "";
        if (!accessToken) return;
        const res = await getUserProfileData(accessToken);
        pointProxy.totalPoint = res?.point || 0;
        pointProxy.lastClaimedPoint = res?.last_claimed_point || 0;
        pointProxy.totalEth = res?.total_reward || 0;
        pointProxy.lastClaimedEth = res?.last_claimed_reward || 0;
        profileService.setData(res);
        profileService.setIsLoading(true);

        if (profileState.isFirstTimeFetch) {
          profileService.setIsFirstTimeFetch(false);
        }
      } catch (error) {
      } finally {
        profileService.setIsLoading(false);
      }
    };
    f();
  }, []);

  return (
    <>
      <nav className={mainNavClass}>
        <div className="mt-6 mb-6">
          <Link to={slugs.home} className="flex items-center justify-center">
            <img src={Logo} />
          </Link>
        </div>

        {/* Main Nav */}
        <div className="flex flex-col flex-1 p-4">
          {navigations.map((item, idx) => (
            <Link
              className={`${navItemClass} ${
                item.href === pathName ? navItemActiveClass : navItemNormalClass
              }`}
              key={idx}
              to={item.href}
            >
              <i
                className={clsx(`${item.iconSolid}`, {
                  "text-secondary": pathName === item.href,
                  "text-gray-500": pathName !== item.href,
                })}
              />
              {/* <span className="whitespace-nowrap ml-4">{item.name}</span> */}
            </Link>
          ))}
          {pathName !== slugs.upload && (
            <div className="mt-1 flex pl-[5px]">
              <button
                className="w-fit bg-secondary flex items-center justify-center p-1.5 text-base rounded-lg font-bold"
                onClick={() =>
                  navigate(slugs.upload, {
                    state: { from: true },
                  })
                }
              >
                <PlusSmallIcon className="w-5 h-5 text-gray-100" />
              </button>
            </div>
          )}
        </div>
        {profileState?.isLoading || profileState?.isFirstTimeFetch ? (
          <div>Loading</div>
        ) : (
          <div className="flex flex-col gap-2 pb-4 px-2">
            <div
              onClick={(e) => {
                e.preventDefault();
                navigate(`${slugs.profile}`, {
                  state: {
                    from: true,
                  },
                });
              }}
              className="p-3 border border-dk-gray-800 rounded-lg flex justify-center"
            >
              <img
                className="min-w-[24px] h-6 w-6 shadow-sm rounded-full object-cover"
                src={
                  profileState?.data?.twitter_user?.metadata?.image_url ||
                  avatarDefault
                }
                alt="avatar"
              />
            </div>
            <div
              className="p-3 border border-dk-gray-800 rounded-lg flex justify-center"
              onClick={(e) => {
                e.preventDefault();
                navigate(slugs.profileBalance);
              }}
            >
              <WalletIcon className="h-5 w-5 text-gray-500" />
            </div>
          </div>
        )}

        {/* //Main Nav */}
      </nav>
    </>
  );
};
