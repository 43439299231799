import { ReactComponent as Speaker } from "assets/icons/speaker.svg";

export const NoData = ({ text = "Nothing Found!" }: { text?: string }) => {
  return (
    <div className="flex flex-col flex-1 justify-center items-center gap-4 bg-dk-gray-900">
      <Speaker className="w-24" />
      <div className="space-y-2 flex flex-col">
        <span className="text-xl text-center">{text}</span>
      </div>
    </div>
  );
};
