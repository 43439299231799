export const testnet = {
  id: 88882,
  name: "Chiliz Testnet",
  network: "chiliz",
  nativeCurrency: {
    decimals: 18,
    name: "CHZ",
    symbol: "CHZ",
  },
  rpcUrls: {
    default: {
      http: ["https://chiliz-spicy.publicnode.com"],
    },
    public: {
      http: ["https://chiliz-spicy.publicnode.com"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Chiliz Explorer",
      url: "https://spicy-explorer.chiliz.com",
    },
    default: {
      name: "Chiliz Explorer",
      url: "https://spicy-explorer.chiliz.com",
    },
  },
  testnet: true,
};

export const mainnet = {
  id: 88888,
  name: "Chiliz",
  network: "chiliz",
  nativeCurrency: {
    decimals: 18,
    name: "CHZ",
    symbol: "CHZ",
  },
  rpcUrls: {
    default: {
      http: [
        "https://rpc.ankr.com/chiliz/797c5fea87e4fa1901c9ec987d49cc4d92590c3d14a8482391240b1694ad29db",
      ],
    },
    public: {
      http: [
        "https://rpc.ankr.com/chiliz/797c5fea87e4fa1901c9ec987d49cc4d92590c3d14a8482391240b1694ad29db",
      ],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "Chiliz Explorer",
      url: "https://scan.chiliz.com",
    },
    default: {
      name: "Chiliz Explorer",
      url: "https://scan.chiliz.com",
    },
  },
};
