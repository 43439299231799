import baseAPI from "core/axios/baseAPI";
import { AxiosRequestConfig } from "axios";
import slugs, { ignoreAuthPaths } from "navigation/slugs";
import { actions } from "proxy-state/author";
// import { NavigateFunction } from "react-router-dom";

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export let redirectApp: any = () => {};

async function callAPI(
  method: Method,
  url: string,
  config?: AxiosRequestConfig,
  onSuccess: (data?: any, status?: number) => void = () => {},
  onFailure: (data?: any, status?: number) => void = () => {},
  optionsCustom: any = {},
  isTryCall: boolean = false
) {
  try {
    optionsCustom?.navigate && (redirectApp = optionsCustom.navigate);
    const { data, status } = await baseAPI.default(method, url, config, isTryCall);
    switch (status) {
      case 200:
      case 201:
        onSuccess(data, status);
        if (ignoreAuthPaths.includes(window.location.pathname)) break;

        if (data?.status === "USER_IS_NOT_LINKED_TW") {
          redirectApp(slugs.accountVerify);
          break;
        }
        if (data?.status === "USER_IS_NOT_INVITED") {
          redirectApp(data?.next_url);
          break;
        }
        break;
      default:
        onFailure(data, status);
        break;
    }
  } catch (error: any) {
    // @ts-ignore
    const statusCode = error?.response?.data?.statusCode;
    switch (statusCode) {
      case 401:
        // window.localStorage.clear();
        actions.setIsAuth();
        break;
      case 403:
        if (
          error?.response?.data?.message === "User's not enter invite code yet!"
        ) {
          redirectApp(slugs.inviteCode);
        }
        break;
      default:
        console.error("func callAPI [Axios call api]", error);
        break;
    }
    onFailure(error, 500); // You can pass a default status code here
  }
}

const coreAction = {
  method: Method,
  callAPI,
};

export default coreAction;
