import clsx from "clsx";
// import { feedState } from "proxy-state/feed";
// import { navigateTab, setTab } from "proxy-state/global";
// import React, { useEffect, useMemo } from "react";
// import { getRecentPost } from "services/apis/apis";
// import { useSnapshot } from "valtio";
import Global from "./components/Global";
import Trade from "./components/Trade";
import Feed from "./components/Feed";
import { useController } from "./Controller";

export function RightSubContent() {
  const { currentTab, handleChangeTab, state } = useController();
  const tabs = [{ name: "Global" }, { name: "Feed" }, { name: "Trade" }];

  return (
    <div className="border_right_content h-[100dvh] overflow-y-auto">
      <div className="flex-1 flex flex-col bg-base-300 px-1">
        {/* <div className="flex justify-around items-center gap-6 border-b border-dk-gray-800 text-center px-4 ">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              className="whitespace-nowrap text-center rounded text-base text-slate-300 font-semibold cursor-pointer flex-1 flex flex-col gap-3 pt-3"
              onClick={() => {
                handleChangeTab(tab.name);
              }}
            >
              <div
                className={clsx("", {
                  "text-white font-semibold": tab.name === currentTab,
                  "": tab.name !== currentTab,
                })}
              >
                {tab.name}
              </div>{" "}
              <div
                className={clsx("h-1 w-full rounded", {
                  "bg-secondary": tab.name === currentTab,
                  "bg-transparent": tab.name !== currentTab,
                })}
              ></div>
            </div>
          ))}
        </div> */}
        <h4 className="p-4 pb-4 text-2xl font-medium">Trade</h4>
        {/* 
        {currentTab === "Global" && <Global datas={state?.recentState?.data} />}
        {currentTab === "Feed" && <Feed datas={state?.hotState?.data} />} */}
        {currentTab === "Trade" && <Trade datas={state?.activeState?.data} />}
      </div>
    </div>
  );
}
