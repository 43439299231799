import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ACCESS_TOKEN, getItem } from "local-storage";

// Init axios
const axiosClient = axios.create({
    // withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,   
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getItem(ACCESS_TOKEN)}`
        // 'Access-Control-Allow-Origin': '*',
    },
});

//interceptors
// Add a request interceptors
axiosClient.interceptors.request.use(
    // @ts-ignore
    function (config: AxiosRequestConfig) {
        // Do something before request is send
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

// Add a response interceptors
axiosClient.interceptors.response.use(
    // @ts-ignore
    function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return {
            data: response.data['data'],
            status: response.status,
        };
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    },
);

export default axiosClient;