import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const ProfileBalance = React.lazy(() => import('../page/ProfileBalance'));

const routes: AppRouteType[] = [
  {
    path: slugs.profileBalance,
    component: ProfileBalance,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
];

export default routes;
