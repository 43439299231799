import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

export const SearchBar = ({
  setSearch,
  handleKeyDown,
  handleSearch,
}: {
  search: string;
  setSearch: (value: string) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearch: () => void;
}) => {
  return (
    <div className="w-full py-3 border-b border_right_content bg-base-300">
      <div className="py-2 px-5 flex items-center gap-2">
        <MagnifyingGlassIcon
          className="w-5 h-5 text-gray-500"
          onClick={(e) => {
            e.preventDefault();
            handleSearch();
          }}
        />
        <input
          className="flex-1 bg-transparent border-none focus:outline-none focus:ring-0 pl-4 h-6 p-0 text-base text-gray-100 placeholder:text-base placeholder:text-gray-500"
          placeholder="Search for profile"
          type="text"
          onChange={(e) => {
            e.preventDefault();
            setSearch(e.currentTarget.value);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};
