import axios from "axios";
import { actions } from "proxy-state/author";
import { redirect } from "react-router-dom";

const customAxios = axios.create();

customAxios.interceptors.response.use(
  (response) => response, // If the response is successful, pass it through
  (error) => {
    if (error.response?.data && error.response.data?.statusCode === 401) {
      // Handle the 401 error and redirect to the login page
      // You can use your routing library's navigation function here
      // For example, if using React Router:
      actions.setIsAuth();
      return;
    }

    if (
      error.response?.data &&
      error.response.data.statusCode === 403 &&
      error.response.data?.message === "User's not enter invite code yet!"
    ) {
      // Handle the 401 error and redirect to the login page
      // You can use your routing library's navigation function here
      // For example, if using React Router:
      redirect("/invite-code");
      return;
    }

    return Promise.reject(error); // Reject the promise to propagate the error
  }
);

export { customAxios };
