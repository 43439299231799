import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import { MainLayoutContent } from "components/layouts/MainLayoutContent";

import React from "react";
import RedirectFeedMetaTag from "../page/RedirectFeed";
const FeedDetail = React.lazy(() => import("../page/FeedDetail"));

const routes: AppRouteType[] = [
  {
    path: slugs.feedDetail,
    component: FeedDetail,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.feedDetail,
    component: FeedDetail,
    container: React.Fragment,
    isAuthNeeded: false,
  },
  // {
  //   path: slugs.feedDetailMetaSEO,
  //   component: RedirectFeedMetaTag,
  //   container: React.Fragment,
  //   isAuthNeeded: false,
  // },
  // {
  //   path: slugs.feedDetailMetaSEO,
  //   component: RedirectFeedMetaTag,
  //   container: React.Fragment,
  //   isAuthNeeded: true,
  // },
];

export default routes;
