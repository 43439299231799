// @ts-nocheck
import React, { lazy, useState, cloneElement } from "react";
import {
  LoadingMedium,
  LoadingSmallDefault,
  LoadingItemLeaderboard,
  LoadingMediumStake,
  LoadingPost,
  LoadingItemActivity,
} from "./LoadingType";

const LoadingDefault = lazy(() => import("./LoadingDefault"));
const LoadingLogout = lazy(() => import("./LoadingLogo"));

export enum TypeLoading {
  LOGO = "LOADING_LOGO",
  DEFAULT = "LOADING_DEFAULT",
  MEDIUM_DEFAULT = "MEDIUM_DEFAULT",
  SMALL_DEFAULT = "SMALL_DEFAULT",
  ITEM_LEADERBOARD = "ITEM_LEADERBOARD",
  ITEM_ACTIVITY = "ITEM_ACTIVITY",
  LOADING_MEDIUM_STAKES = "LOADING_MEDIUM_STAKES",
  LOADING_POST = "LOADING_POST",
}
interface Props {
  type?: TypeLoading;
  className?: string;
  limit?: number;
  ComponentWrapper?: any;
}

export function Loading(props: Props) {
  const { type, limit, ComponentWrapper, ...otherProps } = props;
  const [array]: any[] = useState(() => {
    const ListLoading = [];
    const len = limit || 1;
    // @ts-ignore
    for (let i = 0; i < len; i++) {
      ListLoading.push(i);
    }
    return ListLoading;
  });
  let LoadingView: any;
  switch (type) {
    case TypeLoading.LOGO:
      LoadingView = LoadingLogout;
      break;
    case TypeLoading.SMALL_DEFAULT:
      LoadingView = LoadingSmallDefault;
      break;
    case TypeLoading.LOADING_MEDIUM_STAKES:
      LoadingView = LoadingMediumStake;
      break;
    case TypeLoading.ITEM_LEADERBOARD:
      LoadingView = LoadingItemLeaderboard;
      break;
    case TypeLoading.ITEM_ACTIVITY:
      LoadingView = LoadingItemActivity;
      break;
    case TypeLoading.MEDIUM_DEFAULT:
      LoadingView = LoadingMedium;
      break;
    case TypeLoading.LOADING_POST:
      LoadingView = LoadingPost;
      break;
    case TypeLoading.DEFAULT:
    default:
      LoadingView = LoadingDefault;
      break;
  }

  const Wrapper = ComponentWrapper || <React.Fragment />;
  return cloneElement(Wrapper, {
    children: array.map((_, index) => (
      <LoadingView key={index} {...otherProps} />
    )),
  });
}

export default Loading;
