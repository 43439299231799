import { MainNavigation, Responsive } from "components/commons";
import { LandingPage } from "components/commons/LandingPage";
import React from "react";
import { useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { IS_DISABLE_PWA } from "config";
interface Props {
  children: React.ReactNode;
}

export const MainLayoutSimple: React.FC<Props> = ({ children }) => {
  // const isInStandaloneMode =
  //   IS_DISABLE_PWA || window.matchMedia("(display-mode: standalone)").matches;
  const { pathname } = useLocation();

  return (
    <>
      <div className="main-layout--wrapper">
        <div
          className={`flex flex-col justify-start items-stretch text-gray-100 h-full w-full`}
        >
          <div
            className={`flex flex-col justify-start items-stretch w-full h-full text-gray-100 overflow-hidden relative ${
              isIOS ? "pb-3" : "pb-2"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
      {/* <LandingPage /> */}
    </>
  );
};
