import React from "react";

interface Props {
  children: React.ReactNode;
}

export const MainLayoutPublib2: React.FC<Props> = ({ children }) => {
  return (
    <div className="main-layout--wrapper min-h-[100dvh] min-w-screen lg:pb-0 bg-base-300 overflow-auto z-50">
      <div className="flex flex-col items-center justify-center w-full h-full transition-all">
        {/* Content Area */}
        <div
          className={`flex-1 flex flex-col items-center justify-center min-h-[100dvh] text-gray-100 overflow-auto`}
        >
          {children}
        </div>
        {/* //Content Area */}
      </div>
    </div>
  );
};
