import {
  ArrowLeftIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import slugs from "navigation/slugs";
import React, { ReactNode } from "react";
import { ReactComponent as ChzToken } from "assets/images/CHZ.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "contexts/authContext";
interface Props {
  title?: string;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  className?: string;
  titleClassName?: string;
  customTitle?: ReactNode;
  handleBack?: () => void;
}
export const Breadcum = ({
  title,
  iconRight,
  iconLeft,
  className = "bg-dk-gray-900",
  customTitle,
  titleClassName,
  handleBack,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { balance } = useAuth();

  return (
    <div
      className={`flex flex-wrap justify-between items-center w-full gap-4 py-2 md:py-4 px-4 ${className}`}
    >
      <div className="flex-none cursor-pointer">
        {iconLeft || (
          <ArrowLeftIcon
            className="w-8 h-8 p-1"
            onClick={() => {
              if (handleBack) {
                handleBack();
                return;
              }

              if (!location.state?.from) {
                navigate(slugs.home);
              } else {
                navigate(-1);
              }
            }}
          />
        )}
      </div>
      {customTitle ? (
        customTitle
      ) : (
        <div className={`grow text-left text-xl font-medium ${titleClassName}`}>
          {title}
        </div>
      )}
      <div className="flex-none">
        {iconRight ? (
          iconRight
        ) : (
          <div
            className="text-sm flex items-center font-bold px-3 py-1.5 rounded-full bg-dk-gray-800"
            onClick={() => navigate(slugs.profileBalance)}
          >
            {balance} &nbsp;&nbsp; <ChzToken />
          </div>
        )}
      </div>
    </div>
  );
};
