import { IUserLiveInfo, IUserLiveInfo2 } from "services/apis/types";
import { cookies } from "./universal-cookie";

const KEY = "live-info";

export function setToCookie(info: IUserLiveInfo | IUserLiveInfo2) {
  cookies.set(KEY, info);
}

export function getFromCookie(): IUserLiveInfo | undefined {
  return cookies.get<IUserLiveInfo>(KEY);
}

export function getFromCookie2(): IUserLiveInfo2 | undefined {
  return cookies.get<IUserLiveInfo2>(KEY);
}
