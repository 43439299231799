import { deepArrayCompare } from "utils/array";
import { proxy } from "valtio";

interface State {
    data: Array<any>;
    updatedAt: Date;
    isLoading: boolean;
    isFirstTimeFetch: boolean;
}

const defaultState: State = {
    data: [],
    updatedAt: new Date(),
    isLoading: true,
    isFirstTimeFetch: true,
};

const state = proxy<State>(defaultState);

export const activeService = {
    state,
    setData: (data: any) => {
        const isSame = deepArrayCompare(data, state.data);
        if (isSame) return;
        state.data = data;
        state.updatedAt = new Date();
    },
    setIsLoading: (value: boolean) => {
        state.isLoading = value;
    },
    setIsFirstTimeFetch: (value: boolean) => {
        state.isFirstTimeFetch = value;
    },
    getIndex: (index: number) => {
        return state.data[index];
    },
};
