import clsx from "clsx";
import { PROFILE_ID, getItem } from "local-storage";
import { globalProxy } from "proxy-state/global";
import { useSnapshot } from "valtio";

export const BbChip = (props?: { userId?: string }) => {
  const { idsMap } = useSnapshot(globalProxy);

  const item = idsMap[props?.userId as any];

  const profileId = getItem(PROFILE_ID);

  if (item?.userId === profileId) return null; /// your self

  if (!item?.status) return null;

  return (
    <div
      className={clsx("text-xs px-1 rounded-lg text-gray-100", {
        "bg-[#1BC57E]": item.status === "3,3",
        "bg-slate-500": item.status === "3,0" || item.status === "0,3",
      })}
    >
      {item.status}
    </div>
  );
};
