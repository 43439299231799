import slugs from "navigation/slugs";
import {
  VideoCameraIcon,
  GiftIcon,
  HomeIcon,
  FireIcon,
  Square3Stack3DIcon,
  InboxIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import { ReactComponent as Live } from "assets/icons/live.svg";

export const navigations = [
  {
    name: "Home",
    href: slugs.home,
    icon: HomeIcon,
    iconSolid: "fa-duotone fa-house",
  },

  {
    name: "Profile",
    href: slugs.searchUser,
    icon: UserGroupIcon,
    iconSolid: "fa-duotone fa-users",
  },

  {
    name: "Posts",
    href: slugs.feed,
    icon: FireIcon,
    iconSolid: "fa-duotone fa-fire",
  },

  {
    name: "AGE",
    href: slugs.age,
    icon: UserIcon,
    iconSolid: "fa-duotone fa-hammer-crash",
  },

  // {
  //   name: "Stakes",
  //   href: slugs.stakes,
  //   icon: UserIcon,
  //   iconSolid: "fa-duotone fa-hands-holding-dollar",
  // },

  {
    name: "Leaderboard",
    href: slugs.leaderboard,
    icon: UserIcon,
    iconSolid: "fa-duotone fa-trophy-star",
  },

  {
    name: "Inbox",
    href: slugs.inbox,
    icon: InboxIcon,
    iconSolid: "fa-duotone fa-message-dots",
  },

  {
    name: "Nofitications",
    href: slugs.notification,
    icon: Square3Stack3DIcon,
    iconSolid: "fa-duotone fa-bell",
  },

  // {
  //   name: "App",
  //   href: slugs.myApp,
  //   icon: Square3Stack3DIcon,
  //   iconSolid: "fa-duotone fa-layer-group",
  // },

  {
    name: "Setting",
    href: slugs.settings,
    icon: Square3Stack3DIcon,
    iconSolid: "fa-duotone fa-gear",
  },
];

export const navMobile = [
  {
    name: "Home",
    href: slugs.home,
    icon: HomeIcon,
    iconSolid: "fa-duotone fa-house",
  },

  {
    name: "Posts",
    href: slugs.feed,
    icon: FireIcon,
    iconSolid: "fa-duotone fa-fire",
  },

  {
    name: "Inbox",
    href: slugs.inbox,
    icon: InboxIcon,
    iconSolid: "fa-duotone fa-message-dots",
  },

  {
    name: "Profile",
    href: slugs.profile,
    icon: UserIcon,
    iconSolid: "fa-duotone fa-user",
  },
];
export const uploadFeatures = [
  { name: "Videos", icon: VideoCameraIcon },
  { name: "Perks", icon: GiftIcon },
  { name: "Livestream", icon: Live },
];

export const tabs = [
  { name: "Posts" },
  { name: "Perks" },
  // { name: "Chat" },
  { name: "Activities" },
  { name: "Trades" },
];
export const tabsAge = [{ name: "Live" }, { name: "Recent" }];

export const activityTabs = [
  { name: "Activities" },
  { name: "Trades" },
  { name: "Holders" },
  { name: "Holdings" },
];

export const inboxTabs = [
  { name: "Chat" },
  { name: "Activities" },
  { name: "Subscribed" },
  { name: "System" },
];

export const pointRewards = [
  // {
  //   action: "🫂 Every new 3,3",
  //   point: "1-10",
  //   type: "fi",
  //   isHot: true,
  //   isTooltip: true,
  // },
  {
    action: "💸 Every winning bid (min. 5 ✺)",
    point: "1-10",
    type: "fi",
  },
  { action: "💵 Every 5 trades (min. 10 ✺)", point: "1-5", type: "fi" },
  // {
  //   action: "🔔 Daily balance of min. 1,000 ✺ maintained",
  //   point: "1-10",
  //   type: "fi",
  // },
  // {
  //   action: "🔔 Daily 10,000 ✺ subscribed for SGE",
  //   point: "1",
  //   type: "fi",
  // },
  { action: "🏦 Every 500 in ✺ trading volume", point: "1", type: "fi" },
  { action: "🛍 Every new holding", point: "1", type: "fi" },
  { action: "👭 Every new holder", point: "1", type: "fi" },
  {
    action: "🤝 Every new invitee",
    point: "1",
    type: "social",
  },
  {
    action: "👨‍👩‍👧‍👦 Every new subscriber",
    point: "1",
    type: "social",
  },
  { action: "📝 Daily new post", point: "1", type: "social" },
  { action: "✅ Daily check-in", point: "1", type: "social" },
  {
    action: "💬 Daily 20 messages in your fandom",
    point: "1",
    type: "social",
  },
  // {
  //   action: "🗣 Daily 5 messages in others fandom",
  //   point: "1",
  //   type: "social",
  // },
];

export const homeTabs = [
  { name: "Trending" },
  { name: "SGE" },
  { name: "3,3" },
  // { name: "Upcoming" },
  { name: "Following" },

  // { name: "🏆" },
];

export const exploreTabs = [
  { name: "On Stars League" },
  { name: "On X" },

  // { name: "New" },
];

export const feedTabs = [
  { name: "Trending" },
  { name: "For you" },
  // { name: "3,3" },
  { name: "Now" },
  // { name: "New" },
];

export const maxFileSize = Math.pow(5, 24);

export const maxDesCharacter = 280;
export const maxDes1kCharacter = 1000;

export const viewMethods = [
  {
    id: "onlyHolder",
    title: "Accessholders",
    icon: "fa-regular fa-user-check mr-1",
    des: "People who own your access, now or in the future.",
  },
  {
    id: "everyone",
    title: "Everyone",
    icon: "fa-regular fa-earth-americas mr-1",
  },
];

export const replaceLinkToTagA = (str: string) => {
  const linkRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)+/g;

  function replacer(matched: string) {
    let withProtocol = matched;

    if (!withProtocol.startsWith("http")) {
      withProtocol = "http://" + matched;
    }

    const newStr = `<a class="text-secondary underline underline-offset-1" href="${withProtocol}" onClick="(function(e){
     e.stopPropagation();
  })(event);">${matched}</a>`;

    return newStr;
  }
  return str?.replace(linkRegex, replacer);
};
export const scheduleSGEs = [1, 3, 6, 12, 24, 72];

export const dmAppId = "a775c5ee-603a-4c74-b11b-b8459614458b";

// export const notificationTabs = [
//   { name: "Activities" },
//   { name: "Subscribed" },
//   // { name: "System" },
// ];

export const notificationTabs = [
  { name: "Social" },
  { name: "Finance" },
  // { name: "Subscribed" },
  { name: "SGE" },
  // { name: "System" },
];

export function splitArray(inputArray: any[], subarrayLength: number) {
  let outputArray = [];
  for (let i = 0; i < inputArray?.length; i += subarrayLength) {
    outputArray.push(inputArray.slice(i, i + subarrayLength));
  }
  return outputArray;
}

export function pluralizeWord(word: string, number?: number) {
  // if (!number) return;
  if (!number || number === 1) {
    return word;
  } else {
    if (word.endsWith("s")) {
      return word + "es";
    } else {
      return word + "s";
    }
  }
}

export var settingMobieSlide = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1.08,
  slidesToScroll: 1,
  arrows: false,
};

export var settingOneHalfSlide = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1.46,
  slidesToScroll: 1,
  arrows: false,
};

export var settingDesktopSlide = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
};

export const isAllowNotification = window?.Notification
  ? window?.Notification?.permission &&
  window?.Notification.permission !== "denied"
  : false;

export const isNotAllowNotification = window?.Notification
  ? window?.Notification?.permission &&
  window?.Notification.permission === "denied"
  : false;
