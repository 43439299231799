import React from "react";
import clsx from "clsx";
import { Currency } from "components/commons/Currency";
import slugs from "navigation/slugs";
import { useNavigate } from "react-router-dom";
import { TypeEntry } from "../../Entry";
import { ReactComponent as LikeBlue } from "assets/images/like-blue.svg";
import { formatNumber } from "utils/number/number";
import { PROFILE_ID, getItem } from "local-storage";

import { ReactComponent as Checklist } from "assets/icons/checklist.svg";
import { DEFAULT_IMG } from "config";

interface Props {
  idx: number;
  item: Item;
  type: TypeEntry;
  findIndex: any;
}

interface Item {
  id: string;
  username: string;
  twitter_user: {
    name: string;
    metadata: any;
    is_blue_verified: boolean;
    follower_count: any;
  };
  twitter_item: {
    name: string;
    metadata: any;
    is_blue_verified: boolean;
    follower_count: number;
  };
  current_price: string;
  total_volume: any;
  total_eth_amount: any;
  eth_balance: any;
}

const EntrySmall: React.FC<Props> = (props: Props) => {
  const { idx, item, type, findIndex } = props;
  const profileId = getItem(PROFILE_ID) || null;

  const navigate = useNavigate();
  switch (type) {
    case TypeEntry.SMALL_LEADERBOARD:
      return (
        <div className="w-full flex flex-col">
          <div
            key={idx}
            className={`flex gap-4 justify-between items-center w-full overflow-hidden text-ellipsis whitespace-nowrap flex-nowrap`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`${slugs.profile}/${item?.id}`, {
                state: {
                  from: true,
                },
              });
            }}
          >
            <div className="flex items-center gap-4 w-full flex-nowrap text-ellipsis overflow-hidden">
              <div className="relative flex flex-col justify-center items-center flex-none">
                <span
                  className={clsx(
                    "min-w-[36px] flex justify-center items-center text-center rounded-lg py-1 px-2 font-bold text-xs max-h-5",
                    {
                      "text-gray-100": idx <= 2,
                      "text-gray-500": idx > 2,
                      "bg-[#FF9F00]": idx === 0,
                      "bg-[#A4A8AE]": idx === 1,
                      "bg-[#9E491A]": idx === 2,
                      "bg-dk-gray-800": idx > 2,
                    }
                  )}
                >
                  {idx === 0 && "1st"}
                  {idx === 1 && "2nd"}
                  {idx === 2 && "3rd"}
                  {idx > 2 && idx + 1}
                </span>
              </div>
              {item?.twitter_user?.metadata?.image_url ? (
                <img
                  className="h-9 w-9 shadow-sm rounded-lg object-cover flex-none"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
              ) : (
                <i className="fa-duotone fa-user text-xl text-primary flex-none"></i>
              )}

              <div className="flex items-center gap-1 w-full truncate">
                <div
                  className={`text-base font-medium flex-nowrap text-ellipsis overflow-hidden`}
                >
                  {item?.twitter_user?.name}
                </div>
                <div className="w-4 h-4 block">
                  {item?.twitter_user?.is_blue_verified && <LikeBlue />}
                </div>
              </div>
            </div>

            <div className="flex-1 w-full">
              <Currency value={item?.current_price} />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div className="w-[88px]" />
            <div className="h-3 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    case TypeEntry.SMALL_TRADER_LEADERBOARD:
      return (
        <div className="w-full flex flex-col">
          <div
            key={idx}
            className={`flex gap-4 justify-between items-center w-full overflow-hidden text-ellipsis whitespace-nowrap flex-nowrap`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`${slugs.profile}/${item?.id}`, {
                state: {
                  from: true,
                },
              });
            }}
          >
            <div className="flex items-center gap-4 w-full flex-nowrap text-ellipsis overflow-hidden">
              <div className="relative flex flex-col justify-center items-center flex-none">
                <span
                  className={clsx(
                    "min-w-[36px] flex justify-center items-center text-center rounded-lg py-1 px-2 font-bold text-xs max-h-5",
                    {
                      "text-gray-100": idx <= 2,
                      "text-gray-500": idx > 2,
                      "bg-[#FF9F00]": idx === 0,
                      "bg-[#A4A8AE]": idx === 1,
                      "bg-[#9E491A]": idx === 2,
                      "bg-dk-gray-800": idx > 2,
                    }
                  )}
                >
                  {idx === 0 && "1st"}
                  {idx === 1 && "2nd"}
                  {idx === 2 && "3rd"}
                  {idx > 2 && idx + 1}
                </span>
              </div>
              {item?.twitter_user?.metadata?.image_url ? (
                <img
                  className="h-9 w-9 shadow-sm rounded-lg object-cover flex-none"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
              ) : (
                <i className="fa-duotone fa-user text-xl text-primary flex-none"></i>
              )}

              <div className="flex items-center gap-1 w-full truncate">
                <div
                  className={`text-base font-medium flex-nowrap text-ellipsis overflow-hidden`}
                >
                  {item?.twitter_user?.name}
                </div>
                <div className="w-4 h-4 block">
                  {item?.twitter_user?.is_blue_verified && <LikeBlue />}
                </div>
              </div>
            </div>

            <div className="flex items-center gap-1">
              <span className="text-sm text-gray-500">Vol:</span>{" "}
              <Currency value={item?.total_eth_amount} />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div className="w-[88px]" />
            <div className="h-3 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    case TypeEntry.SMALL_WHALES_LEADERBOARD:
      return (
        <div className="w-full flex flex-col">
          <div
            key={idx}
            className={`flex gap-4 justify-between items-center w-full overflow-hidden text-ellipsis whitespace-nowrap flex-nowrap`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`${slugs.profile}/${item?.id}`, {
                state: {
                  from: true,
                },
              });
            }}
          >
            <div className="flex items-center gap-4 w-full flex-nowrap text-ellipsis overflow-hidden">
              <div className="relative flex flex-col justify-center items-center flex-none">
                <span
                  className={clsx(
                    "min-w-[36px] flex justify-center items-center text-center rounded-lg py-1 px-2 font-bold text-xs max-h-5",
                    {
                      "text-gray-100": idx <= 2,
                      "text-gray-500": idx > 2,
                      "bg-[#FF9F00]": idx === 0,
                      "bg-[#A4A8AE]": idx === 1,
                      "bg-[#9E491A]": idx === 2,
                      "bg-dk-gray-800": idx > 2,
                    }
                  )}
                >
                  {idx === 0 && "1st"}
                  {idx === 1 && "2nd"}
                  {idx === 2 && "3rd"}
                  {idx > 2 && idx + 1}
                </span>
              </div>
              {item?.twitter_user?.metadata?.image_url ? (
                <img
                  className="h-9 w-9 shadow-sm rounded-lg object-cover flex-none"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
              ) : (
                <i className="fa-duotone fa-user text-xl text-primary flex-none"></i>
              )}

              <div className="flex items-center gap-1 w-full truncate">
                <div
                  className={`text-base font-medium flex-nowrap text-ellipsis overflow-hidden`}
                >
                  {item?.twitter_user?.name}
                </div>
                <div className="w-4 h-4 block">
                  {item?.twitter_user?.is_blue_verified && <LikeBlue />}
                </div>
              </div>
            </div>

            <div className="flex-1 w-full">
              {/* {currencyFormatter(portfolioValue)}{" "} */}
              <Currency value={item?.eth_balance} />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div className="w-[88px]" />
            <div className="h-3 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    case TypeEntry.SMALL_HOME_EXPLORE:
      return (
        <div
          key={idx}
          onClick={(e) => {
            e.preventDefault();
            navigate(
              profileId === item?.id
                ? slugs.profile
                : `${slugs.profile}/${item?.id}`
            );
          }}
          className="flex px-4 gap-4 justify-between items-center "
        >
          <div className="flex flex-col w-full">
            <div className="flex items-center gap-4 w-full ">
              <div className="shadow-sm justify-center items-center relative">
                <img
                  className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
              </div>
              <div className="flex-1 flex justify-between gap-4 flex-nowrap text-ellipsis overflow-hidden">
                <div className="flex flex-col items-baseline transition-all truncate w-full">
                  <div className="text-base font-normal flex items-center gap-1 truncate w-full">
                    <div className="font-medium flex-nowrap text-ellipsis overflow-hidden">
                      {item?.twitter_user?.name}
                    </div>
                    <div className="w-4 h-4">
                      {" "}
                      {item?.twitter_user?.is_blue_verified ? (
                        <LikeBlue />
                      ) : null}
                    </div>

                    {/* <span className="text-gray-500 ">@NASA</span> */}
                  </div>
                  <span className="text-sm font-normal text-gray-500">
                    {item?.twitter_user?.follower_count
                      ? formatNumber(Number(item?.twitter_user?.follower_count))
                      : "0"}{" "}
                    Followers
                  </span>
                </div>
                <div>
                  <div className="flex justify-end">
                    <Currency
                      textClassName="text-md font-bold"
                      value={item?.current_price}
                    />
                  </div>
                  <div className="flex gap-2">
                    <span className="text-sm font-normal text-gray-500">
                      Vol
                    </span>
                    <span className="text-sm font-normal">
                      {formatNumber(item?.total_volume)}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-4">
              <div className="w-16" />
              <div className="h-3 grow border-b border-dk-gray-800" />
            </div>
          </div>
        </div>
      );
    case TypeEntry.SMALL_HOME_TRENDING_TODAY:
      return (
        <div className="w-full flex flex-col" key={`${idx}_blockItem`}>
          <div
            onClick={(e) => {
              e.preventDefault();
              navigate(
                profileId === item?.id
                  ? slugs.profile
                  : `${slugs.profile}/${item?.id}`
              );
            }}
            className="flex gap-4 justify-between items-center"
          >
            <div className="flex items-center gap-4 w-full">
              <div className="shadow-sm justify-center items-center relative">
                <img
                  className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                  src={
                    `${item?.twitter_user?.metadata?.image_url}` || DEFAULT_IMG
                  }
                  alt="avatar"
                />
              </div>
              <div className="flex-1 flex justify-between items-center gap-4 flex-nowrap text-ellipsis overflow-hidden">
                <div className="flex flex-col items-baseline transition-all truncate w-full">
                  <div className="text-base font-normal flex items-center gap-1 truncate w-full">
                    <div className="font-medium flex-nowrap text-ellipsis overflow-hidden">
                      {item?.username}
                    </div>
                    <div className="w-4 h-4">
                      {item?.twitter_user?.is_blue_verified ? (
                        <LikeBlue />
                      ) : null}
                    </div>

                    {/* <span className="text-gray-500 ">@NASA</span> */}
                  </div>
                  <span className="text-sm font-normal text-gray-500">
                    {formatNumber(item?.twitter_user?.follower_count)} Followers
                  </span>
                </div>
                <div>
                  <div className="flex justify-end">
                    <Currency
                      textClassName="text-md font-bold"
                      value={item?.current_price}
                    />
                  </div>
                  <div className="flex gap-2">
                    <span className="text-sm font-normal text-gray-500">
                      Vol
                    </span>
                    <span className="text-sm font-normal">
                      {formatNumber(item?.total_volume)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-4">
            <div className="w-16" />
            <div className="h-3 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    case TypeEntry.SMALL_EXPLORE:
      return (
        <div className="flex flex-col w-full">
          <div
            className="flex gap-4 justify-between items-center w-full"
            key={idx}
            onClick={(e) => {
              e.preventDefault();
              navigate(
                profileId === item?.id
                  ? slugs.profile
                  : `${slugs.profile}/${item?.id}`
              );
            }}
          >
            <div className="flex items-center gap-4 w-full">
              <div className="shadow-sm justify-center items-center relative">
                <img
                  className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                  src={item?.twitter_item?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
                <div className="flex justify-center items-center -mt-2">
                  <div
                    className={clsx(
                      "flex justify-center items-center text-center rounded-full h-4 w-4 font-medium text-xs",
                      {
                        "text-gray-100": findIndex !== 1,
                        "text-gray-500": findIndex === 1,

                        "bg-[#FFB800]": findIndex === 0,
                        "bg-gray-300": findIndex === 1,
                        "bg-[#C96000]": findIndex === 2,
                        "bg-[#FF9F00]": findIndex > 2,
                      }
                    )}
                  >
                    {findIndex + 1}
                  </div>
                </div>
              </div>
              <div className="flex-1 flex justify-between gap-4 flex-nowrap text-ellipsis overflow-hidden">
                <div className="flex flex-col items-baseline transition-all truncate w-full">
                  <div className="text-base font-normal flex items-center gap-1 truncate w-full">
                    <div className="font-medium flex-nowrap text-ellipsis overflow-hidden">
                      {item?.twitter_item?.name ?? "-"}
                    </div>
                    <div className="w-4 h-5">
                      {item?.twitter_item?.is_blue_verified ? (
                        <Checklist className="w-4 h-4" />
                      ) : null}
                    </div>

                    {/* <span className="text-gray-500 ">@NASA</span> */}
                  </div>
                  <span className="text-sm font-normal text-gray-500">
                    {item?.twitter_item?.follower_count
                      ? formatNumber(Number(item?.twitter_item?.follower_count))
                      : "0"}{" "}
                    Followers
                  </span>
                </div>
                <Currency value={item?.total_volume} />
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-16" />
            <div className="h-1 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    case TypeEntry.SMALL_HOME_LEADERBOARD:
      return (
        <div className="flex flex-col w-full">
          <div
            className="flex gap-4 justify-between items-center w-full"
            key={idx}
            onClick={(e) => {
              e.preventDefault();
              navigate(
                profileId === item?.id
                  ? slugs.profile
                  : `${slugs.profile}/${item?.id}`
              );
            }}
          >
            <div className="flex items-center gap-4 w-full">
              <div className="shadow-sm justify-center items-center relative">
                <img
                  className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
                <div className="flex justify-center items-center -mt-2">
                  <div
                    className={clsx(
                      "flex justify-center items-center text-center rounded-full h-4 w-4 font-medium text-xs",
                      {
                        "text-gray-100": findIndex !== 1,
                        "text-gray-500": findIndex === 1,

                        "bg-[#FFB800]": findIndex === 0,
                        "bg-gray-300": findIndex === 1,
                        "bg-[#C96000]": findIndex === 2,
                        "bg-[#FF9F00]": findIndex > 2,
                      }
                    )}
                  >
                    {findIndex + 1}
                  </div>
                </div>
              </div>
              <div className="flex-1 flex justify-between gap-4 flex-nowrap text-ellipsis overflow-hidden">
                <div className="flex flex-col items-baseline transition-all truncate w-full">
                  <div className="text-base font-normal flex items-center gap-1 truncate w-full">
                    <span className="font-medium flex-nowrap text-ellipsis overflow-hidden">
                      {item?.twitter_user?.name ?? "-"}
                    </span>
                    <div className="w-4 h-4">
                      {item?.twitter_user?.is_blue_verified ? (
                        <Checklist className="w-4 h-4" />
                      ) : null}
                    </div>

                    {/* <span className="text-gray-500 ">@NASA</span> */}
                  </div>
                  <span className="text-sm font-normal text-gray-500">
                    {item?.twitter_user?.follower_count
                      ? formatNumber(Number(item?.twitter_user?.follower_count))
                      : "0"}{" "}
                    Followers
                  </span>
                </div>
                <div>
                  <div className="flex items-center justify-end gap-1">
                    <Currency value={item?.current_price} />
                  </div>
                  <div className="flex items-center gap-1">
                    <div className="text-sm font-normal text-gray-500">Vol</div>
                    <div className="text-sm font-normal">
                      {formatNumber(item?.total_volume)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-16" />
            <div className="h-1 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    case TypeEntry.SMALL_HOME_TRADERS_LEADERBOARD:
      return (
        <div className="flex flex-col w-full">
          <div
            className="flex gap-4 justify-between items-center w-full"
            key={idx}
            onClick={(e) => {
              e.preventDefault();
              navigate(
                profileId === item?.id
                  ? slugs.profile
                  : `${slugs.profile}/${item?.id}`
              );
            }}
          >
            <div className="flex items-center gap-4 w-full">
              <div className="shadow-sm justify-center items-center relative">
                <img
                  className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
                <div className="flex justify-center items-center -mt-2">
                  <div
                    className={clsx(
                      "flex justify-center items-center text-center rounded-full h-4 w-4 font-medium text-xs",
                      {
                        "text-gray-100": findIndex !== 1,
                        "text-gray-500": findIndex === 1,

                        "bg-[#FFB800]": findIndex === 0,
                        "bg-gray-300": findIndex === 1,
                        "bg-[#C96000]": findIndex === 2,
                        "bg-[#FF9F00]": findIndex > 2,
                      }
                    )}
                  >
                    {findIndex + 1}
                  </div>
                </div>
              </div>
              <div className="flex-1 flex justify-between gap-4 flex-nowrap text-ellipsis overflow-hidden">
                <div className="flex flex-col items-baseline transition-all truncate w-full">
                  <div className="text-base font-normal flex items-center gap-1 truncate w-full">
                    <span className="font-medium flex-nowrap text-ellipsis overflow-hidden">
                      {item?.twitter_user?.name ?? "-"}
                    </span>
                    <div className="w-4 h-4">
                      {item?.twitter_user?.is_blue_verified ? (
                        <Checklist className="w-4 h-4" />
                      ) : null}
                    </div>

                    {/* <span className="text-gray-500 ">@NASA</span> */}
                  </div>
                  <span className="text-sm font-normal text-gray-500">
                    {item?.twitter_user?.follower_count
                      ? formatNumber(Number(item?.twitter_user?.follower_count))
                      : "0"}{" "}
                    Followers
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-500">Vol:</span>{" "}
                  <Currency value={item?.total_eth_amount} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-16" />
            <div className="h-1 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    case TypeEntry.SMALL_HOME_WHALES_LEADERBOARD:
      return (
        <div className="flex flex-col w-full">
          <div
            className="flex gap-4 justify-between items-center w-full"
            key={idx}
            onClick={(e) => {
              e.preventDefault();
              navigate(
                profileId === item?.id
                  ? slugs.profile
                  : `${slugs.profile}/${item?.id}`
              );
            }}
          >
            <div className="flex items-center gap-4 w-full">
              <div className="shadow-sm justify-center items-center relative">
                <img
                  className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
                <div className="flex justify-center items-center -mt-2">
                  <div
                    className={clsx(
                      "flex justify-center items-center text-center rounded-full h-4 w-4 font-medium text-xs",
                      {
                        "text-gray-100": findIndex !== 1,
                        "text-gray-500": findIndex === 1,

                        "bg-[#FFB800]": findIndex === 0,
                        "bg-gray-300": findIndex === 1,
                        "bg-[#C96000]": findIndex === 2,
                        "bg-[#FF9F00]": findIndex > 2,
                      }
                    )}
                  >
                    {findIndex + 1}
                  </div>
                </div>
              </div>
              <div className="flex-1 flex justify-between gap-4 flex-nowrap text-ellipsis overflow-hidden">
                <div className="flex flex-col items-baseline transition-all truncate w-full">
                  <div className="text-base font-normal flex items-center gap-1 truncate w-full">
                    <span className="font-medium flex-nowrap text-ellipsis overflow-hidden">
                      {item?.twitter_user?.name ?? "-"}
                    </span>
                    <div className="w-4 h-4">
                      {item?.twitter_user?.is_blue_verified ? (
                        <Checklist className="w-4 h-4" />
                      ) : null}
                    </div>

                    {/* <span className="text-gray-500 ">@NASA</span> */}
                  </div>
                  <span className="text-sm font-normal text-gray-500">
                    {item?.twitter_user?.follower_count
                      ? formatNumber(Number(item?.twitter_user?.follower_count))
                      : "0"}{" "}
                    Followers
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <Currency value={item?.eth_balance} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-16" />
            <div className="h-1 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
    default:
      return (
        <div className="w-full flex flex-col">
          <div
            key={idx}
            className={`flex gap-4 justify-between items-center w-full overflow-hidden text-ellipsis whitespace-nowrap flex-nowrap`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`${slugs.profile}/${item?.id}`, {
                state: {
                  from: true,
                },
              });
            }}
          >
            <div className="flex items-center gap-4 w-full flex-nowrap text-ellipsis overflow-hidden">
              <div className="relative flex flex-col justify-center items-center flex-none">
                <span
                  className={clsx(
                    "min-w-[36px] flex justify-center items-center text-center rounded-lg py-1 px-2 font-bold text-xs max-h-5",
                    {
                      "text-gray-100": idx <= 2,
                      "text-gray-500": idx > 2,

                      "bg-[#FF9F00]": idx === 0,
                      "bg-[#A4A8AE]": idx === 1,
                      "bg-[#9E491A]": idx === 2,
                      "bg-dk-gray-800": idx > 2,
                    }
                  )}
                >
                  {idx === 0 && "1st"}
                  {idx === 1 && "2nd"}
                  {idx === 2 && "3rd"}
                  {idx > 2 && idx + 1}
                </span>
              </div>
              {item?.twitter_user?.metadata?.image_url ? (
                <img
                  className="h-9 w-9 shadow-sm rounded-lg object-cover flex-none"
                  src={item?.twitter_user?.metadata?.image_url || DEFAULT_IMG}
                  alt="avatar"
                />
              ) : (
                <i className="fa-duotone fa-user text-xl text-primary flex-none"></i>
              )}

              <div className="flex items-center gap-1 w-full truncate">
                <div
                  className={`text-base font-medium flex-nowrap text-ellipsis overflow-hidden`}
                >
                  {item?.twitter_user?.name}
                </div>
                <div className="w-4 h-4 block">
                  {item?.twitter_user?.is_blue_verified && <LikeBlue />}
                </div>
              </div>
            </div>

            <div className="flex-1 w-full">
              <Currency value={item?.total_volume} />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div className="w-[88px]" />
            <div className="h-3 grow border-b border-dk-gray-800" />
          </div>
        </div>
      );
  }
};

export default EntrySmall;
