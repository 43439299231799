import React, { memo, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { ReactComponent as Icon } from "assets/images/icon.svg";
import { Loading, LazyLoad } from "components/commons";
import { MainLayoutPublib } from "components/layouts/MainLayoutPublib";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AppRouteType, mainRouters } from "./registryRoutes";
import { getAuthUrl } from "../services/apis/urls";
import { actions, store, useSnapshot } from "proxy-state/author";
import { routerLogin } from "../modules/auth/route";
import slugs, { ignoreAuthPaths } from "./slugs";
import coreAction from "core/actions";
import { useAuth } from "contexts/authContext";
import { IS_DISABLE_PWA } from "config";
import { isAndroid, isIOS } from "react-device-detect";
const isInStandaloneMode =
  IS_DISABLE_PWA || window.matchMedia("(display-mode: standalone)").matches;

const AppRouter = memo(() => {
  const { getAccessToken } = usePrivy();
  const { isAuth } = useSnapshot(store);
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // const { pathname } = useLocation();
  // console.log("pathname", pathname, isAuth);

  const onSuccess = () => {
    if (ignoreAuthPaths.includes(window.location.pathname)) {
      actions.setIsAuth(token);
    }
    setLoading(false);
  };
  const onFailure = () => {
    if (ignoreAuthPaths.includes(window.location.pathname)) {
      actions.setIsAuth();
    }
    setLoading(false);
  };

  const statusAuth = async () => {
    const privyToken = await getAccessToken();
    const config = {
      data: { token: privyToken },
    };
    await coreAction.callAPI(
      coreAction.method.POST,
      `${getAuthUrl}/login/authenticate-status`,
      config,
      onSuccess,
      onFailure,
      {
        navigate,
      }
    );
  };

  React.useEffect(() => {
    if (ignoreAuthPaths.includes(window.location.pathname)) {
      setLoading(false);
      return;
    }
    statusAuth();
  }, []);

  if (loading) return <Loading.View type={Loading.type.LOGO} />;

  const routerPublic = mainRouters.filter(
    ({ isAuthNeeded }: AppRouteType, index) => !isAuthNeeded
  );
  const routerPrivate = mainRouters.filter(
    ({ isAuthNeeded }: AppRouteType, index) => isAuthNeeded
  );

  const router = (array: AppRouteType[]) =>
    array.map((router) => {
      const { component: Component, container: Container, path } = router;

      return (
        <Route
          key={path as string}
          path={path as string}
          element={
            <Container>
              <LazyLoad>
                <Component />
              </LazyLoad>
            </Container>
          }
        />
      );
    });

  const { container: Container, component: Component } = routerLogin;

  const routerDefault = (
    <Route
      key={isAuth ? "home" : "login"}
      path={"*"}
      element={
        !isAuth || (!isInStandaloneMode && (isAndroid || isIOS)) ? (
          <Navigate to={slugs.login} replace />
        ) : isAuth ? (
          <Navigate to={slugs.home} replace />
        ) : (
          <Container>
            <LazyLoad>
              <Component />
            </LazyLoad>
          </Container>
        )
      }
    />
  );

  const listRoutes = !isAuth
    ? router(routerPublic).concat(routerDefault)
    : router(routerPrivate).concat(routerDefault);

  return <Routes>{listRoutes}</Routes>;
});

export const LogoFullScreen = (
  <MainLayoutPublib>
    <div className="flex flex-col h-full w-full justify-center items-center z-50">
      <Icon className="w-16 h-16 mb-4" />
    </div>
  </MainLayoutPublib>
);

const Navigator = () => {
  const { authenticated, ready } = usePrivy();
  return !authenticated && !ready ? LogoFullScreen : <AppRouter />;
};

export default Navigator;
