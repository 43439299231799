import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import Logo from "assets/icons/fantechLogoDesk.svg";
import Logo from "assets/icons/starLeagueLogoDesk.svg";
import { navigations } from "helpers/constants";
import { ReactComponent as ChzToken } from "assets/images/CHZ.svg";
import slugs from "navigation/slugs";
import clsx from "clsx";
import { WalletIcon } from "@heroicons/react/24/outline";
import { pointProxy, unreadInboxProxy } from "proxy-state/global";
import { getUserProfileData } from "services/apis/apis";
import { ACCESS_TOKEN, getItem } from "local-storage";
import { profileService } from "proxy-state/user/profile";
import { useSnapshot } from "valtio";
import { DEFAULT_IMG } from "config";
import { formatNumber } from "utils/number/number";
import { notiProxyState } from "proxy-state/notification";
import { NumberNoti } from "../NumberNoti/NumberNoti";
import { happeningService } from "proxy-state/home/sge";

export const MainNavigation: React.FC<{ balance?: string }> = ({ balance }) => {
  const pathName = useLocation().pathname;
  const profileState = useSnapshot(profileService.state);
  const accessToken = getItem(ACCESS_TOKEN) || "";
  const { countSocial, countFinance, countSGE } = useSnapshot(
    notiProxyState.data
  );
  const { count } = useSnapshot(happeningService.state);
  const { unreadCount } = useSnapshot(unreadInboxProxy);
  const navigate = useNavigate();

  // Main Navigation
  const mainNavClass =
    "flex flex-col w-full h-full bg-transparent border-r border-dk-gray-800";
  const navItemClass =
    "flex items-center pr-4 py-2.5 text-xl w-full rounded-box transition cursor-pointer gap-2 justify-between";
  const navItemNormalClass = "font-medium text-slate-500 hover:text-gray-100";
  const navItemActiveClass = "font-semibold bg-base-content/10 text-gray-100";

  useEffect(() => {
    const f = async () => {
      try {
        const accessToken = getItem(ACCESS_TOKEN) || "";
        if (!accessToken) return;
        const res = await getUserProfileData(accessToken);
        pointProxy.totalPoint = res?.point || 0;
        pointProxy.lastClaimedPoint = res?.last_claimed_point || 0;
        pointProxy.totalEth = res?.total_reward || 0;
        pointProxy.lastClaimedEth = res?.last_claimed_reward || 0;
        profileService.setData(res);
        profileService.setIsLoading(true);

        if (profileState.isFirstTimeFetch) {
          profileService.setIsFirstTimeFetch(false);
        }
      } catch (error) {
      } finally {
        profileService.setIsLoading(false);
      }
    };
    f();
  }, []);

  return (
    <>
      <nav className={mainNavClass}>
        <div className="mt-6 ml-6 mb-6">
          <Link to={slugs.home} className="flex items-center">
            <img src={Logo} className="w-1/2" alt="logo" />
          </Link>
        </div>

        {/* Main Nav */}
        <div className="flex flex-col flex-1 p-5">
          {navigations.map((item, idx) => (
            <Link
              className={`${navItemClass} ${
                item.href === pathName ? navItemActiveClass : navItemNormalClass
              }`}
              key={idx}
              to={item.href}
            >
              <span className="flex items-center gap-3">
                <i
                  className={clsx(`${item.iconSolid}`, {
                    "text-secondary": pathName === item.href,
                    "text-gray-500": pathName !== item.href,
                  })}
                />
                <span className="whitespace-nowrap">{item.name}</span>
              </span>
              {item.href === slugs.notification &&
              accessToken &&
              countFinance + countSGE + countSocial > 0 ? (
                <div className="mt-1 min-w-[20px]">
                  <NumberNoti
                    amount={countFinance + countSGE + countSocial}
                    customClass={clsx(
                      `w-full h-5 px-2 text-[10px] leading-3 font-medium text-gray-100`,
                      {
                        "bg-secondary": item.href === pathName,
                        "bg-dk-gray-700 text-gray-500": item.href !== pathName,
                      }
                    )}
                    onClick={() => {
                      navigate(slugs.notification);
                    }}
                  />
                </div>
              ) : null}
              {item.href === slugs.age && accessToken && count > 0 ? (
                <div className="mt-1 min-w-[20px]">
                  <NumberNoti
                    amount={count}
                    customClass={clsx(
                      `w-full h-5 px-2 text-[10px] leading-3 font-medium text-gray-100`,
                      {
                        "bg-secondary": item.href === pathName,
                        "bg-dk-gray-700 text-gray-500": item.href !== pathName,
                      }
                    )}
                    onClick={() => {
                      navigate(slugs.notification);
                    }}
                  />
                </div>
              ) : null}
              {item.href === slugs.inbox && accessToken && unreadCount > 0 ? (
                <div className="mt-1 min-w-[20px]">
                  <NumberNoti
                    amount={unreadCount}
                    customClass={clsx(
                      `w-full h-5 px-2 text-[10px] leading-3 font-medium text-gray-100`,
                      {
                        "bg-secondary": item.href === pathName,
                        "bg-dk-gray-700 text-gray-500": item.href !== pathName,
                      }
                    )}
                    onClick={() => {
                      navigate(slugs.inbox);
                    }}
                  />
                </div>
              ) : null}
            </Link>
          ))}
          {pathName !== slugs.upload && (
            <div className="mt-4">
              <button
                className="w-full bg-secondary flex items-center justify-center p-3 text-base rounded-lg font-bold"
                onClick={() => navigate(slugs.upload)}
              >
                Post
              </button>
            </div>
          )}
        </div>

        {accessToken && (
          <div className="w-full flex items-center p-5">
            <div className="flex flex-col gap-2 w-full">
              <div
                className="flex items-center justify-between gap-2 border border-dk-gray-800 rounded-lg p-3 cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(slugs.profile);
                }}
              >
                <span className="text-gray-100 text-base font-medium">
                  {profileState?.data?.twitter_user?.name}
                </span>
                <img
                  className={clsx(
                    "h-6 w-6 shadow-sm rounded-full object-fill border-2 border-base-300"
                  )}
                  src={
                    profileState?.data?.twitter_user?.metadata?.image_url ||
                    DEFAULT_IMG
                  }
                  alt="avatar"
                />
              </div>
              <div
                className="flex items-center justify-between gap-2 p-3 cursor-pointer border border-dk-gray-800 rounded-lg"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(slugs.profileBalance);
                }}
              >
                <div className="text-base font-medium flex items-center gap-1">
                  {balance ? formatNumber(Number(balance)) : 0}
                  <span className="text-primary text-xl">
                    <ChzToken />
                  </span>
                </div>
                <WalletIcon className="w-5 h-5 text-gray-500" />
              </div>
            </div>
          </div>
        )}
        {
          profileState?.isLoading || profileState?.isFirstTimeFetch ? (
            <div>Loading</div>
          ) : null
          // <div className="flex flex-col gap-2 p-4">
          //   <div
          //     onClick={(e) => {
          //       e.preventDefault();
          //       navigate(
          //         `${slugs.profile}/${profileService?.state?.data?.id}`,
          //         {
          //           state: {
          //             from: true,
          //           },
          //         }
          //       );
          //     }}
          //     className="p-3 border border-dk-gray-800 rounded-lg flex justify-between"
          //   >
          //     <div>{profileState?.data?.twitter_user?.name}</div>
          //     <img
          //       className="min-w-[24px] h-6 w-6 shadow-sm rounded-full object-cover"
          //       src={
          //         profileState?.data?.twitter_user?.metadata?.image_url ||
          //         avatarDefault
          //       }
          //       alt="avatar"
          //     />
          //   </div>
          //   <div className="p-3 border border-dk-gray-800 rounded-lg flex justify-between">
          //     <Currency value={profileState?.data?.current_price} />
          //     <WalletIcon className="h-5 w-5 text-gray-500" />
          //   </div>
          // </div>
        }

        {/* //Main Nav */}
      </nav>
    </>
  );
};
