import { MainLayoutPublib2 } from "components/layouts/MainLayoutPublib2";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const AccountVerifyDone = React.lazy(() => import("../page"));

const routes: AppRouteType[] = [
  {
    path: slugs.acccountVerified,
    component: AccountVerifyDone,
    container: MainLayoutPublib2,
    isAuthNeeded: false,
  },
];

export default routes;
