export const CONTRACTS: {
  [chainId: number]: { [key: string]: string };
} = {
  88882: {
    starsLeague:
      process.env.REACT_APP_APP_ENV === "staging"
        ? ""
        : "0x7101fA40ed7da32489df75065Ed4bEA71828AF58", // testing
  },
  88888: {
    starsLeague: "0xFaD9Fb76EE13aBFe08F8B17d3898a19902b6f9FB",
  },
};
