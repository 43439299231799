import React, { Suspense, ReactNode } from 'react';

import { Loading } from 'components/commons';

interface LazyLoadProps {
    children: ReactNode;
    loading?: ReactNode;
}

const LazyLoad: React.FC<LazyLoadProps> = ({ children, loading }) => {
    return (
        <Suspense fallback={loading || <Loading.View />}>
            {children}
        </Suspense>
    );
};

export default LazyLoad;