import { AxiosRequestConfig } from 'axios';
import axiosClient from 'core/axios/axiosClient';
import { ACCESS_TOKEN, getItem } from "local-storage";

enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE', 
    PATCH = 'PATCH',
}

interface MethodBase {
    GET: (url: string, config?: AxiosRequestConfig) => Promise<any>;
    POST: (url: string, config?: AxiosRequestConfig) => Promise<any>;
    PUT: (url: string, config?: AxiosRequestConfig) => Promise<any>;
    PATCH: (url: string, config?: AxiosRequestConfig) => Promise<any>;
    DELETE: (url: string, config?: AxiosRequestConfig) => Promise<any>;
}

// TODO: Fix for cases where tokens cannot be loaded
const initConfig = () => ({
    'Authorization': `Bearer ${getItem(ACCESS_TOKEN)}`
});

const methodBase: MethodBase = {
    GET: (url, config = { params: {}, headers: {} }) => {
        return axiosClient.get(url, {...initConfig(), ...config });
    },

    POST: (url, config = { params: {}, headers: {} }) => {
        return axiosClient.post(url, config?.data, {...initConfig(), ...config });
    },

    PUT: (url, config = { params: {}, headers: {} }) => {
        return axiosClient.put(url, config?.data, {...initConfig(), ...config });
    },

    PATCH: (url, config = { params: {}, headers: {} }) => {
        return axiosClient.patch(url, config?.data, {...initConfig(), ...config });
    },

    DELETE: (url, config) => {
        return axiosClient.delete(url, {...initConfig(), ...config });
    },
};

type MethodArgs = ['GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE', string, AxiosRequestConfig?, boolean?]

let previousMethod: string | null = null;
let previousParams: any[] | null = null;

function baseAPI(...args: MethodArgs) {
    const method = args[0] || Method.GET;
    const isTryCall = args[3];
    const params = args.slice(1) as Parameters<typeof methodBase[typeof method]>;

    if (!isTryCall && method === previousMethod && JSON.stringify(params) === JSON.stringify(previousParams)) {
        return { data: {}, status: 500 };
    }

    previousMethod = method;
    previousParams = params;

    return (methodBase[method] as (...args: any[]) => any)(...params);
};

export default {
    default: baseAPI,
    method: Method,
};