import Countdown from "react-countdown";
import { CountdownTimeDeltaFn } from "react-countdown/dist/Countdown";

export const Bidding = ({
  end_time,
  onComplete,
  onTick,
}: {
  end_time?: number;
  onComplete?: () => void;
  onTick?: CountdownTimeDeltaFn;
}) => {
  const renderer = ({
    minutes,
    seconds,
    completed,
  }: {
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className="flex-1 flex p-4 justify-center items-center">
          <div className="font-medium text-xl text-orange-500 mb-1">
            Bidding ended
          </div>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div className="flex-1 p-4">
          <div className="text-base text-slate-300 mb-1">Bidding ends in</div>

          <span className="font-normal text-orange-500 ml-1 text-md">
            <span className="font-medium text-xl">{minutes}</span> Min :{" "}
            <span className="font-medium text-xl">{seconds}</span> Sec
          </span>
        </div>
      );
    }
  };

  return (
    <Countdown
      date={end_time}
      renderer={renderer}
      onComplete={onComplete}
      onTick={onTick}
    />
  );
};
