import { PlusSmallIcon } from "@heroicons/react/24/outline";
import slugs from "navigation/slugs";
import { useNavigate } from "react-router-dom";

export const UploadPost = () => {
  const navigate = useNavigate();
  return (
    <div
      className="z-[100] w-14 h-14 bg-white flex justify-center items-center rounded-full fixed bottom-24 right-4 md:bottom-16 md:right-8"
      onClick={() => {
        navigate(slugs.upload);
      }}
    >
      <PlusSmallIcon className="w-8 h-8 text-base-300" />
    </div>
  );
};
