import { Loading, NoData } from "components/commons";
import { ACCESS_TOKEN, getItem } from "local-storage";
import { toNumber } from "lodash";
import moment from "moment";
import slugs from "navigation/slugs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserActivitiesById } from "services/apis/apis";
import { currencyFormatter } from "utils/number/number";
import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { Currency } from "components/commons/Currency";
import { useSnapshot } from "valtio";
import { activeService } from "proxy-state/user/activity";
import { chilizScan } from "config";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";

export default function Trade(props: any) {
  const { datas } = props;
  const navigate = useNavigate();
  const activeState = useSnapshot(activeService.state);
  // const { id } = useParams();

  const truncatedAddress = (address: any) => {
    return address && `${address.slice(0, 6)}...${address.slice(-6)}`;
  };

  const renderStatus = (el: any) => {
    if (el?.is_airdrop) {
      return <span className="text-info">AIRDROP</span>;
    }
    if (el?.is_bid) {
      if (el?.share_amount > 0) {
        return <span className="text-[#0EA5E9]">BID</span>;
      } else {
        return <span className="text-[#6366F1]">OUTBID</span>;
      }
    } else {
      if (el?.share_amount > 0) {
        return <span className="text-success">BUY</span>;
      } else {
        return <span className="text-error">SELL</span>;
      }
    }
  };

  return (
    <div className="flex flex-col gap-4 w-full pt-4 px-4 flex-1">
      {activeState?.isLoading && activeState?.isFirstTimeFetch ? (
        <Loading.View type={Loading.type.SMALL_DEFAULT} limit={5} />
      ) : null}

      {!activeState?.isLoading && datas?.length === 0 && <NoData />}

      {!activeState.isLoading &&
        datas?.map((el: any) => (
          <div
            className="flex items-start self-stretch gap-2 w-full border-b border-dk-gray-800 pb-3 cursor-pointer"
            key={el?.id}
            onClick={(e) => {
              e.preventDefault();
              navigate(`${slugs.profile}/${el?.subject_id}`, {
                state: { from: true },
              });
            }}
          >
            <div className="flex flex-col gap-1">
              <div className="flex justify-center items-center">
                <div className="h-8 w-8 shadow-sm rounded-full overflow-hidden flex justify-center items-center">
                  <img
                    className="h-8 w-8 shadow-sm rounded-full object-fill"
                    // src={item?.metadata?.image_url}
                    src={el?.trader_url ?? "/images/star-league-logo.png"}
                    alt=""
                  />
                </div>
                <div className="h-8 w-8 shadow-sm rounded-full overflow-hidden flex justify-center items-center -translate-x-1">
                  <img
                    className="h-8 w-8 shadow-sm rounded-full object-fill"
                    // src={item?.metadata?.image_url}
                    src={el?.subject_url ?? "/images/star-league-logo.png"}
                    alt=""
                  />
                </div>
              </div>
              <div className="max-w-[60px] rounded-full px-2.5 py-1 w-full bg-dk-gray-800 flex items-center justify-center text-[10px] leading-3 font-bold tracking-wide">
                {renderStatus(el)}
              </div>
            </div>

            {/* {item?.metadata?.image_url ? (
            <img
              className="h-8 w-8 shadow-sm rounded-full object-fill"
              src={item?.metadata?.image_url}
            />
          ) : (
            <i className="fa-duotone fa-user text-2xl text-primary"></i>
          )} */}

            <div className="flex-1 truncate">
              <div className={`flex text-base font-normal `}>
                <span
                  className="text-white font-bold"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`${slugs.profile}/${el?.trader_id}`, {
                      state: { from: true },
                    });
                  }}
                >
                  {el?.trader_username}
                </span>{" "}
                <span>
                  {el?.is_airdrop ? (
                    <ArrowLeft className=" mx-2 mt-1" />
                  ) : (
                    <ArrowRight className=" mx-2 mt-1" />
                  )}{" "}
                </span>
                <span className="text-white font-bold truncate">
                  {" "}
                  {el?.subject_username}
                </span>{" "}
                {/* share */}
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-1">
                  <Currency
                    textClassName="text-sm font-bold"
                    value={
                      el?.is_bid
                        ? (toNumber(el?.eth_amount) / 9) * 10
                        : el?.eth_amount
                    }
                  />
                  <span className="flex items-center gap-1">
                    {"  "}
                    <span className="text-sm text-slate-500" role="separator">
                      ·
                    </span>
                    {"  "}
                    <span className="text-sm text-slate-500" role="separator">
                      {moment(el?.created_at).fromNow()}
                    </span>
                  </span>
                </div>
                <div
                  className="text-sm font-normal text-slate-500 flex flex-nowrap items-center gap-0.5"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    window.open(`${chilizScan}/${el?.tx_hash}`);
                  }}
                >
                  {truncatedAddress(el?.tx_hash)}{" "}
                  <ArrowUpRightIcon className="w-3 h-3" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
