import clsx from "clsx";
import { Loading, NoData } from "components/commons";
import { DEFAULT_IMG } from "config";
import { ACCESS_TOKEN, getItem } from "local-storage";
import moment from "moment";
import slugs from "navigation/slugs";
import { notiProxyState } from "proxy-state/notification";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNotificationsHistory, putNotiView } from "services/apis/apis";
import { useSnapshot } from "valtio";
import _ from "lodash";

type TCategory = "All" | "You" | "Holdings" | "Subscribing";

const getCategory = (currentTab: TCategory) => {
  switch (currentTab) {
    case "All":
      return undefined;

    case "You":
      return "YourAccount";

    case "Holdings":
      return "YourHoldings";

    case "Subscribing":
      return "YourSubscribing";

    default:
      return undefined;
  }
};

export const NotiCard = (props: { type: "social" | "finance" | "sge" }) => {
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState<TCategory>("All");

  const navigate = useNavigate();

  useEffect(() => {
    const f = async () => {
      try {
        const accessToken = getItem(ACCESS_TOKEN) || "";
        if (!accessToken) return;
        setIsLoading(true);
        const result = await getNotificationsHistory(
          props.type,
          getCategory(currentTab)
        );
        setTimeout(() => {
          if (props.type === "finance") {
            notiProxyState.data.countFinance = 0;
          } else if (props.type === "sge") {
            notiProxyState.data.countSGE = 0;
          } else if (props.type === "social") {
            notiProxyState.data.countSocial = 0;
          }
        }, 3000);

        if (props.type === "sge") {
          setData(_.unionBy(result, "content"));
        } else {
          setData(result);
        }
      } catch (error) {
        console.log("🚀 ~ file: HoldCard.tsx:21 ~ f ~ error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    f();
    return () => {};
  }, [currentTab, props.type]);

  const handleClickViewNoti = async (notiId: string) => {
    if (!notiId) return;
    try {
      await putNotiView(notiId);
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {}, [props.type]);

  const userAction = (el: any) => {
    // const contents = el?.content?.split(" ") || [];

    // // const agent = contents[0];
    // const recipient = contents[contents.length - 1]?.startsWith("@")
    //   ? contents[contents.length - 1]
    //   : undefined;

    // contents?.shift();

    // if (recipient) {
    //   contents?.pop();
    // }
    // const content = contents.join(" ");

    return (
      <span className={`text-base font-normal mr-1`}>
        {/* Previous version */}
        {/* <span
          className="text-secondary"
          onClick={() => {
            if (!el?.agent?.id) return;
            navigate(`${slugs.profile}/${el?.agent?.id}`, {
              state: { from: true },
            });
          }}
        >
          @{el?.agent?.twitter_user?.username}{" "}
        </span>
        {content}{" "}
        {recipient && (
          <span
            className="text-secondary"
            onClick={() => {
              if (!el?.recipient?.id) return;
              navigate(`${slugs.profile}/${el?.recipient?.id}`, {
                state: { from: true },
              });
            }}
          >
            @{el?.recipient?.twitter_user?.username}
          </span>
        )}{" "} */}
        <div
          className=""
          dangerouslySetInnerHTML={{
            __html: el?.content,
          }}
        ></div>
      </span>
    );
  };

  return (
    <div className="flex flex-col py-4">
      {isLoading && (
        <Loading.View className="fixed z-[60] backdrop-brightness-90 top-0 bottom-0 responsive_content_in_layout" />
      )}

      {props.type !== "sge" && (
        <div className="flex gap-2 font-medium px-4 pb-4">
          <div
            onClick={() => {
              setCurrentTab("All");
            }}
            className={clsx("text-sm py-2 px-3 cursor-pointer", {
              "text-slate-700 bg-white rounded-lg": currentTab === "All",
              "text-slate-300 bg-gray-800 rounded-lg": currentTab !== "All",
            })}
          >
            All
          </div>
          <div
            onClick={() => {
              setCurrentTab("You");
            }}
            className={clsx("text-sm py-2 px-3 cursor-pointer", {
              "text-slate-800 bg-white rounded-lg": currentTab === "You",
              "text-slate-300 bg-gray-800 rounded-lg": currentTab !== "You",
            })}
          >
            You
          </div>
          <div
            onClick={() => {
              setCurrentTab("Holdings");
            }}
            className={clsx("text-sm py-2 px-3 cursor-pointer", {
              "text-slate-800 bg-white rounded-lg": currentTab === "Holdings",
              "text-slate-300 bg-gray-800 rounded-lg":
                currentTab !== "Holdings",
            })}
          >
            Holdings
          </div>
          <div
            onClick={() => {
              setCurrentTab("Subscribing");
            }}
            className={clsx("text-sm py-2 px-3 cursor-pointer", {
              "text-slate-800 bg-white rounded-lg":
                currentTab === "Subscribing",
              "text-slate-300 bg-gray-800 rounded-lg":
                currentTab !== "Subscribing",
            })}
          >
            Subscribing
          </div>
        </div>
      )}

      {!isLoading && data?.length === 0 && <NoData />}

      {data?.map((el: any) => {
        return (
          <div
            className={`flex items-start self-stretch gap-2 w-full p-4 cursor-pointer ${
              el?.is_view ? "" : "bg-dk-gray-800/80"
            }`}
            key={el?.id}
            onClick={(e) => {
              e.stopPropagation();
              handleClickViewNoti(el?.id);
              if (el?.launch_url)
                navigate(el?.launch_url, {
                  state: { fromNoti: true },
                });
            }}
          >
            {/* Previous version */}
            {/* <div className="flex">
              <div className="h-8 w-8 shadow-sm rounded-full overflow-hidden flex justify-center items-center">
                <img
                  className="h-8 w-8 shadow-sm rounded-full object-fill"
                  src={
                    el?.agent?.twitter_user?.metadata?.image_url ?? DEFAULT_IMG
                  }
                  alt=""
                />
              </div>
              <div className="h-8 w-8 shadow-sm rounded-full overflow-hidden flex justify-center items-center -translate-x-3">
                <img
                  className="h-8 w-8 shadow-sm rounded-full object-fill"
                  src={
                    el?.recipient?.twitter_user?.metadata?.image_url ??
                    DEFAULT_IMG
                  }
                  alt=""
                />
              </div>
            </div> */}
            <div className="flex relative">
              <div className="h-12 w-12 shadow-sm rounded-full overflow-hidden flex justify-center items-center">
                <img
                  className="h-12 w-12 shadow-sm rounded-full object-fill"
                  src={
                    el?.agent?.twitter_user?.metadata?.image_url ?? DEFAULT_IMG
                  }
                  alt=""
                />
              </div>
              <div className="h-6 w-6 shadow-sm rounded-full overflow-hidden flex justify-center items-center absolute right-0 bottom-0">
                <img
                  className="h-6 w-6 shadow-sm rounded-full object-fill"
                  src={
                    el?.recipient?.twitter_user?.metadata?.image_url ??
                    DEFAULT_IMG
                  }
                  alt=""
                />
              </div>
            </div>

            {/* {item?.metadata?.image_url ? (
            <img
              className="h-8 w-8 shadow-sm rounded-full object-fill"
              src={item?.metadata?.image_url}
            />
          ) : (
            <i className="fa-duotone fa-user text-2xl text-primary"></i>
          )} */}

            <div className="flex-1 flex justify-between">
              <h6 className="flex flex-col items-baseline transition-all">
                {userAction(el)}

                <span className="text-sm font-normal text-slate-500">
                  {moment(el?.created_at).fromNow()}
                </span>
              </h6>
            </div>
          </div>
        );
      })}
    </div>
  );
};
