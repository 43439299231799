import React, { useState, useEffect } from 'react';

let intervalId: any;
const useOnScreen = (targetRef: React.MutableRefObject<undefined>, handleVisible = (): void => {}, handleHidden = (): void => {}) => {
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const _callback = (entries: any[], observer: any) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    handleVisible();
                    // intervalId = setInterval(() => {
                    //     handleVisible();
                    // }, 2000);
                } else {
                    handleHidden()
                    // clearInterval(intervalId); // Clear interval
                }
            });
        };

        const observer = new IntersectionObserver(_callback, options);
        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            // clearInterval(intervalId);
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    });
};

export default useOnScreen;