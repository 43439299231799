import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Suspense, useCallback } from "react";
import { ReOpenPWA } from "components/commons/ReOpenPWA";
import { UpdateVersion } from "components/commons/UpdateVersion";
import { AuthProvider } from "contexts/authContext";
import { LazyLoad } from "components/commons";

import {
  ACCESS_TOKEN,
  CHZ_PRICE,
  CLIENT_VERSION,
  CURRENCY_DISPLAY,
  ETH_PRICE,
  IS_REQUIRED_RE_OPEN_PWA,
  MNT_PRICE,
  PROFILE_ID,
  getItem,
  saveItem,
} from "local-storage";
import slugs from "navigation/slugs";
import { useEffect, useRef, useState } from "react";
import OneSignal from "react-onesignal";
// import { useLocation } from "react-router-dom";
import {
  getClientConfig,
  getCheckSGELive,
  getUserHolderIds,
  getUserHoldingIds,
  getUserProfileData,
  getCountNoti,
  getSGELiveUsers,
  getMessageUserUnread,
} from "services/apis/apis";
import Navigator, { LogoFullScreen } from "./navigation/navigator";
import { ToastContainer } from "react-toastify";
import { useSnapshot } from "valtio";
import { swapState } from "proxy-state/swap";
// import { ethers } from "ethers";
import { useWallets } from "@privy-io/react-auth";
import { toast } from "react-toastify";
import { formatAmountMnt } from "utils/number/number";
import {
  checkSgeProxy,
  globalProxy,
  navigateTab,
  pointProxy,
  unreadInboxProxy,
} from "proxy-state/global";
import moment from "moment";
import { getPrices } from "services/coingecko";
import { appConfig } from "./services/blockchain";
import { profileUserProxyService } from "proxy-state/profile-user";
import { useLocation } from "react-router-dom";
import { notiProxyState } from "proxy-state/notification";
import { happeningService } from "proxy-state/home/sge";

// custom text moment
moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "1s",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h", //this is the setting that you need to change
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    w: "1w",
    ww: "%dw",
    M: "1mo", //change this for month
    MM: "%dmo",
    y: "ay",
    yy: "%dy",
  },
});

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function runOneSignal() {
  try {
    await OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_ID!,
      allowLocalhostAsSecureOrigin: true,
      autoResubscribe: true,
      serviceWorkerParam: {
        scope: "/push/onesignal/",
      },
      serviceWorkerPath: "/push/onesignal/OneSignalSDKWorker.js",
    });
  } catch (e) {
    console.log("runOneSignal inits", e);
  }
  // .then(() => {
  //   // console.log("OneSignal", OneSignal.User);
  //   // console.log("32122321321");
  //   // OneSignal.Debug.setLogLevel("trace");
  // });
}

function App() {
  const accessToken = getItem(ACCESS_TOKEN) || "";
  const [isOutdated, setIsOutdated] = useState(false);
  const [version, setVersion] = useState();
  const [isRequiredReOpenPWA, setIsRequiredReOpenPWA] = useState(false);
  const initialized = useRef(false);

  const { swappedAt, mntBalance } = useSnapshot(swapState);
  const { isLive } = useSnapshot(checkSgeProxy);
  const snapNavigateTab = useSnapshot(navigateTab);
  const { wallets } = useWallets();
  const { pathname } = useLocation();

  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const walletAddress = embeddedWallet?.address;

  const initOneSignalAndSetProfile = async () => {
    await runOneSignal();
    let profileId = getItem(PROFILE_ID) || null;

    while (!profileId) {
      profileId = getItem(PROFILE_ID) || null;
      await sleep(10000);
    }

    if (profileId) {
      await OneSignal.login(profileId);
      console.log(
        "windown onesignal _currentUser",
        // @ts-ignore
        window.OneSignal.User._currentUser,
        profileId
      );
      const optedIn = OneSignal.User.PushSubscription.optedIn;
      if (optedIn) {
        await OneSignal.Notifications.requestPermission();
      }
      console.log("Done onesignal");
    }
  };

  /// @dev: prevent useEffect run twice
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      initOneSignalAndSetProfile();
    }
  }, []);

  useEffect(() => {
    const fetchCheckSgeLive = async () => {
      const result = await getCheckSGELive();

      if (checkSgeProxy.isLive !== result) {
        checkSgeProxy.isLive = result;
      }
    };
    fetchCheckSgeLive();
  }, [snapNavigateTab]);

  useEffect(() => {
    const ignoreUpdateVersionInPath = [slugs.linkTwitter, slugs.login].includes(
      window.location.pathname
    );

    let interval: any;

    if (ignoreUpdateVersionInPath) {
      return;
    }

    const f = async () => {
      const result = await getClientConfig();
      const currentVersion = getItem(CLIENT_VERSION);
      const version = result?.client_version;

      if (!currentVersion) {
        saveItem(CLIENT_VERSION, version);
        return;
      } else {
        if (currentVersion !== version) {
          setVersion(version);
          saveItem(CLIENT_VERSION, version);
          setIsOutdated(true);
        }

        interval = setInterval(async () => {
          const result = await getClientConfig();

          const version = result?.client_version;

          const currentVersion = getItem(CLIENT_VERSION);

          if (currentVersion !== version) {
            setVersion(version);
            saveItem(CLIENT_VERSION, version);
            setIsOutdated(true);
          }
        }, 1000 * 60 * 5);
      }
    };
    f();

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const isRequiredReOpenPWA = JSON.parse(getItem(IS_REQUIRED_RE_OPEN_PWA)!);

    if (isRequiredReOpenPWA) {
      setIsRequiredReOpenPWA(true);
      saveItem(IS_REQUIRED_RE_OPEN_PWA, false);
    }
  }, []);

  /// Tracking swapping
  useEffect(() => {
    let interval: any;

    if (!walletAddress) return;

    if (!swappedAt) return;

    const mntBalanceBefore = mntBalance;

    const f = async () => {
      try {
        interval = setInterval(async () => {
          const currentTime = new Date();
          const startFetchingTime = new Date(swappedAt.getTime() + 90 * 1000); // 90s

          // start fetching after 2p swap
          if (currentTime < startFetchingTime) return;

          const mntBalanceAfter = await appConfig.provider.getBalance(
            walletAddress
          );

          // stop tracking if mnt balance up
          if (mntBalanceAfter.gt(mntBalanceBefore)) {
            toast.success(
              `${formatAmountMnt(mntBalanceAfter || "0", 18, 1)} Ξ received`
            );
            clearInterval(interval);
            return;
          }

          // stop fetching after 5m swap
          if (new Date() > new Date(swappedAt.getTime() + 60 * 1000 * 5)) {
            clearInterval(interval);
            return;
          }
        }, 1500);
      } catch (error) {}
    };
    f();
  }, [mntBalance, swappedAt, walletAddress]);

  /// Fetching MNT, ETH Price
  useEffect(() => {
    const f = async () => {
      const result = await getPrices(["ethereum", "mantle", "chiliz"]);
      const eth = result?.ethereum?.usd || 1600;
      const mnt = result?.mantle?.usd || 0.4;
      const chz = result?.chiliz?.usd || 0.06;
      globalProxy.ethPrice = eth;
      globalProxy.mntPrice = mnt;
      globalProxy.chzPrice = chz;
      saveItem(CHZ_PRICE, chz);
      saveItem(ETH_PRICE, eth);
      saveItem(MNT_PRICE, mnt);
    };
    f();
  }, []);

  /// setting price display
  useEffect(() => {
    const coinDisplay = getItem(CURRENCY_DISPLAY);

    if (!coinDisplay) {
      const coinDisplay = "CHZ";
      globalProxy.currency = coinDisplay;
      saveItem(CURRENCY_DISPLAY, coinDisplay);
    }
  }, []);

  const profileId = getItem(PROFILE_ID);

  ///Get my profile
  const getUserProfile = useCallback(async () => {
    try {
      const accessToken = getItem(ACCESS_TOKEN) || "";
      if (!accessToken || !profileId) return;
      const res = await getUserProfileData(accessToken);
      pointProxy.totalPoint = res?.point || 0;
      pointProxy.lastClaimedPoint = res?.last_claimed_point || 0;
      pointProxy.totalEth = res?.total_reward || 0;
      pointProxy.lastClaimedEth = res?.last_claimed_reward || 0;
      profileUserProxyService.setData(res, profileId);
    } catch (error) {}
  }, [profileId]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  /// Fetching holders, holdings
  useEffect(() => {
    const f = async () => {
      if (!profileId) return;

      try {
        const [holders, holdings] = await Promise.all([
          getUserHolderIds(profileId),
          getUserHoldingIds(profileId),
        ]);

        const idsMap: {
          [key: string]: {
            userId: string;
            youOwn: boolean;
            theyOwn: boolean;
            status: "3,3" | "3,0" | "0,3" | undefined;
          };
        } = {};

        holders.forEach((holder: any) => {
          idsMap[holder] = {
            ...idsMap[holder],
            userId: holder,
            theyOwn: true,
          };
        });
        holdings.forEach((holding: any) => {
          idsMap[holding] = {
            ...idsMap[holding],
            userId: holding,
            youOwn: true,
          };
        });
        Object.keys(idsMap).forEach((key) => {
          const item = idsMap[key];
          idsMap[key].status =
            item.youOwn && item.theyOwn
              ? "3,3"
              : item.youOwn
              ? "3,0"
              : item.theyOwn
              ? "0,3"
              : undefined;
        });

        globalProxy.idsMap = idsMap;
      } catch (error) {
        console.log("🚀 ~ file: App.tsx:276 ~ f ~ error:", error);
      }
    };
    f();
  }, [profileId]);

  useEffect(() => {
    const access_token = getItem(ACCESS_TOKEN);
    if (!access_token) return;
    const f = async () => {
      try {
        const result = await getCountNoti();
        notiProxyState.data.countFinance = result.finance;
        notiProxyState.data.countSGE = result.sge;
        notiProxyState.data.countSocial = result.social;
      } catch (error) {}
    };
    f();
  }, [pathname, snapNavigateTab.currentTab]);

  useEffect(() => {
    const access_token = getItem(ACCESS_TOKEN);
    if (!access_token) return;
    const f = async () => {
      try {
        const result = await getMessageUserUnread();
        unreadInboxProxy.unreadCount = result.unread_count;
      } catch (error) {}
    };
    f();
  }, [pathname, snapNavigateTab.currentTab]);

  useEffect(() => {
    const f = async () => {
      try {
        if (!accessToken) return;
        const result = await getSGELiveUsers(accessToken);
        happeningService.setCount(result?.length ?? 0);
      } catch (error) {
        console.log(error);
      }
    };

    f();
    const interval = setInterval(async () => {
      f();
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(interval);
    };
  }, [accessToken]);

  if (isOutdated) {
    return <UpdateVersion version={version} />;
  }

  if (isRequiredReOpenPWA) {
    return <ReOpenPWA />;
  }

  return (
    <AuthProvider>
      <LazyLoad loading={LogoFullScreen}>
        <Navigator />
      </LazyLoad>
      <ToastContainer position="bottom-center" autoClose={3000} theme="dark" />
    </AuthProvider>
  );
}

export default App;
