import {
  MainNavigation,
  MobileNavigation,
  Responsive,
  UploadPost,
  TabletNavigation,
} from "components/commons";
import React, { useEffect } from "react";
import { ReactComponent as Icon } from "assets/images/star-league-slogan.svg";
import { useWallets } from "@privy-io/react-auth";
import { formatAmountMnt } from "utils/number/number";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import slugs from "navigation/slugs";
// import { ReactComponent as Mnt } from "assets/icons/mnt-logo-sm.svg";
import { LandingPage } from "components/commons/LandingPage";
import { appConfig } from "services/blockchain";
import { MainLayoutPublib } from "../MainLayoutPublib";
import { useAuth } from "contexts/authContext";
import { useSnapshot } from "valtio";
import {
  checkSgeProxy,
  navigateTab,
  scrollingProxy,
  // setTab,
} from "proxy-state/global";
import {
  BellAlertIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
// import IframeComponent from "components/commons/Iframes";
import { navigations } from "helpers/constants";
import { isIOS, isMobile } from "react-device-detect";
import { ReactComponent as ChzToken } from "assets/images/CHZ.svg";
import { IS_DISABLE_PWA } from "config";
import { SearchBar } from "components/widget/components/SearchBar";
import { LeftSubContent } from "components/widget/LeftSubContent";
import { RightSubContent } from "components/widget/RightSubContent";

interface Props {
  children: React.ReactNode;
}

export const MainLayout: React.FC<Props> = ({ children }) => {
  const { pathname, search } = useLocation();

  const snapNavigateTab = useSnapshot(navigateTab);
  const { isLive } = useSnapshot(checkSgeProxy);

  const querySearch = new URLSearchParams(search);
  const converId = querySearch.get("converId") || "";

  const navigate = useNavigate();

  const { balance, setBalance } = useAuth();

  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const scrolling = useSnapshot(scrollingProxy).isScrolling;

  useEffect(() => {
    const getBalanceBase = async () => {
      if (!embeddedWallet) return;
      const balance = await appConfig.provider.getBalance(
        embeddedWallet?.address
      );
      setBalance(formatAmountMnt(balance ?? "0", 18, 1));
    };
    getBalanceBase();
  }, [embeddedWallet]);

  const isHasIframe = [slugs.inbox, slugs.profile, slugs.otherProfile].includes(
    pathname
  );
  const isHiddenMainNav = converId !== "";

  const isHiddenHeader =
    [slugs.profile, slugs.otherProfile, slugs.notification].includes(
      pathname
    ) || converId !== "";

  const isShowRightContent =
    pathname === (slugs.home || slugs.bidding || slugs.feed);

  const isShowLeftContent =
    pathname.includes(slugs.profile) || pathname === slugs.searchUser;

  const isShowUploadPost =
    (pathname === slugs.profile &&
      snapNavigateTab.currentTab.Profile.currentTab === "Posts") ||
    pathname === slugs.feed;

  // const isInStandaloneMode =
  //   IS_DISABLE_PWA || window.matchMedia("(display-mode: standalone)").matches;
  const pageTitle = navigations.find((item) => item.href === pathname)?.name;

  return (
    <>
      <div className="main-layout--wrapper">
        <div
          className={`relative flex flex-col justify-start items-stretch text-white h-full w-full`}
        >
          {/* Left Sidebar */}
          <Responsive from={"lg"}>
            <div className="w-60 fixed top-0 bottom-0 transition-all z-50 h-full">
              <MainNavigation balance={balance} />
            </div>
          </Responsive>
          {/* Left Sidebar */}
          {/* Left Sidebar */}
          <Responsive from={"md"} to={"lg"}>
            <div className="w-20 fixed top-0 l-0 transition-all z-50 h-full">
              <TabletNavigation />
            </div>
          </Responsive>
          {/* Left Sidebar */}
          {/* Right Sidebar */}
          {isShowRightContent && (
            <Responsive from={"md"}>
              <div className="w-[360px] absolute right-0 top-0 transition-all z-50 h-full">
                <RightSubContent />
              </div>
            </Responsive>
          )}
          {/* Right Sidebar */}

          {isShowLeftContent && (
            <Responsive from={"md"}>
              <div className="w-[360px] absolute left-20 lg:left-60 top-0 transition-all z-50 h-full">
                <LeftSubContent />
              </div>
            </Responsive>
          )}


          {/* Mobile Bottom Navigation */}
          {!isHiddenMainNav && (
            <>
              <Responsive from={"md"}>
                <div className="w-full fixed bottom-0 transition-all z-20  backdrop-blur-[2px] bg-dk-gray-900 bg-opacity-[0.95]">
                  <MobileNavigation />
                </div>
              </Responsive>
            </>
          )}
          {/* // Mobile Bottom Navigation */}

          <div className="w-full fixed bottom-0 transition-all z-20  backdrop-blur-[2px] bg-dk-gray-900 bg-opacity-[0.95]">
            <MobileNavigation />
          </div>

          {/* header on mobie */}
          <Responsive from={"xs"} to={"md"}>
            {isHiddenHeader ? null : (
              <div className="fixed top-0 flex gap-2 justify-start items-center w-full bg-base-300 px-4 py-2.5 z-20">
                <div className="grow flex gap-2 md:hidden">
                  {pathname === slugs.home ? (
                    <Icon className="w-24 h-8" />
                  ) : (
                    <div className="text-2xl font-bold">{pageTitle}</div>
                  )}
                </div>

                <div className="grow"></div>

                {pathname !== slugs.home && (
                  <div className="flex-none ">
                    <div
                      className="text-sm font-bold flex items-center gap-2 rounded-full px-3 py-1.5 bg-dk-gray-800"
                      onClick={() => navigate(slugs.home)}
                    >
                      BID{" "}
                      <span className="relative flex h-1.5 w-1.5">
                        <span
                          className={clsx(
                            "absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75",
                            {
                              "animate-ping": isLive,
                            }
                          )}
                        ></span>
                        <span
                          className={clsx(
                            "relative inline-flex rounded-full h-1.5 w-1.5 ",
                            {
                              "bg-red-500": isLive,
                              "bg-slate-500": !isLive,
                            }
                          )}
                        ></span>
                      </span>
                    </div>
                  </div>
                )}

                <div className="flex-none ">
                  <div
                    className="text-sm flex items-center font-bold px-3 py-1.5 rounded-full bg-dk-gray-800"
                    onClick={() => navigate(slugs.profileBalance)}
                  >
                    {balance} &nbsp;&nbsp; <ChzToken />
                  </div>
                </div>

                {/* <div className="flex-none">
                  <div
                    className="text-sm rounded-lg px-3 py-1"
                    onClick={() => navigate(slugs.leaderboard)}
                  >
                    <div>🏆</div>
                  </div>
                </div> */}

                <div className="flex">
                  <div
                    className="flex text-sm py-1"
                    onClick={() => navigate(slugs.notification)}
                  >
                    <BellAlertIcon className="h-[20px]" />
                    <span className="text-[#EF4444] -mt-[12px] text-[26px]">
                      •
                    </span>
                  </div>
                </div>

                <div className="flex-none">
                  <MagnifyingGlassIcon
                    className="w-6 h-6 stroke-1.5"
                    onClick={() => navigate(slugs.explore)}
                  />
                </div>
              </div>
            )}
          </Responsive>
          {/* <Responsive from={"md"}>
            {pathname === slugs.home && (
              <div className="fixed top-0 w-full bg-base-300 max-w-7xl z-20">
                <div className="pl-[15rem] pr-[calc(20rem+1px)]">
                  <SearchBar />
                </div>
              </div>
            )}
          </Responsive> */}

          {/* Content Area */}
          <div
            className={`flex flex-col justify-start items-stretch text-white relative overflow-x-hidden overscroll-y-none overflow-y-auto min-h-[100dvh]  ${
              isHiddenHeader ? "" : "pt-[52px] md:pt-0"
            } ${isIOS ? "pb-[68px]" : "pb-14 md:pb-0"} ${
              isHasIframe ? "min-h-[100dvh]" : ""
            } ${
              isShowLeftContent
                ? "md:pl-[440px] lg:pl-[600px]"
                : "md:pl-20 lg:pl-60 md:pr-[360px]"
            }`}
          >
            {children}
            {isShowUploadPost ? (
              <Responsive from={"xs"} to={"md"}>
                <UploadPost />
              </Responsive>
            ) : null}
          </div>
          {/* //Content Area */}
        </div>
      </div>

      {/* <MainLayoutPublib>
          <div className="min-h-[100dvh] flex-1 flex flex-col h-full w-full justify-center items-center">
            <LandingPage />
          </div>
        </MainLayoutPublib> */}
    </>
  );
};
