import { navMobile, navigations } from "helpers/constants";
import { Link, useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";
import clsx from "clsx";

export const MobileNavigation: React.FC = () => {
  const { pathname } = useLocation();

  const itemClass = (link: string) =>
    `nav-mobile-item cursor-pointer ${
      pathname === link ? "text-gray-100" : "text-slate-500"
    }`;
  return (
    <>
      <div
        className={clsx("nav-mobile z-0", {
          "pb-3": isIOS,
        })}
      >
        <div className="flex w-full justify-around items-center py-4 max-w-3xl mx-auto">
          {navMobile.map((item, idx) => (
            <Link key={idx} className={itemClass(item.href)} to={item.href}>
              {/* <i
                className={`h-8 ${item.icon} ${
                  pathname === item.href
                    ? "text-primary fa-solid"
                    : "text-slate-500 fa-regular"
                }`}
              ></i> */}
              <div className="flex items-center">
                <i
                  className={clsx(`text-xl ${item.iconSolid}`, {
                    "text-secondary": pathname === item.href,
                    "text-gray-500": pathname !== item.href,
                  })}
                />
              </div>
              {/* <span className="text-sm leading-none mt-1">{item.name}</span> */}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
