import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const Leaderboard = React.lazy(() => import('../page'));

const routes: AppRouteType[] = [
  {
    path: slugs.leaderboard,
    component: Leaderboard,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
];

export default routes;
