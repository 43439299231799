import {ethers} from "ethers";
import {getContract} from "utils/blockchain/get-contract";
import {mainnet, testnet} from "../../config";

export const mainnetProvider = new ethers.providers.StaticJsonRpcProvider(
    "https://eth.llamarpc.com"
);

const chilizTestnetProvider = new ethers.providers.StaticJsonRpcProvider(
    testnet.rpcUrls.default.http[0]
);

const chilizMainnetProvider = new ethers.providers.StaticJsonRpcProvider(mainnet.rpcUrls.default.http[0]
);

let chainId = 88882;
let provider = chilizTestnetProvider;
let starsLeagueAddress = getContract(chainId, "starsLeague");

if (process.env.REACT_APP_APP_ENV === "develop" || process.env.REACT_APP_APP_ENV === "testing") {
    chainId = 88882;
    provider = chilizTestnetProvider;
    starsLeagueAddress = getContract(chainId, "starsLeague");
}

if (process.env.REACT_APP_APP_ENV === "staging") {
    chainId = 88882;
    provider = chilizTestnetProvider;
    starsLeagueAddress = getContract(chainId, "starsLeague");
}

if (process.env.REACT_APP_APP_ENV === "production") {
    chainId = 88888;
    provider = chilizMainnetProvider;
    starsLeagueAddress = getContract(chainId, "starsLeague");
}

export const appConfig = {
    chainId,
    provider,
    starsLeagueAddress,
};
