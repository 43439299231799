import { XMarkIcon } from "@heroicons/react/24/outline";
import { isBrowser } from "react-device-detect";

export const CloseModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <div
      className={`absolute top-0 right-0 cursor-pointer ${
        isBrowser ? "block" : "hidden"
      }`}
      onClick={onClose}
    >
      <XMarkIcon className="w-5 h-5" />
    </div>
  );
};
