import { BigNumber, BigNumberish, ethers } from "ethers";
import {
  BASIS_POINTS_DIVISOR,
  PRECISION,
  USD_DECIMALS,
  bigNumberify,
} from "./legacy";
import { memoize, toNumber } from "lodash";
import { formatEther, formatUnits, parseEther } from "ethers/lib/utils.js";
import { CURRENCY_DISPLAY, ETH_PRICE, MNT_PRICE, getItem } from "local-storage";

const cacheGetPrice = memoize(
  (mntPrice: number, ethPrice: number) => {
    const mntPriceEth = toNumber(mntPrice / ethPrice).toFixed(10);
    const mntPriceEthEthers = parseEther(mntPriceEth.toString());

    const ethPriceMntEthers = parseEther(
      toNumber(ethPrice / mntPrice).toString()
    );
    return {
      mntPriceEthEthers,
      ethPriceMntEthers,
      mntPriceEth: toNumber(mntPriceEth),
    };
  },
  (mntPrice, ethPrice) => `${mntPrice}-${ethPrice}`
);

const getPrice = () => {
  const mntPrice = toNumber(getItem(MNT_PRICE)) || 0.4;
  const ethPrice = toNumber(getItem(ETH_PRICE)) || 1600;

  return cacheGetPrice(mntPrice, ethPrice);
};

export const convertEtherMntToEtherEth = (amount: BigNumber) => {
  const { mntPriceEthEthers } = getPrice();

  return BigNumber.from(
    formatEther(amount.mul(mntPriceEthEthers)).split(".")[0].toString()
  );
};

export const convertEtherEthToEtherMtn = (amount: BigNumber) => {
  const { ethPriceMntEthers } = getPrice();

  return BigNumber.from(
    formatEther(amount.mul(ethPriceMntEthers)).split(".")[0].toString()
  );
};

export const trimZeroDecimals = (amount: string) => {
  if (parseFloat(amount) === parseInt(amount)) {
    return parseInt(amount).toString();
  }
  return amount;
};

export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(
        0,
        amountStr.length - (decimals - maxDecimals)
      );
    }
  }
  return amountStr;
};

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(
        amountStr.length + (minDecimals - decimals),
        "0"
      );
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};

export function numberWithCommas(x: BigNumberish) {
  if (!x) {
    return "...";
  }

  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatTokenAmountWithUsd(
  tokenAmount?: BigNumber,
  usdAmount?: BigNumber,
  tokenSymbol?: string,
  tokenDecimals?: number,
  opts: {
    fallbackToZero?: boolean;
    displayDecimals?: number;
  } = {}
) {
  if (!tokenAmount || !usdAmount || !tokenSymbol || !tokenDecimals) {
    if (!opts.fallbackToZero) {
      return undefined;
    }
  }

  return `${formatTokenAmount(
    tokenAmount,
    tokenDecimals,
    tokenSymbol,
    opts
  )} (${formatUsd(usdAmount, opts)})`;
}

const calculateMinDisplayed = memoize(
  (decimals: number, displayedDecimals: number): number => {
    return 10 ** decimals / 10 ** displayedDecimals;
  },
  (decimals, displayedDecimals) => `${decimals}#${displayedDecimals}`
);

export const formatAmountMnt = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string
) => {
  const minAmount = calculateMinDisplayed(tokenDecimals, displayDecimals ?? 4);
  if (
    BigNumber.from(amount).lt(BigNumber.from(minAmount.toString())) &&
    BigNumber.from(amount).gt(0)
  ) {
    const minAmountDisplay = formatUnits(minAmount.toString(), tokenDecimals);
    return `<${minAmountDisplay}`;
  }

  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatAmountEth = (
  mntAmount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string
) => {
  const amount = convertEtherMntToEtherEth(mntAmount as BigNumber);

  const minAmount = calculateMinDisplayed(tokenDecimals, displayDecimals ?? 4);
  if (
    BigNumber.from(amount).lt(BigNumber.from(minAmount.toString())) &&
    BigNumber.from(amount).gt(0)
  ) {
    const minAmountDisplay = formatUnits(minAmount.toString(), tokenDecimals);
    return `<${minAmountDisplay}`;
  }

  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string
) => {
  const currencyDisplay: "ETH" | "CHZ" =
    (getItem(CURRENCY_DISPLAY) as any) || "ETH";

  if (currencyDisplay === "ETH") {
    return formatAmountEth(amount, tokenDecimals, 5, useCommas, defaultValue);
  } else {
    return formatAmountMnt(amount, tokenDecimals, 1, useCommas, defaultValue);
  }
};

export const formatAmountFree = (
  amount: BigNumberish,
  tokenDecimals: number,
  displayDecimals?: number
) => {
  if (!amount) {
    return "...";
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  return trimZeroDecimals(amountStr);
};

export const parseValue = (value: string, tokenDecimals: number) => {
  const pValue = parseFloat(value);

  if (isNaN(pValue)) {
    return undefined;
  }

  value = limitDecimals(value, tokenDecimals);
  const amount = ethers.utils.parseUnits(value, tokenDecimals);
  return bigNumberify(amount);
};

export function formatUsd(
  usd?: BigNumber,
  opts: { fallbackToZero?: boolean } = {}
) {
  const { fallbackToZero = false } = opts;

  if (!usd) {
    if (fallbackToZero) {
      usd = BigNumber.from(0);
    } else {
      return undefined;
    }
  }

  const sign = usd.lt(0) ? "-" : "";

  return `${sign}$${formatAmount(usd.abs(), USD_DECIMALS, 2, true)}`;
}

export function formatPercentage(
  percentage?: BigNumber,
  opts: { fallbackToZero?: boolean } = {}
) {
  if (!percentage) {
    if (opts.fallbackToZero) {
      return `${formatAmount(BigNumber.from(0), 2, 2)}%`;
    }

    return undefined;
  }

  return `${formatAmount(percentage, 2, 2)}%`;
}

export function formatTokenAmount(
  amount?: BigNumber,
  tokenDecimals?: number,
  symbol?: string,
  opts: {
    showAllSignificant?: boolean;
    displayDecimals?: number;
    fallbackToZero?: boolean;
  } = {}
) {
  const {
    displayDecimals = 4,
    showAllSignificant = false,
    fallbackToZero = false,
  } = opts;

  const symbolStr = symbol ? `${symbol}` : "";

  if (!amount || !tokenDecimals) {
    if (fallbackToZero) {
      amount = BigNumber.from(0);
      tokenDecimals = displayDecimals;
    } else {
      return undefined;
    }
  }

  const formattedAmount = showAllSignificant
    ? formatAmountFree(amount, tokenDecimals, tokenDecimals)
    : formatAmount(amount, tokenDecimals, displayDecimals);

  return `${formattedAmount} ${symbolStr}`;
}

export function roundUpDivision(a: BigNumber, b: BigNumber) {
  if (a.lt(0)) {
    return a.sub(b).add(1).div(b);
  }

  return a.add(b).sub(1).div(b);
}

export function applyFactor(value: BigNumber, factor: BigNumber) {
  return value.mul(factor).div(PRECISION);
}

export function getBasisPoints(numerator: BigNumber, denominator: BigNumber) {
  return numerator.mul(BASIS_POINTS_DIVISOR).div(denominator);
}

export function formatDeltaUsd(
  deltaUsd?: BigNumber,
  percentage?: BigNumber,
  opts: { fallbackToZero?: boolean } = {}
) {
  if (!deltaUsd) {
    if (opts.fallbackToZero) {
      return `${formatUsd(BigNumber.from(0))} (${formatAmount(
        BigNumber.from(0),
        2,
        2
      )}%)`;
    }

    return undefined;
  }

  let sign = "";
  if (!deltaUsd.eq(0)) {
    sign = deltaUsd?.gt(0) ? "+" : "-";
  }

  const percentageStr = percentage
    ? ` (${sign}${formatPercentage(percentage.abs())})`
    : "";

  return `${sign}${formatUsd(deltaUsd.abs())}${percentageStr}`;
}
export const toFixedSmallNumber = (x: number): number => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = Number(`0.${"0".repeat(e)}${x.toString().substring(2)}`);
    }
  } else {
    let e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += Number(`0${"0".repeat(e + 1)}`);
    }
  }
  return x;
};

export function convertToInternationalCurrencySystem(labelValue: string | any) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue)).toFixed(2);
}

export const currencyFormatterMnt = (value: any, currency: string = "✺") =>
  value
    ? toNumber(value) < 0.1
      ? `<0.1 ${currency}`
      : `${formatNumber(toNumber(value))} ${currency}`
    : `0 ${currency}`;

export const currencyFormatterChz = (value: any) =>
  value
    ? toNumber(value) < 0.1
      ? `<0.1`
      : `${formatNumber(toNumber(value))}`
    : `0`;

export const currencyFormatterEth = (value: any, currency: string = "Ξ") => {
  const { mntPriceEth } = getPrice();

  const eth = toNumber(value) * mntPriceEth;

  return eth
    ? toNumber(value) < 0.0001
      ? `<0.0001 ${currency}`
      : `${toNumber(eth).toFixed(4).toString()} ${currency}`
    : `0 ${currency}`;
};

export const currencyFormatter = (value: any) => {
  const coinsDisplay: "ETH" | "CHZ" =
    (getItem(CURRENCY_DISPLAY) as any) || "ETH";

  if (coinsDisplay === "ETH") {
    return currencyFormatterEth(value);
  }

  if (coinsDisplay === "CHZ") {
    return currencyFormatterChz(value);
  }
};

export const shortenString = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }

  const halfLength = Math.floor((maxLength - 3) / 2);
  const firstHalf = text.slice(0, halfLength);
  const secondHalf = text.slice(text.length - halfLength);

  return `${firstHalf}...${secondHalf}`;
};

export const formatNumber = (number: number) => {
  if (isNaN(number)) {
    return "Invalid number";
  }
  if (number === 0) return 0;

  if (number >= 1000000) {
    return (number / 1000000).toFixed(2) + "M";
  }

  if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  }

  return Number.isInteger(number)
    ? number.toString()
    : number.toFixed(1).toString();
};

let CURRENCY = "CHZ";

const CURRENCY_NAME: "ETH" | "CHZ" =
  (getItem(CURRENCY_DISPLAY) as any) || "ETH";

// if (CURRENCY_NAME === "ETH") CURRENCY = "Ξ";
// if (CURRENCY_NAME === "CHZ") CURRENCY = "CHZ";
// else if (CURRENCY_NAME === "CHZ") {
//   CURRENCY = "✺";
// } else {
//   CURRENCY = "-";
// }

switch (CURRENCY_NAME) {
  case "ETH":
    CURRENCY = "Ξ";
    break;
  case "CHZ":
    CURRENCY = "CHZ";
    break;
  default:
    CURRENCY = "-";
    break;
}

export { CURRENCY, CURRENCY_NAME };
