import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from "react";
const Settings = React.lazy(() => import("../page/Settings/Settings"));
const CurrencyDisplay = React.lazy(
  () => import("../page/CurrencyDisplay/CurrencyDisplay")
);
const NotificationSetting = React.lazy(
  () => import("../page/NotificationSetting/NotificationSetting")
);
const SocialInteraction = React.lazy(() => import("../page/SocialInteraction"));
const FinanceActivity = React.lazy(() => import("../page/FinanceActivity"));
const PrivacyPolicy = React.lazy(() => import("../page/PrivacyPolicy"));
const TermsAndConditions = React.lazy(
  () => import("../page/TermsAndConditions")
);

const routes: AppRouteType[] = [
  {
    path: slugs.settings,
    component: Settings,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.currencySettings,
    component: CurrencyDisplay,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.notificationSetting,
    component: NotificationSetting,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.socialInteractionSetting,
    component: SocialInteraction,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.financeActivitySetting,
    component: FinanceActivity,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.privacyPolicy,
    component: PrivacyPolicy,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.termsConditions,
    component: TermsAndConditions,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
];

export default routes;
