import React from "react";

const LoadingMediumDefault: React.FC = () => {
  const Cover = (
    <React.Fragment>
      <div className="absolute right-2 top-2">
        <div
          className={`flex justify-center align-middle py-1 px-2 rounded-full text-gray-100 font-semibold text-[10px] loadingAnimation w-[46px] h-[12px]`}
        />
      </div>
      <div className="h-[112px] w-full shadow-sm rounded-tl-lg rounded-tr-lg object-cover loadingAnimation" />
    </React.Fragment>
  );

  const Info = (
    <div className="flex p-4 gap-4 mt-[-30px] justify-between items-center bg-dk-gray-800">
      <div className="flex items-center gap-3 w-full">
        <div className="shadow-sm flex justify-center items-center relative">
          <div className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover loadingAnimation" />
        </div>
        <div className="flex-1 flex h-[50px] rounded-lg loadingAnimation" />
      </div>
    </div>
  );

  return (
    <div className="border rounded-lg border-dk-gray-800 md:hover:bg-base-200 transition cursor-pointer">
      {Cover}
      <div>
        {Info}
        <div className="p-4 border-t border-dk-gray-800 bg-dk-gray-800">
          <div className="loadingAnimation h-[20px] rounded-lg" />
        </div>
      </div>
    </div>
  );
};

export default LoadingMediumDefault;
