import { MainLayoutSimple } from "components/layouts/MainLayoutSimple";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from "react";
const InviteCode = React.lazy(() => import("../page"));

const routes: AppRouteType[] = [
  {
    path: slugs.inviteCode,
    component: InviteCode,
    container: MainLayoutSimple,
    isAuthNeeded: true,
  },
  {
    path: slugs.inviteCode,
    component: InviteCode,
    container: MainLayoutSimple,
    isAuthNeeded: false,
  },
];

export default routes;
