import { MainLayoutPublib2 } from "components/layouts/MainLayoutPublib2";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from "react";
const LinkTwitter = React.lazy(() => import("../page"));

const routes: AppRouteType[] = [
  {
    path: slugs.linkTwitter,
    component: LinkTwitter,
    container: MainLayoutPublib2,
    isAuthNeeded: false,
  },
];

export default routes;
