import home from "modules/home/route";
import MyApp from "modules/apps/route";
import AppDetail from "modules/app-detail/route";
import auth from "modules/auth/route";
import explore from "modules/explore/route";
import inbox from "modules/inbox/route";
import inboxDetail from "modules/inbox-detail/route";
import accountVerify from "modules/account-verify/route";
import linkTwitter from "modules/link-twitter/route";
import accountVerified from "modules/account-verify-done/route";
import inviteCode from "modules/invite-code/route";
import depositEth from "modules/deposit-eth/route";
import buyFirstShare from "modules/buy-first-share/route";
import upload from "modules/upload/route";
import profile from "modules/profile/route";
import otherProfile from "modules/other-profile/route";
import balanceProfile from "modules/profile-balance/route";
import live from "modules/live/route";
import activities from "modules/activities/route";
import point from "modules/point/route";
import enableNotifications from "modules/enable-notifications/route";
import referralShare from "modules/referral-share/route";
import myInviteCode from "modules/my-invite-code/route";
import settings from "modules/setting/route";
import bid from "modules/bid/route";
import feed from "modules/feed/route";
import feedDetail from "modules/feed-detail/route";
import leaderboard from "modules/leaderboard/route";
import notification from "modules/notification/route";
import stakes from "modules/stakes/route";
import staking from "modules/staking/route";
import searchUser from "modules/search-user/route";
import agePage from "modules/age/route";

const mainRouters: AppRouteType[] = [
  ...home,
  ...MyApp,
  ...AppDetail,
  ...auth,
  ...feed,
  ...feedDetail,
  ...explore,
  ...inbox,
  ...profile,
  ...inboxDetail,
  ...accountVerify,
  ...inviteCode,
  ...linkTwitter,
  ...depositEth,
  ...buyFirstShare,
  ...upload,
  ...otherProfile,
  ...balanceProfile,
  ...live,
  ...activities,
  ...point,
  ...accountVerified,
  ...enableNotifications,
  ...referralShare,
  ...myInviteCode,
  ...bid,
  ...settings,
  ...leaderboard,
  ...notification,
  ...stakes,
  ...staking,
  ...searchUser,
  ...agePage,
];

interface Props {
  children: React.ReactNode;
}

export type AppRouteType = {
  path: string;
  component: React.FC;
  container: React.FC<Props>;
  isAuthNeeded: boolean;
};

export { mainRouters };
