import { useCallback, useEffect, useState } from "react";
import { SearchBar } from "./components/SearchBar";
import { ACCESS_TOKEN, getItem } from "local-storage";
import {
  getUserTrendingUsers,
  searchFantechUsers,
  searchUsers,
} from "services/apis/apis";
import { Entry, Loading } from "components/commons";
import { splitArray } from "helpers/constants";
import { useSnapshot } from "valtio";
import { newService } from "proxy-state/home/top";
import useGetList from "core/hooks/useGetList";
import { getNewUsersUrl } from "services/apis/urls";

export const LeftSubContent = () => {
  const accessToken = getItem(ACCESS_TOKEN) || "";
  const { data, page, isLoading, isFirstTimeFetch } = useSnapshot(
    newService.state
  );

  const [search, setSearch] = useState("");
  // const [isLoading, setIsLoading] = useState(true);
  const [datas, setDatas] = useState<any[]>([]);
  const [isResetSearch, setIsResetSearch] = useState(true);

  const onSuccess = (result: any[]) => {
    newService.nextPage();
    newService.concatData(result);
    if (newService.state.isFirstTimeFetch) {
      newService.setIsFirstTimeFetch(false);
    }
  };
  const { targetRef, isLimit, getList } = useGetList(
    getNewUsersUrl,
    { page, limit: 15 },
    onSuccess
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (search) {
        handleSearch();
        setIsResetSearch(false);
      } else {
        setDatas([]);
        setIsResetSearch(true);
      }
    }
  };

  let outputArrayDesktop =
    datas.length > 0 ? splitArray(datas, 1) : splitArray(data as any, 1);

  const blockItem = (items: any) =>
    items?.map((item: any, idx: number) => {
      return (
        <Entry.View
          size={Entry.SizeEntry.SMALL}
          type={Entry.TypeEntry.SMALL_HOME_TRENDING_TODAY}
          key={`${idx}_trending_today`}
          injectProps={{ item, idx }}
        />
      );
    });

  const contentItems = (array: any, _element: any): any =>
    array?.map((item: any, index: number) => {
      return (
        <div
          key={index}
          className="transition cursor-pointer px-3 pt-3 hover:bg-dk-gray-800 rounded-lg"
        >
          <div className="relative">
            <div className="flex flex-col gap-3">
              {typeof _element === "function" ? _element(item) : _element}
            </div>
          </div>
        </div>
      );
    });

  const handleSearch = useCallback(async () => {
    try {
      if (!accessToken) return;
      // setIsLoading(true);
      let res;

      // if (currentTabProps === "On X") {
      //   res = await searchUsers(accessToken, val);
      // } else {
      res = await searchFantechUsers(accessToken, search);
      // }
      setDatas(res);
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
    }
  }, [accessToken, search]);

  useEffect(() => {
    const f = async () => {
      if (!search) return;
      try {
        if (!accessToken) return;
        // setIsLoading(true);
        const res = await searchUsers(accessToken, search);
        setDatas(res);
        // setIsLoading(false);
      } catch (error) {
        // setIsLoading(false);
      }
    };
    f();
  }, [accessToken]);

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className=" border-r border-dk-gray-800 h-screen overflow-y-auto">
      <div className="fixed top-0 max-w-[360px] w-full z-20">
        <SearchBar
          search={search}
          setSearch={setSearch}
          handleKeyDown={handleKeyDown}
          handleSearch={handleSearch}
        />
      </div>
      <div className="px-5 pb-4 pt-[85px]">
        <h4 className="text-xl font-medium text-gray-100">Profiles</h4>
      </div>
      <div className="flex px-2 flex-col">
        {isLoading && isFirstTimeFetch
          ? contentItems(
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
              // @ts-ignore

              <Loading.View type={Loading.type.SMALL_DEFAULT} limit={1} />
            )
          : contentItems(outputArrayDesktop, blockItem)}
        {/* : null} */}

        {!isLimit && isResetSearch && (
          <div
            className="flex flex-col gap-4 pl-4 pr-2"
            // @ts-ignore
            ref={targetRef}
          >
            <Loading.View type={Loading.type.SMALL_DEFAULT} limit={3} />
          </div>
        )}
      </div>
    </div>
  );
};
