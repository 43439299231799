export const ACCESS_TOKEN = "access_token";
export const IS_SETUP_NOTIFICATION = "is_setup_notification";
export const CLIENT_VERSION = "client_version";
export const PRIVY_TOKEN = "privy:token";

export const PROFILE_ID = "profile_id";
export const USER_INFO = "user_info";

export const AUTH_ERROR = "auth_error";
export const TALKJS_SIGNATURAL = "talkjs_signature";

export const IS_REQUIRED_RE_OPEN_PWA = "is_required_re_open_pwa";

export const IS_HIDE_REFERRAL_NOTE = "IS_HIDE_REFERRAL_NOTE";
export const IS_HIDE_RESTART_CURRENCY_DISPLAY =
  "IS_HIDE_RESTART_CURRENCY_DISPLAY";

export const IS_FIRST_CLICK_LOGIN = "is_first_click_login";
export const ETH_PRICE = "eth_price";
export const CHZ_PRICE = "chz_price";
export const MNT_PRICE = "mnt_price";
export const CURRENCY_DISPLAY = "currency_display";

export const getItem = (key: string) => {
  return localStorage.getItem(key);
};

export const saveItem = (key: string, value: any) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value);
  }
};

export const deleteItem = () => {
  const isSetupNotification = getItem(IS_SETUP_NOTIFICATION);
  localStorage.clear();
  saveItem(IS_SETUP_NOTIFICATION, isSetupNotification);
};
