import { useState } from "react";
import { copyPostSlug } from "utils/document";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/solid";

export const CopyPostLink = (props: { slug: string; id: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div
      className="px-4 py-2.5 text-left flex items-center justify-between cursor-pointer gap-2"
      onClick={(e) => {
        e.stopPropagation();
        setIsCopied(true);
        // copyPostSlug(props?.slug);
        copyPostSlug(props?.id);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }}
    >
      <button className="">Copy link</button>
      {isCopied ? (
        <ClipboardDocumentCheckIcon className="w-4 h-4 text-green-600" />
      ) : (
        <ClipboardDocumentIcon className="w-4 h-4" />
      )}
    </div>
  );
};
