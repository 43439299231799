import clsx from "clsx";
import React from "react";
import { ReactComponent as LikeBlue } from "assets/images/like-blue.svg";
import avatarDefault from "assets/images/fake-topstake/avatar/avatar1.png";
import { TypeEntry } from "../../Entry";
import { ReactComponent as IconBell } from "assets/icons/bell.svg";

interface ItemInfo {
  label: string;
  value: string | number;
  styleLabel: string;
  styleValue: string;
}

interface Props {
  cover: string;
  avatar: string;
  name: string;
  follower: number;
  status: "STAKE" | "SOON";
  itemInfo: ItemInfo[];
  type: TypeEntry;
}

interface ItemProps {
  label: string;
  value: string | number;
  styleLabel?: string;
  styleValue?: string;
  defaultStyleLabel?: string;
  defaultStyleValue?: string;
}

const Item: React.FC<ItemProps> = ({
  label,
  value,
  styleLabel,
  styleValue,
  defaultStyleLabel = "text-gray-500 mr-1",
  defaultStyleValue = "text-gray-500 mr-1",
}) => (
  <React.Fragment>
    <span className={clsx(styleLabel || defaultStyleLabel)}>{label}</span>
    <span className={clsx(styleValue || defaultStyleValue)}>{value}</span>
  </React.Fragment>
);

const EntryMedium: React.FC<Props> = (props) => {
  const { cover, avatar, follower, name, status, itemInfo, type } = props;

  const Cover = (
    <React.Fragment>
      <div className="absolute right-2 top-2">
        <div
          className={`flex justify-center align-middle py-1 px-2 rounded-full 
          ${status === "STAKE" ? "bg-[#65A30D]" : ""} 
          ${status === "SOON" ? "bg-[#1BC57E]" : ""} 
          text-gray-100 font-semibold text-[10px]`}
        >
          {status || "SOON"}
        </div>
      </div>
      <img
        className="h-[112px] w-full shadow-sm rounded-tl-lg rounded-tr-lg object-cover"
        src={cover}
        alt="img-demo"
      />
    </React.Fragment>
  );

  const Info = (
    <div className="flex p-4 gap-4 mt-[-30px] justify-between items-center">
      <div className="flex items-center gap-3 w-full">
        <div className="shadow-sm flex justify-center items-center relative">
          <img
            className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
            src={avatar || avatarDefault}
            alt="avatar"
          />
        </div>
        <div className="flex-1 flex justify-between gap-1">
          <div className="max-w-[60vw] mt-5 flex flex-col items-baseline transition-all truncate w-full">
            <p className="text-base font-normal mr-1 mb-1 flex items-center gap-1">
              <span className="font-semibold pt-1">{name}</span>
              {/* {!!isTwich && <LikeBlue />}  */}
              <LikeBlue />
            </p>
            <span className="text-sm font-normal text-gray-500">
              {follower} Followers
            </span>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );

  switch (type) {
    case TypeEntry.MEDIUM_TOP_STAKES:
      return (
        <div className="bg-dk-gray-800 rounded-lg md:hover:bg-base-200 transition cursor-pointer">
          <div className="relative">
            {Cover}
            <div>
              {Info}
              <div className="text-sm font-medium px-4 py-2 border-t border-dk-gray-700">
                <div className="flex justify-between items-baseline gap-2">
                  <div>
                    <Item
                      label={itemInfo[0].label}
                      value={itemInfo[0].value}
                      styleLabel={itemInfo[0].styleLabel}
                      styleValue={itemInfo[0].styleValue}
                      defaultStyleLabel="text-gray-500 mr-1"
                      defaultStyleValue="text-sky-500 whitespace-nowrap"
                    />
                  </div>
                  <div className="flex gap-2">
                    {itemInfo
                      .slice(1)
                      .map(
                        ({
                          label,
                          value,
                          styleValue,
                          styleLabel,
                        }: {
                          label: string;
                          value: string | number;
                          styleValue: string;
                          styleLabel: string;
                        }) => (
                          <div key={`${label}`}>
                            <Item
                              label={label}
                              value={value}
                              styleLabel={styleLabel}
                              styleValue={styleValue}
                            />
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case TypeEntry.MEDIUM_STAKES:
      return (
        <div className="rounded-lg md:hover:bg-base-200 transition cursor-pointer mb-4 bg-dk-gray-800">
          <div className="relative flex justify-end ">
            <div
              className={`absolute top-2 right-2 align-middle py-1 px-2 rounded-full ${
                status === "STAKE" ? "bg-[#65A30D]" : ""
              } ${status === "SOON" ? "bg-[#1BC57E]" : ""} 
                  text-gray-100 font-semibold text-[10px]`}
            >
              {status || "SOON"}
            </div>
          </div>
          <div>
            <div className="flex p-4 gap-4 justify-between items-center ">
              <div className="flex items-center gap-4 w-full">
                <div className="shadow-sm flex justify-center items-center relative">
                  <img
                    className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                    src={avatar || avatarDefault}
                    alt="avatar"
                  />
                </div>
                <div className="flex-1 flex justify-between gap-1">
                  <div className="max-w-[60vw] flex flex-col items-baseline transition-all truncate w-full">
                    <p className="text-base font-normal mr-1 mb-1 flex items-center gap-1">
                      <span className="font-semibold">{name}</span>
                      {/* {!!isTwich && <LikeBlue />}  */}
                      <LikeBlue />
                    </p>
                    <span className="text-sm font-normal text-gray-500">
                      {follower} Followers
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
            <div className="text-sm font-medium px-4 py-2 border-t border-dk-gray-700">
              <div className="flex justify-between items-baseline gap-2">
                <div>
                  <Item
                    label={itemInfo[0].label}
                    value={itemInfo[0].value}
                    styleLabel={itemInfo[0].styleLabel}
                    styleValue={itemInfo[0].styleValue}
                    defaultStyleLabel="text-gray-500 mr-1"
                    defaultStyleValue="text-sky-500 whitespace-nowrap"
                  />
                </div>
                <div className="flex gap-2">
                  {itemInfo
                    .slice(1)
                    .map(
                      ({
                        label,
                        value,
                        styleValue,
                        styleLabel,
                      }: {
                        label: string;
                        value: string | number;
                        styleValue: string;
                        styleLabel: string;
                      }) => (
                        <div key={`${label}`}>
                          <Item
                            label={label}
                            value={value}
                            styleLabel={styleLabel}
                            styleValue={styleValue}
                          />
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    case TypeEntry.MEDIUM_BANNER_STAKES:
      return (
        <div className="border rounded-lg border-gray-800 md:hover:bg-base-200 transition cursor-pointer w-full">
          {Cover}
          <div className="flex p-4 gap-4 mt-[-30px] justify-between items-center bg-dk-gray-800 w-full flex-nowrap text-ellipsis overflow-hidden">
            <div className="flex items-center gap-4 w-full flex-nowrap text-ellipsis overflow-hidden">
              <div className="shadow-sm flex justify-center items-center relative">
                <img
                  className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover"
                  src={avatar || avatarDefault}
                  alt="avatar"
                />
              </div>
              <div className="mt-5 flex flex-col items-baseline transition-all truncate w-full">
                <div className="flex items-center gap-1 w-full truncate">
                  <div className="text-base font-medium flex-nowrap text-ellipsis overflow-hidden">
                    {name}
                  </div>
                  {/* {!!isTwich && <LikeBlue />}  */}
                  <div className="w-4 h-4 block">
                    <LikeBlue />
                  </div>
                </div>
                <span className="text-sm font-normal text-gray-500">
                  {follower} Followers
                </span>
              </div>
            </div>
            <div className="mt-5 bg-dk-gray-700 p-2 rounded-lg">
              <IconBell />
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="border rounded-lg border-gray-800 md:hover:bg-base-200 transition cursor-pointer">
          <div className="relative">
            {Cover}
            <div>
              {Info}
              <div className="text-sm font-medium p-4 border-t border-gray-800 bg-gray-800">
                <div className="flex justify-between items-baseline gap-2">
                  <div>
                    <Item
                      label={itemInfo[0].label}
                      value={itemInfo[0].value}
                      styleLabel={itemInfo[0].styleLabel}
                      styleValue={itemInfo[0].styleValue}
                      defaultStyleLabel="text-gray-500 mr-1"
                      defaultStyleValue="text-sky-500 whitespace-nowrap"
                    />
                  </div>
                  <div className="flex gap-2">
                    {itemInfo
                      .slice(1)
                      .map(
                        ({
                          label,
                          value,
                          styleValue,
                          styleLabel,
                        }: {
                          label: string;
                          value: string | number;
                          styleValue: string;
                          styleLabel: string;
                        }) => (
                          <div key={`${label}`}>
                            <Item
                              label={label}
                              value={value}
                              styleLabel={styleLabel}
                              styleValue={styleValue}
                            />
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
};

export default EntryMedium;
