import React from "react";

const LoadingSmallDefault: React.FC = () => {
  return (
    <div className="flex items-center rounded-lg loadingAnimation gap-4 w-full p-2 mb-3">
      <div className="shadow-sm justify-center items-center relative">
        <div className="min-w-[64px] h-16 w-16  shadow-sm rounded-lg object-cover loadingAnimation" />
      </div>
      <div className=" flex-1 flex justify-between items-center gap-4 ">
        <div className="flex flex-col gap-2 truncate w-full">
          <div className="max-w-[56vw] h-3 rounded items-baseline transition-all truncate w-full loadingAnimation"></div>
          <div className="max-w-[56vw] h-3 rounded items-baseline transition-all truncate w-full loadingAnimation"></div>
        </div>
        <div className="flex flex-col gap-2 truncate w-[40px] mr-2">
          <div className="max-w-[40px] h-3 rounded items-baseline transition-all truncate w-full loadingAnimation"></div>
          <div className="max-w-[40px] h-3 rounded items-baseline transition-all truncate w-full loadingAnimation"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSmallDefault;
