const slugs = {
  // Private pages
  home: "/",
  explore: "/explore",
  feed: "/feed",
  feedDetail: "/feed/:id",
  feedDetailMetaSEO: "/s/f/:id",
  inboxDetail: "/inbox/:id",
  inbox: "/inbox",
  notification: "/notification",
  live: "/live",
  profile: "/profile",
  activities: "/profile/activities/:id",
  profileBalance: "/profile-balance",
  referralShare: "/referral-share",
  otherProfile: "/profile/:id/",
  otherProfileMetaTag: "/s/:id/",
  bid: "/bid/:id/",
  bidding: "/bidding",
  buyFirstShare: "/buy-first-share",
  upload: "/upload",
  uploadPremium: "/upload/:convId",
  point: "/point",
  myInviteCode: "/my-invite-code",
  settings: "/settings",
  currencySettings: "/settings/currency",
  notificationSetting: "/settings/notification-setting",
  socialInteractionSetting: "/notification-setting/social",
  financeActivitySetting: "/notification-setting/finance",
  myApp: "/my-app",
  appDetail: "/my-app/:id",
  leaderboard: "/leaderboard",
  stakes: "/stakes",
  staking: "/staking/:id",
  holders: "/holders/:id",
  holdings: "/holdings/:id",
  enableNotifications: "/enable-notifications",
  inviteCode: "/invite-code",
  depositEth: "/deposit-eth",
  privacyPolicy: "/settings/privacy-policy",
  termsConditions: "/settings/terms-conditions",
  searchUser: "/search-user",
  age: "/age",

  // Public pages
  accountVerify: "/account-verify",
  linkTwitter: "/link-twitter",
  acccountVerified: "/account-verified",
  login: "/login",
};

export const ignoreAuthPaths = [
  slugs.accountVerify,
  slugs.login,
  slugs.linkTwitter,
];

export default slugs;
