import { ACCESS_TOKEN, getItem } from "local-storage";
import {
  getPostHot,
  getRecentPost,
  getUserActivitiesById,
  getUserProfileData,
} from "services/apis/apis";
import useSWR from "swr";
import { useSnapshot } from "valtio";
import { navigateTab, setTab } from "proxy-state/global";
import { feedState } from "proxy-state/feed";
import { useEffect } from "react";
import { activeService } from "proxy-state/user/activity";

export const useController = () => {
  const snapNavigateTab = useSnapshot(navigateTab);
  const currentTab = snapNavigateTab.currentTab.RightSlide.currentTab;
  const recentState = useSnapshot(feedState.recentService.state);
  const hotState = useSnapshot(feedState.hotService.state);
  const activeState = useSnapshot(activeService.state);

  const accessToken = getItem(ACCESS_TOKEN) || "";

  const handleChangeTab = (value: string) => {
    setTab("RightSlide", value);
  };

  const { data } = useSWR(["getUserProfileData"], async () => {
    if (!accessToken) return;
    return getUserProfileData(accessToken);
  });

  /// Fetch recent data
  useEffect(() => {
    const f = async () => {
      try {
        if (currentTab !== "Global") return;
        feedState.recentService.setIsLoading(true);
        const result = await getRecentPost();
        feedState.recentService.setData(result);
        if (recentState.isFirstTimeFetch) {
          feedState.recentService.setIsFirstTimeFetch(false);
        }
      } catch (error) {
      } finally {
        feedState.recentService.setIsLoading(false);
      }
    };
    f();
  }, [currentTab, recentState.isFirstTimeFetch]);

  /// Fetch post hot data
  useEffect(() => {
    const f = async () => {
      try {
        if (currentTab !== "Feed") return;
        feedState.hotService.setIsLoading(true);
        const result = await getPostHot();
        feedState.hotService.setData(result);

        if (hotState.isFirstTimeFetch) {
          feedState.hotService.setIsFirstTimeFetch(false);
        }
      } catch (error) {
      } finally {
        feedState.hotService.setIsLoading(false);
      }
    };
    f();
  }, [currentTab, hotState.isFirstTimeFetch]);

  /// Fetch recent data
  useEffect(() => {
    const f = async () => {
      try {
        if (currentTab !== "Trade" || !data?.id) return;
        activeService.setIsLoading(true);
        const result = await getUserActivitiesById(accessToken, data?.id);
        activeService.setData(result);
        if (activeService.state.isFirstTimeFetch) {
          activeService.setIsFirstTimeFetch(false);
        }
      } catch (error) {
      } finally {
        activeService.setIsLoading(false);
      }
    };
    f();
  }, [currentTab, activeState.isFirstTimeFetch, data?.id, accessToken]);

  return {
    currentTab,
    handleChangeTab,
    state: { activeState, recentState, hotState },
  };
};
