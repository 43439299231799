import React from "react";

const LoadingItemLeaderboard: React.FC = () => {
  return (
    <div className="w-full flex flex-col">
      <div
        className={`flex loadingAnimation gap-4 justify-between items-center w-full overflow-hidden text-ellipsis whitespace-nowrap flex-nowrap rounded-t-lg `}
      >
        <div className="flex items-center gap-4 w-full flex-nowrap text-ellipsis overflow-hidden pt-4 pl-4">
          <div className="relative flex flex-col justify-center items-center flex-none">
            <span
              className={
                "loadingAnimation min-w-[36px] h-5 flex justify-center items-center text-center rounded-lg font-bold text-xs max-h-5"
              }
            ></span>
          </div>

          <div className="h-9 min-w-[36px] rounded-lg loadingAnimation"></div>

          <div className="flex items-center gap-1 w-full truncate">
            <div
              className={`text-base loadingAnimation font-medium flex-nowrap text-ellipsis overflow-hidden`}
            ></div>
            <div className="w-full rounded h-4 block loadingAnimation"></div>
          </div>
        </div>

        <div className="flex-1 w-full loadingAnimation"></div>
      </div>

      <div className="flex loadingAnimation gap-4 w-full rounded-b-lg">
        <div className="w-[88px]" />
        <div className="h-3 grow border-b border-dk-gray-800" />
      </div>
    </div>
  );
};

export default LoadingItemLeaderboard;
