import { MainLayoutSimple } from "components/layouts/MainLayoutSimple";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const DepositEth = React.lazy(() => import("../page"));

const routes: AppRouteType[] = [
  {
    path: slugs.depositEth,
    component: DepositEth,
    container: MainLayoutSimple,
    isAuthNeeded: true,
  },
];

export default routes;
