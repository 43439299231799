import { compareObjects } from "utils/object";
import { proxy } from "valtio";

interface State {
    data: any;
    updatedAt: Date;
    isLoading: boolean;
    isFirstTimeFetch: boolean;
}

const defaultState: State = {
    data: {},
    updatedAt: new Date(),
    isLoading: true,
    isFirstTimeFetch: true,
};

const state = proxy<State>(defaultState);

export const profileService = {
    state,
    setData: (data: any) => {
        const isSame = compareObjects(data, state.data);
        if (isSame) return;
        state.data = data;
        state.updatedAt = new Date();
    },
    setIsLoading: (value: boolean) => {
        state.isLoading = value;
    },
    setIsFirstTimeFetch: (value: boolean) => {
        state.isFirstTimeFetch = value;
    },
};
