import { notificationTabs } from "helpers/constants";
import { useController } from "./Controller";
import clsx from "clsx";
import { Breadcum } from "components/commons/Breadcum";
import { NotiCard } from "../components/NotiCard";
import { useSnapshot } from "valtio";
import { notiProxyState } from "proxy-state/notification";
// import { NumberNoti } from "components/notification/NumberNoti";
import { ReactComponent as Ellipse1 } from "assets/icons/ellipse1.svg";

function Notification() {
  const { currentTab, handleChangeTab, state } = useController();
  const { countFinance, countSGE, countSocial } = useSnapshot(
    notiProxyState.data
  );

  const tabRender = () => {
    return (
      <div className="flex-1 pt-2">
        {currentTab === "Finance" && <NotiCard type="finance" />}
        {currentTab === "Social" && <NotiCard type="social" />}
        {currentTab === "SGE" && <NotiCard type="sge" />}
      </div>
    );
  };

  const renderCount = (tab: string) => {
    switch (tab) {
      case "Social":
        return countSocial > 0 ? (
          // <NumberNoti
          //   amount={countSocial}
          //   customClass="w-6 h-6 text-xs leading-3 font-meidum"
          // />
          <div className="w-[6px] h-[6px] bg-red-500 rounded-full"></div>
        ) : null;
      case "Finance":
        return countFinance > 0 ? (
          // <NumberNoti
          //   amount={countFinance}
          //   customClass="w-6 h-6 text-xs leading-3 font-medium"
          // />
          <div className="w-[6px] h-[6px] bg-red-500 rounded-full"></div>
        ) : null;
      case "SGE":
        return countSGE > 0 ? (
          <div className="w-[6px] h-[6px] bg-red-500 rounded-full"></div>
        ) : // <NumberNoti
        //   amount={countSGE}
        //   customClass="w-6 h-6 text-xs leading-3 font-meidum"
        // />
        null;
      default:
        return null;
    }
  };

  return (
    <div className="flex-1 flex flex-col h-[100dvh] overflow-y-auto border_right_content">
      <Breadcum
        title="Notifications"
        className="bg-base-300 fixed top-0 w-full z-50 responsive_content_in_layout"
      />
      <div className="w-full border-b border-gray-800 bg-base-300 mt-12 fixed top-0 flex z-50 responsive_content_in_layout">
        <div className="flex gap-8 items-center px-4 z-10 ">
          {notificationTabs.map((tab) => (
            <div
              key={tab.name}
              className="whitespace-nowrap text-center rounded text-base text-slate-300 font-semibold cursor-pointer flex-1 flex flex-col gap-3 pt-3"
              onClick={() => {
                handleChangeTab(tab.name);
              }}
            >
              <div
                className={clsx("flex items-center gap-2", {
                  " text-white font-semibold": tab.name === currentTab,
                  "": tab.name !== currentTab,
                })}
              >
                {tab.name === "SGE" ? "AGE" : tab.name}
                {renderCount(tab.name)}
              </div>

              <div
                className={clsx("h-1 w-full rounded", {
                  "bg-secondary": tab.name === currentTab,
                  "bg-transparent": tab.name !== currentTab,
                })}
              ></div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex-1 flex flex-col mt-24">{tabRender()}</div>{" "}
    </div>
  );
}

export { Notification as default };
