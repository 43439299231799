import React from "react";

function LoadingMediumStake() {
  return (
    <div className="rounded-lg md:hover:bg-base-200 transition cursor-pointer mb-4 bg-dk-gray-800 loadingAnimation">
      <div className="relative flex justify-end ">
        <div
          className={`absolute top-2 right-2 align-middle h-5 w-12 rounded-full loadingAnimation`}
        ></div>
      </div>
      <div>
        <div className="flex p-4 gap-4 justify-between items-center ">
          <div className="flex items-center gap-4 w-full">
            <div className="shadow-sm flex justify-center items-center relative">
              <div className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover loadingAnimation"></div>
            </div>
            <div className="flex-1 flex justify-between gap-1">
              <div className="max-w-[60vw] flex flex-col items-baseline transition-all truncate w-full">
                <p className="text-base font-normal mr-1 mb-1 flex items-center gap-1 h-5 w-24 rounded-md loadingAnimation"></p>
                <span className="text-sm font-normal text-gray-500  h-5 w-24 rounded-md loadingAnimation"></span>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="text-sm font-medium px-4 py-2 border-t border-dk-gray-700">
          <div className="flex justify-between items-baseline gap-2">
            <div>
              <div className="flex gap-2">
                <span className={"loadingAnimation h-5 w-12 rounded-md"}></span>
                <span className={"loadingAnimation h-5 w-12 rounded-md"}></span>
              </div>
            </div>
            <div className="flex gap-2 loadingAnimation h-5 w-48 rounded-md"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingMediumStake;
