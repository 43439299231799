import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const Activities = React.lazy(() => import("../page"));

const routes: AppRouteType[] = [
  {
    path: slugs.activities,
    component: Activities,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
];

export default routes;
