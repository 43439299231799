import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as Indicator } from "assets/icons/indicator.svg";
import { isBrowser, isAndroid, isIOS } from "react-device-detect";

import clsx from "clsx";

interface Props {
  isOpen: boolean;
  toggleModal: () => void;
  children: React.ReactNode;
  position?: "bottom" | "center";
  readonly?: boolean;
}
export const Modal = ({
  isOpen,
  toggleModal,
  children,
  position = isBrowser && !isAndroid && !isIOS ? "center" : "bottom",
  readonly = false,
}: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        onClose={() => {
          if (readonly) return;
          toggleModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#FFFFFF33] bg-opacity-20 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 overflow-hidden"
          onClick={() => {
            if (readonly) return;
            toggleModal();
          }}
        >
          <div
            className={`flex min-h-full justify-center text-center ${
              position === "center" ? "px-4 items-center" : "p-0 items-end"
            }`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "bg-dk-gray-900 w-full relative transform text-left transition-all !overflow-auto",
                  {
                    "max-w-md rounded-3xl p-4 max-h-[85vh]": isBrowser,
                    "rounded-t-3xl border-b-none max-h-[75vh]": !isBrowser,
                  }
                )}
              >
                {position === "center" ? null : (
                  <div>
                    <Indicator className="w-full" />
                  </div>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
