import { MainLayout } from "components/layouts/MainLayout";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from "react";
const Home =  React.lazy(() => import('../page/Home'));

const routes: AppRouteType[] = [
  {
    path: slugs.home,
    component: Home,
    container: MainLayout,
    isAuthNeeded: true,
  },
];

export default routes;
