import { Channel } from "@sendbird/uikit-react";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { ComingSoon, Loading } from "components/commons";
import { ACCESS_TOKEN, getItem } from "local-storage";
import { useEffect, useState } from "react";
import { getChatSessionInfo, getUserLiveInfo } from "services/apis/apis";
import { IUserLiveInfo } from "services/apis/types";
import { getFromCookie, setToCookie } from "services/cookie/user-live-info";

export function SendbirdChat({
  fanGroup,
  id = "chat-detail",
}: {
  fanGroup?: string;
  id?: string;
}) {
  const [userInfo, setUserInfo] = useState<IUserLiveInfo>();

  const applicationId = process.env.REACT_APP_SENDBIRD_APP_ID || "";
  const accessToken = getItem(ACCESS_TOKEN) || "";

  useEffect(() => {
    const _userInfo = getFromCookie();
    if (_userInfo) {
      setUserInfo(_userInfo);
    } else {
      getChatSessionInfo(accessToken).then((user) => {
        setUserInfo(user);
        setToCookie(user);
      });
    }
  }, [accessToken]);

  const myColorSet = {
    "--sendbird-dark-background-700": "transparent",
    "--sendbird-dark-background-600": "transparent",
    "--sendbird-dark-background-500": "#1C2029",
    "--sendbird-dark-background-400": "#292f3d",
    "--sendbird-dark-primary-200": "#279EFF",
    "--sendbird-dark-primary-300": "#008cff",
    "--sendbird-dark-primary-400": "#0070cc",
    "--sendbird-dark-ondark-01": "#f1f5f9",
    "--sendbird-dark-background-300": "#cbd5e1",
    "--sendbird-dark-ondark-02": "#6b7280",
    "--sendbird-dark-onlight-01": "#f1f5f9",

    "--sendbird-dark-error-200": "#ef4444",
  };

  return (
    <>
      {userInfo && userInfo?.user_id ? (
        <SendbirdProvider
          appId={applicationId}
          userId={userInfo.user_id}
          accessToken={userInfo.access_token}
          theme="dark"
          colorSet={myColorSet}
          uikitOptions={{
            groupChannel: {
              enableTypingIndicator: true,
              enableReactions: true,
            },
          }}
        >
          <div id={id} className="h-full w-full flex flex-col flex-1 relative">
            <Channel
              renderPlaceholderLoader={() => <Loading.View />}
              channelUrl={fanGroup ?? ""}
              disableUserProfile={true}
              isLoading={false}
              renderChannelHeader={() => <div></div>}
              renderPlaceholderInvalid={() => (
                <ComingSoon type="speaker" des="No messages found" />
              )}
            />
          </div>
        </SendbirdProvider>
      ) : (
        <Loading.View />
      )}
    </>
  );
}
