import { ACCESS_TOKEN, getItem } from "local-storage";
import { IBaseResponse, IUserLiveInfo, IUserLiveInfo2 } from "./types";
import {
  getApps,
  checkSubscribersUrl,
  delegateInitializeShares,
  deleteCommentByIdUrl,
  detailPostUrl,
  getAllBidsUrl,
  getBuyPriceAfterFeeUrl,
  getCheckSGELiveUrl,
  getClientVersionUrl,
  getCommentReactionUrl,
  getCommentsByPostIdUrl,
  getExploreUrl,
  getFollowingUsersUrl,
  getGroupInfoUrl,
  getHistoryBidsUrl,
  getInviteCodePremiumUrl,
  getInviteCodeUrl,
  getInviteesUrl,
  getLeaderBoardPointsUrl,
  getLeaderBoardTradersUrl,
  getLeaderBoardWhalesUrl,
  getLikedUsersByCommentIdUrl,
  getLikedUsersPostIdUrl,
  getListPostByIdUrl,
  getLiveInfoUrl,
  getNewUsersUrl,
  getNotificationUrl,
  getPoolInitialBuyPriceAfterFeeUrl,
  getPostReactionUrl,
  getPostRepostUrl,
  getRawDataSwapEthToMntUrl,
  getRawDataSwapMntToEthUrl,
  getReferralStatisticUrl,
  getRepostedUsersByPostIdUrl,
  getSGELiveUsersUrl,
  getSGEUsersUrl,
  getSubscribeUrl,
  getSubscribedUrl,
  getTrendingUsersDailyUrl,
  getTrendingUsersUrl,
  getTwitterAuthUrl,
  getUpcomingUsersUrl,
  getUserActivitiesByIdUrl,
  getUserBiddingsByIdUrl,
  getUserByIdUrl,
  getUserHoldersByIdUrl,
  getUserHoldingByIdUrl,
  getUserHoldingsStatisticUrl,
  getUserProfileUrl,
  getUserSubscribersByIdUrl,
  getUserSubscriptionsUrl,
  getUserTradeByIdUrl,
  getUserUrl,
  getunsubscribeUrl,
  postConfigSgeUrl,
  postForYouUrl,
  postHotUrl,
  postLinkTwitterUrl,
  postRecentUrl,
  pushCommentUrl,
  renewInviteCodeUrl,
  searchFantechUsersUrl,
  updateAllowNotificationUrl,
  updateInviteCodeUrl,
  updateStatusNotificationUrl,
  uploadPostUrl,
  getACategoryUrl,
  getAppUsedByIdUrl,
  getLeaderBoardPointsDailyUrl,
  getUserHolderIdsUrl,
  getUserHoldingIdsUrl,
  getBuyBack33UsersUrl,
  post33Url,
  postTipUrl,
  getTipUsersByPostIdUrl,
  upLoadMediaUrlPayView,
  detailPostPublicUrl,
  getCommentsByPostIdPublicUrl,
  getProfilePublicUrl,
  getPostsPublicUrl,
  postAddMemberToGroupChat,
  getChatSession,
  getGroupParnerInfoUrl,
  createDirectMessageGroupUrl,
  getCountNotiUrl,
  putNotiViewUrl,
  getMessageUserUnreadUrl,
  getNotificationHistoryUrl,
} from "./urls";
import { customAxios as axios } from "utils/server";
import { BigNumber } from "ethers";

export const getHeaders = (token?: string) => {
  const accessToken = getItem(ACCESS_TOKEN) ?? token;

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
};

export const searchUsers = async (token: string, searchText: string) => {
  try {
    const res = await axios.get<{ data: any[] }>(
      `${getExploreUrl}?username=${searchText}`,
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getInviteCode = async (token: string) => {
  try {
    const res = await axios.get<{ data: any[] }>(getInviteCodeUrl, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getInviteCodePremium = async () => {
  try {
    const res = await axios.get<{ data: any[] }>(getInviteCodePremiumUrl, {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const renewInviteCode = async (token: string) => {
  try {
    const res = await axios.get<{ data: any[] }>(renewInviteCodeUrl, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const searchFantechUsers = async (token: string, searchText: string) => {
  try {
    const res = await axios.get<{ data: any[] }>(
      `${searchFantechUsersUrl}?username=${searchText}`,
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserData = async () => {
  try {
    const res = await axios.get<{ data: any }>(getUserUrl);

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getFollowingUser = async (
  token: string,
  take: number,
  page: number,
  forceUpdate?: boolean
) => {
  try {
    const res = await axios.get<{ data: any }>(
      `${getFollowingUsersUrl}?take=${take}&page=${page}${forceUpdate ? "&force_update=" + forceUpdate : ""
      }`,
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserProfileData = async (
  token: string,
  isForceUpdate?: boolean
) => {
  try {
    const res = await axios.get<{ data: any }>(getUserProfileUrl, {
      headers: getHeaders(token),
      params: { force_update: isForceUpdate },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

// export const getUserStatisticData = async (token: string) => {
//   try {
//     const res = await axios.get<{ data: any }>(getUserStatisticUrl, {
//       headers: getHeaders(token),
//     });

//     return res?.data?.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const updateUserInviteCode = async (token: string, code: string) => {
  try {
    const res = await axios.post<{ data: any }>(
      updateInviteCodeUrl,
      {
        inviteCode: code,
      },
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const postTip = async (postId: string, amount: string) => {
  try {
    const res = await axios.post<{ data: any }>(
      postTipUrl,
      {
        postId: postId,
        tipAmount: amount,
      },
      {
        headers: getHeaders(),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const delegateBuyFirstShare = async (token: string) => {
  try {
    const res = await axios.post<{ data: any }>(
      delegateInitializeShares,
      {},
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const subscribe = async (token: string, idolId: string) => {
  try {
    const res = await axios.post<{ data: any }>(
      getSubscribeUrl,
      {
        idolId,
      },
      {
        headers: getHeaders(token),
      }
    );

    return true;
  } catch (error) {
    return false;
  }
};

export const unsubscribe = async (token: string, idolId: string) => {
  try {
    const res = await axios.post<{ data: any }>(
      getunsubscribeUrl,
      {
        idolId,
      },
      {
        headers: getHeaders(token),
      }
    );

    return true;
  } catch (error) {
    console.log("🚀 ~ file: apis.ts:188 ~ unsubscribe ~ error:", error);
    return false;
  }
};

export const reactionPost = async (postId: string) => {
  try {
    await axios.post<{ data: any }>(
      getPostReactionUrl,
      {
        postId: postId,
      },
      {
        headers: getHeaders(),
      }
    );

    return true;
  } catch (error) {
    return false;
  }
};

export const reactionComment = async (commentId: string) => {
  try {
    await axios.post<{ data: any }>(
      getCommentReactionUrl,
      {
        commentId,
      },
      {
        headers: getHeaders(),
      }
    );

    return true;
  } catch (error) {
    return false;
  }
};

export const replyPost = async (postId: string) => {
  try {
    await axios.post<{ data: any }>(
      getPostRepostUrl(postId),
      {},
      {
        headers: getHeaders(),
      }
    );

    return true;
  } catch (error) {
    return false;
  }
};

export const getPostByIdPublic = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(detailPostPublicUrl(id), {});

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getCommentsByPostIdPublic = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(
      getCommentsByPostIdPublicUrl(id),
      {}
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const checkSubscribers = async (token: string, userIds: string[]) => {
  try {
    const res = await axios.post<{ data: any }>(
      checkSubscribersUrl,
      {
        user_ids: userIds,
      },
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserById = async (token: string, id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserByIdUrl(id), {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserHoldingsById = async (token: string, id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserHoldingByIdUrl(id));

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getInvitees = async (token: string, type: string) => {
  try {
    const res = await axios.get<{ data: any }>(getInviteesUrl, {
      headers: getHeaders(token),
      params: { type, take: 50 }, // TODO: pagination later
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getReferralStatistic = async (token: string) => {
  try {
    const res = await axios.get<{ data: any }>(getReferralStatisticUrl, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserHoldingsStatistic = async (token: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserHoldingsStatisticUrl, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserSubscriptions = async (token: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserSubscriptionsUrl, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getAllBids = async (token: string, id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getAllBidsUrl(id), {
      params: { take: 50 },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getNotifications = async (type: any) => {
  try {
    const ENotificationCategory = {
      YourAccount: "YourAccount",
      YourHoldings: "YourHoldings",
      YourSubscribing: "YourSubscribing",
    };
    const res = await axios.get<{ data: any }>(getNotificationUrl(), {
      headers: getHeaders(),

      params: { type },
    });

    const result = res?.data?.data;

    const groupedNotifications = result?.notifications?.reduce(
      (acc: any, item: any) => {
        if (
          item.notification.category === ENotificationCategory.YourAccount ||
          item.notification.category === ENotificationCategory.YourHoldings ||
          item.notification.category === ENotificationCategory.YourSubscribing
        ) {
          const category = item.notification.category;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push({
            id: item.id,
            title: item.notification.title,
            name: item.notification.name,
            status: item.status,
            orderBy: item.notification.orderBy,
          });
        }
        return acc;
      },
      {}
    );

    Object.keys(groupedNotifications).forEach((category) => {
      groupedNotifications[category].sort(
        (a: any, b: any) => a.orderBy - b.orderBy
      );
    });

    return groupedNotifications;
  } catch (error) {
    throw error;
  }
};

export const getNotificationsHistory = async (
  type?: "social" | "finance" | "sge",
  category?: "YourAccount" | "YourHoldings" | "YourSubscribing"
) => {
  try {
    const res = await axios.get<{ data: any }>(getNotificationHistoryUrl, {
      headers: getHeaders(),
      params: { type, category, take: 100 },
    });

    const result = res?.data?.data;

    return result;
  } catch (error) {
    throw error;
  }
};

export const updateStatusNotifications = async (id: any, status: string) => {
  try {
    const res = await axios.put<{ data: any }>(
      updateStatusNotificationUrl(id),
      {
        status,
      },
      {
        headers: getHeaders(),
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateAllowNotification = async (status: string) => {
  try {
    const res = await axios.post<{ data: any }>(
      updateAllowNotificationUrl(),
      {
        status,
      },
      {
        headers: getHeaders(),
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const getHistoryBids = async (token: string, id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getHistoryBidsUrl(id), {
      headers: getHeaders(token),
      params: { take: 50 },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserHoldersById = async (token: string, id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserHoldersByIdUrl(id), {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserHolderIds = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserHolderIdsUrl(id));
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserHoldingIds = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserHoldingIdsUrl(id));
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserSubscribersById = async (id: string, take?: number) => {
  try {
    const res = await axios.get<{ data: any }>(getUserSubscribersByIdUrl(id), {
      params: { take },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserBiddingById = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserBiddingsByIdUrl(id));

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserLiveInfo = async (
  token: string
): Promise<IUserLiveInfo> => {
  try {
    const res = await axios.get<IBaseResponse<IUserLiveInfo>>(getLiveInfoUrl, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getChatSessionInfo = async (
  token: string
): Promise<IUserLiveInfo> => {
  try {
    const res = await axios.get<IBaseResponse<IUserLiveInfo>>(getChatSession, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserLiveInfo2 = async (
  token: string
): Promise<IUserLiveInfo2> => {
  try {
    const res = await axios.get<IBaseResponse<IUserLiveInfo2>>(getLiveInfoUrl, {
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getGroupInfo = async (
  token: string,
  groupUrl: string
): Promise<any> => {
  try {
    const res = await axios.get<IBaseResponse<any>>(
      `${getGroupInfoUrl}/${groupUrl}`,
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getGroupPartnerInfo = async (
  token: string,
  partnerId: string
): Promise<any> => {
  try {
    const res = await axios.get<IBaseResponse<any>>(
      `${getGroupParnerInfoUrl}/${partnerId}`,
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getUserTrendingUsers = async (token: string, take: number) => {
  try {
    const res = await axios.get<{ data: any }>(getTrendingUsersUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    console.log("🚀 ~ file: apis.ts:257 ~ getUserTrendingUser ~ error:", error);
  }
};

export const getUserUpcomingUsers = async (token: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUpcomingUsersUrl, {});
    return res?.data?.data;
  } catch (error) {
    console.log("🚀 ~ file: apis.ts:257 ~ getUserTrendingUser ~ error:", error);
  }
};

export const getUserActivitiesById = async (token: string, id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserActivitiesByIdUrl(id), {
      params: { take: 50 },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};
export const getUserTradeById = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getUserTradeByIdUrl(id), {
      params: { take: 50 },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getSubscribed = async (token: string) => {
  try {
    const res = await axios.get<{ data: any }>(getSubscribedUrl, {
      headers: getHeaders(token),
      params: { take: 100, page: 0 },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getClientConfig = async () => {
  try {
    const res = await axios.get<{ data: any }>(getClientVersionUrl, {});
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const postConfigSge = async (hour: number) => {
  try {
    const res = await axios.post<{ data: any }>(
      postConfigSgeUrl,
      {
        hour,
      },
      {
        headers: getHeaders(),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getCheckSGELive = async () => {
  try {
    const res = await axios.get<{ data: any }>(getCheckSGELiveUrl, {});
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getTwitterAuthLoginUrl = async (
  accessToken: string,
  privyToken: string
) => {
  try {
    const res = await axios.post<{ data: any }>(
      getTwitterAuthUrl,
      { token: privyToken },
      {
        headers: getHeaders(accessToken),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const uploadPost = async (data: any) => {
  const accessToken = getItem(ACCESS_TOKEN);

  try {
    const res = await axios.post<{ data: any }>(uploadPostUrl, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const uploadMediaPayToView = async (data: FormData) => {
  const accessToken = getItem(ACCESS_TOKEN);

  try {
    const res = await axios.post<{ data: any }>(upLoadMediaUrlPayView, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const pushComment = async (postId: string, content: string) => {
  const accessToken = getItem(ACCESS_TOKEN);

  try {
    const res = await axios.post<{ data: any }>(
      pushCommentUrl,
      { postId, content },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getPostById = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(detailPostUrl(id), {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getCommentsByPostId = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getCommentsByPostIdUrl(id), {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getRepostedUsersPostId = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(
      getRepostedUsersByPostIdUrl(id),
      {
        headers: getHeaders(),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getTipUsersPostId = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getTipUsersByPostIdUrl(id), {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getLikedUsersPostId = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getLikedUsersPostIdUrl(id), {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getLikedUsersByCommentId = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(
      getLikedUsersByCommentIdUrl(id),
      {
        headers: getHeaders(),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getPostForYou = async () => {
  try {
    const res = await axios.get<{ data: any }>(postForYouUrl, {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getPostHot = async () => {
  try {
    const res = await axios.get<{ data: any }>(postHotUrl, {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getRecentPost = async () => {
  try {
    const res = await axios.get<{ data: any }>(postRecentUrl, {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const get33Post = async () => {
  try {
    const res = await axios.get<{ data: any }>(post33Url, {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const deletePostById = async (id: string) => {
  try {
    const res = await axios.delete<{ data: any }>(detailPostUrl(id), {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCommentById = async (id: string) => {
  try {
    const res = await axios.delete<{ data: any }>(deleteCommentByIdUrl(id), {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const updatePostById = async (id: string, data: any) => {
  const accessToken = getItem(ACCESS_TOKEN);

  try {
    const res = await axios.put<{ data: any }>(detailPostUrl(id), data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getListPostById = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getListPostByIdUrl(id), {
      headers: getHeaders(),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const postLinkTwitter = async (code: string, state: string) => {
  try {
    const res = await axios.post<{ data: any }>(postLinkTwitterUrl, {
      code,
      state,
    });

    return { data: res?.data?.data, status: 1 };
  } catch (error: any) {
    console.log("🚀 ~ file: apis.ts:358 ~ postLinkTwitter ~ error:", error);
    return { data: error?.response?.data?.message, status: 0 };
  }
};

export const getTrendingUsers = async (token: string, take: number) => {
  try {
    const res = await axios.get<{ data: any }>(getTrendingUsersUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: apis.ts:257 ~ getTiktokUserTrendingUser ~ error:",
      error
    );
  }
};

export const getBuyBack33 = async (token: string, take: number) => {
  try {
    const res = await axios.get<{ data: any }>(getBuyBack33UsersUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: apis.ts:257 ~ getTiktokUserTrendingUser ~ error:",
      error
    );
  }
};

export const getTrendingUsersDaily = async (token: string, take: number) => {
  try {
    const res = await axios.get<{ data: any }>(getTrendingUsersDailyUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: apis.ts:257 ~ getTiktokUserTrendingUser ~ error:",
      error
    );
  }
};

export const getLeaderBoardTraders = async (token: string, take: number) => {
  try {
    const res = await axios.get<{ data: any }>(getLeaderBoardTradersUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getLeaderBoardWhales = async (token: string, take: number) => {
  try {
    const res = await axios.get<{ data: any }>(getLeaderBoardWhalesUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getLeaderBoardPoints = async (token: string, take: number) => {
  try {
    const res = await axios.get<{ data: any }>(getLeaderBoardPointsUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getLeaderBoardPointsDaily = async (
  token: string,
  take: number
) => {
  try {
    const res = await axios.get<{ data: any }>(getLeaderBoardPointsDailyUrl, {
      headers: getHeaders(token),
      params: { take },
    });
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getNewUsers = async (
  token: string,
  take: number,
  page: number = 1
) => {
  try {
    const res = await axios.get<{ data: any }>(getNewUsersUrl, {
      headers: getHeaders(token),
      params: { take, page },
    });
    return res?.data?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: apis.ts:257 ~ getTiktokUserTrendingUser ~ error:",
      error
    );
  }
};

export const getUpcomingUsers = async (token: string) => {
  try {
    const callback = () =>
      axios.get<{ data: any }>(getUpcomingUsersUrl, {
        headers: getHeaders(token),
      });
    const result = await extendCheckSubscribers(callback, token);
    return result;
  } catch (error) {
    console.log(
      "🚀 ~ file: apis.ts:257 ~ getTiktokUserTrendingUser ~ error:",
      error
    );
  }
};

export const getSGEUsers = async (
  token: string,
  priority?: number,
  status?: "Pending" | "InitDone"
) => {
  try {
    const res = await axios.get<{ data: any }>(getSGEUsersUrl, {
      params: { priority, status },
      headers: getHeaders(token),
    });
    return res?.data?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: apis.ts:257 ~ getTiktokUserTrendingUser ~ error:",
      error
    );
  }
};

export const getSGELiveUsers = async (token: string) => {
  try {
    const res = await axios.get<{ data: any }>(getSGELiveUsersUrl, {
      headers: getHeaders(token),
    });
    return res?.data?.data;
  } catch (error) {
    console.log(
      "🚀 ~ file: apis.ts:257 ~ getTiktokUserTrendingUser ~ error:",
      error
    );
  }
};

export const getFollowingUsers = async (
  token: string,
  take: number,
  page: number,
  forceUpdate?: boolean
) => {
  try {
    const res = await axios.get<{ data: any }>(
      `${getFollowingUsersUrl}?take=${take}&page=${page}${forceUpdate ? "&force_update=" + forceUpdate : ""
      }`,
      {
        headers: getHeaders(token),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

/// Block chain controller
export const getPoolInitialBuyPriceAfterFee = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(
      getPoolInitialBuyPriceAfterFeeUrl(id)
    );

    return BigNumber.from(res?.data?.data);
  } catch (error) {
    throw error;
  }
};

export const getBuyPriceAfterFee = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getBuyPriceAfterFeeUrl(id));

    return BigNumber.from(res?.data?.data);
  } catch (error) {
    throw error;
  }
};

export const getRawDataSwapEthToMnt = async (
  amount: string,
  address: string
) => {
  try {
    const res = await axios.post<{ data: any }>(getRawDataSwapEthToMntUrl, {
      amount,
      address,
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const addMemberToGroupChat = async (idUser: string) => {
  try {
    const res = await axios.post<{ data: any }>(
      postAddMemberToGroupChat,
      {
        idUser: idUser,
      },
      {
        headers: getHeaders(),
      }
    );

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getRawDataSwapMntToEth = async (
  amount: string,
  address: string
) => {
  try {
    const res = await axios.post<{ data: any }>(getRawDataSwapMntToEthUrl, {
      amount,
      address,
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

const extendCheckSubscribers = async (callback: any, accessToken: string) => {
  try {
    const result = await callback();
    const list = result?.data?.data;
    const ids = list.map((item: any) => item.id);
    const subs = await checkSubscribers(accessToken, ids);
    return list.map((item: any) => ({
      ...item,
      is_subscribed: subs.includes(item.id),
    }));
  } catch (error) {
    throw error;
  }
};

export const getACategory = async () => {
  try {
    const res = await axios.get<{ data: any }>(getACategoryUrl);

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getAppUsedById = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getAppUsedByIdUrl(id));

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getAppsData = async (
  token: string,
  category_id?: string,
  status?: string
) => {
  try {
    const res = await axios.get<{ data: any }>(getApps, {
      params: {
        category_id: category_id ? category_id : undefined,
        status,
      },
      headers: getHeaders(token),
    });

    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const voteApp = async (token: string, appId: string) => {
  try {
    const url = getApps + "/" + appId + "/vote";
    const res = await axios.put<{ data: any }>(
      url,
      {
        id: appId,
      },
      {
        headers: getHeaders(token),
      }
    );
    return res?.data?.data;
  } catch (error) {
    console.log("error", error);
    return {
      message: error?.toString(),
      status: "404",
    };
  }
};
export const activeApp = async (token: string, appId: string) => {
  try {
    const url = getApps + "/" + appId + "/active";
    const res = await axios.put<{ data: any }>(
      url,
      {
        id: appId,
      },
      {
        headers: getHeaders(token),
      }
    );
    return res?.data?.data;
  } catch (error) {
    console.log("error", error);
    return {
      message: error?.toString(),
      status: "404",
    };
  }
};

//public
export const getProfilePublic = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getProfilePublicUrl(id));
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getPostsPublic = async (id: string) => {
  try {
    const res = await axios.get<{ data: any }>(getPostsPublicUrl(id));
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const createDirectMessageGroup = async (
  user_id_1: string,
  user_id_2: string
) => {
  try {
    const res: any = await axios.post<{ data: any }>(
      createDirectMessageGroupUrl,
      {
        user_id_1: user_id_1,
        user_id_2: user_id_2,
      },
      {
        headers: getHeaders(),
      }
    );

    return res?.data?.data;
  } catch (error) {
    return error;
  }
};

//noti
export const getCountNoti = async () => {
  const accessToken = getItem(ACCESS_TOKEN);
  try {
    const res = await axios.get<{ data: any }>(getCountNotiUrl(), {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const putNotiView = async (notiId: string) => {
  const accessToken = getItem(ACCESS_TOKEN);
  try {
    const res = await axios.put<{ data: any }>(
      putNotiViewUrl(notiId),
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};

export const getMessageUserUnread = async () => {
  const accessToken = getItem(ACCESS_TOKEN);
  try {
    const res = await axios.get<{ data: any }>(
      getMessageUserUnreadUrl,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return res?.data?.data;
  } catch (error) {
    throw error;
  }
};
