import React from "react";

export default function LoadingPost() {
  return (
    <div className="rounded-md md:hover:bg-base-200 transition cursor-pointer mt-4 mx-4 loadingAnimation">
      <div>
        <div className="flex p-4 gap-4 justify-between items-center ">
          <div className="flex items-center gap-4 w-full">
            <div className="shadow-sm flex justify-center items-center relative">
              <div className="min-w-[64px] h-16 w-16 shadow-sm rounded-lg object-cover loadingAnimation"></div>
            </div>
            <div className="flex-1 flex justify-between gap-1">
              <div className="max-w-[60vw] flex flex-col gap-1 items-baseline transition-all truncate w-full">
                <p className="text-base font-normal mr-1 mb-1 flex items-center gap-1 h-3 w-3/5 rounded-md loadingAnimation"></p>
                <span className="text-sm font-normal text-gray-500  h-3 w-full rounded-md loadingAnimation"></span>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className="text-sm font-medium px-4 pb-4">
          <div className="flex flex-col justify-between items-baseline gap-2">
            <div className="flex gap-2 loadingAnimation h-2 w-3/5 rounded-md"></div>
            <div className="flex gap-2 loadingAnimation h-2 w-2/5 rounded-md"></div>
            <div className="flex gap-2 loadingAnimation h-2 w-4/5 rounded-md"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
