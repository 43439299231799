import { ACCESS_TOKEN, getItem } from "local-storage";
import { proxy, useSnapshot } from "valtio";

interface StoreState {
  isAuth: boolean;
  token: string;
}

const accessToken = getItem(ACCESS_TOKEN);

const defaultState: StoreState = {
  isAuth: !!accessToken || false,
  token: accessToken || "",
};

const store = proxy<StoreState>(defaultState);

const actions = {
  setIsAuth: (token: string = "") => {
    store.isAuth = token.length > 0;
    store.token = token;
  },
};

export { actions, store, useSnapshot };
