import { BigNumber } from "ethers";
import { proxy } from "valtio";

export const state: {
  swappedAt: Date | undefined;
  mntBalance: BigNumber;
  ethBalance: BigNumber;
} = {
  swappedAt: undefined,
  mntBalance: BigNumber.from(0),
  ethBalance: BigNumber.from(0),
};

export const swapState = proxy(state);

export const swapService = {
  state,
  setSwap: (mntBalance: BigNumber) => {
    swapState.swappedAt = new Date();
    swapState.mntBalance = mntBalance;
  },
  // setFetched: () => {
  //   state.fetchedAt = new Date();
  // },
};
