import React, { useState, useEffect } from 'react';
import coreAction from 'core/actions';
import useOnScreen from './useOnScreen';

const useGetList = (
    url: string, 
    pagination: { limit?: number, page: number }, 
    onSuccess: (data?: any, status?: number) => void = () => { },
    onFailure: (data?: any, status?: number) => void = () => { }
    ) => {

    const targetRef = React.useRef();
    const [isLimit, setIsLimit] = React.useState(false);

    const _onSuccess = (result: any[]) => {
        setIsLimit(page !== 1 && (!Array.isArray(result) || result.length === 0));
        if (page === 1 || (Array.isArray(result) && result.length > 0)) {
            onSuccess(result);
        }
    };

    const _onFailure = () => {
        onFailure();
    }

    const { limit, page } = pagination;
    const getList = async () => {
        await coreAction.callAPI(
            coreAction.method.GET,
            url,
            {
                params: { take: limit || 20, page },
            },
            _onSuccess,
            _onFailure,
        );
    }

    useOnScreen(targetRef, getList);

    return {
        targetRef,
        getList,
        isLimit,
        // setIsLimit,
    }
}

export default useGetList;