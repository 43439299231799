// import { ReactComponent as SearchUser } from "assets/icons/trySearchUser.svg";
import { ReactComponent as SearchUser } from "assets/icons/speaker.svg";

export const TrySearchUser = () => {
  return (
    <div className="p-4">
      <div className=" flex flex-col justify-center items-center gap-4 py-6 px-12 w">
        <SearchUser className="w-32" />
        <p className="text-base text-center text-slate-300">
          Try searching for user. <br />
          Find some good stuffs!
        </p>
      </div>
    </div>
  );
};
