import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from "react";
import ProfileMetaTag from "../page/RedirectOtherProfile";
const OtherProfile = React.lazy(() => import("../page/OtherProfile"));

const routes: AppRouteType[] = [
  {
    path: slugs.otherProfile,
    component: OtherProfile,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
  {
    path: slugs.otherProfile,
    component: OtherProfile,
    container: React.Fragment,
    isAuthNeeded: false,
  },
  // {
  //   path: slugs.otherProfileMetaTag,
  //   component: ProfileMetaTag,
  //   container: React.Fragment,
  //   isAuthNeeded: false,
  // },
  // {
  //   path: slugs.otherProfileMetaTag,
  //   component: ProfileMetaTag,
  //   container: MainLayoutContent,
  //   isAuthNeeded: true,
  // },
];

export default routes;
