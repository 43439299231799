import { MainLayoutPublib } from "components/layouts/MainLayoutPublib";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const Login = React.lazy(() => import("../page/Login"));

export const routerLogin: AppRouteType = {
  path: slugs.login,
  component: Login,
  container: MainLayoutPublib,
  isAuthNeeded: false,
}

const routes: AppRouteType[] = [
  routerLogin,
];

export default routes;
