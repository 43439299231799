// @ts-nocheck
import "./styles/globals.css";
import "@sendbird/uikit-react/dist/index.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { PrivyProvider } from "@privy-io/react-auth";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Buffer } from "buffer";
import { mainnet, testnet } from "config";
// import * as Sentry from "@sentry/react";

// Ref: https://stackoverflow.com/questions/68707553/uncaught-referenceerror-buffer-is-not-defined
// @ts-ignore
window.Buffer = Buffer;

// This method will be passed to the PrivyProvider as a callback
// that runs after successful login.
// Sentry.init({
//   dsn: "https://8957c70094d7cb2c4c58cc7780d69986@o4506183646773248.ingest.sentry.io/4506183674429440",
//   integrations: [
//     new Sentry.BrowserTracing(),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["https://app.starsleague.com", "https://beta.starsleague.com"],
// });

const handleLogin = (user: any) => {
  console.log(`User ${user.id} logged in!`);
};

let deferredPrompt: any;
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <PrivyProvider
        appId={process.env.REACT_APP_PRIVY_APP_ID!}
        onSuccess={handleLogin}
        config={{
          loginMethods: ["email", "google", "apple"],
          appearance: {
            theme: "dark",
            accentColor: "#FF1156",
            logo: "/images/star-league-logo.png",
            showWalletLoginFirst: false,
          },
          additionalChains: [mainnet, testnet],
          embeddedWallets: {
            createOnLogin: "off",
          },
          rpcConfig: {
            rpcUrls: {
              "1": "https://eth.llamarpc.com",
            },
          },
        }}
      >
        <App />
      </PrivyProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorkerRegistration.register();
window.addEventListener("beforeinstallprompt", (e) => {
  e.preventDefault();
  // Store the event for later use
  deferredPrompt = e;
  // Display your custom install button or message here
  showInstallButton();
});

function showInstallButton() {
  const installButton = document.getElementById("install-button");
  if (installButton) {
    installButton.style.display = "block";

    installButton.addEventListener("click", () => {
      // Show the browser's installation prompt
      deferredPrompt.prompt();

      // Wait for the user's choice
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the installation");
        } else {
          console.log("User dismissed the installation.");
        }
        // Clear the deferred prompt
        deferredPrompt = null;
      });
    });
  }
}
