import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from 'react';
const Stakes = React.lazy(() => import("../page/Stakes"));

const routes: AppRouteType[] = [
  {
    path: slugs.stakes,
    component: Stakes,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
];

export default routes;
