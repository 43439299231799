import React from "react";
import { isIOS } from "react-device-detect";
interface Props {
  children: React.ReactNode;
}

export const MainLayoutPublib: React.FC<Props> = ({ children }) => {
  return (
    <div className="container-fluid min-h-[100dvh] min-w-screen lg:pb-0 bg-gray-950 overflow-hidden z-50">
      <div className="flex flex-col items-stretch justify-center w-full h-full transition-all">
        {/* Content Area */}
        <div
          className={`flex-1 flex flex-col items-stretch justify-center min-h-[100dvh] text-gray-100 overflow-hidden`}
        >
          {children}
        </div>
        {/* //Content Area */}
      </div>
    </div>
  );
};
