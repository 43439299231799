import { useWallets } from "@privy-io/react-auth";
import {
  MainNavigation,
  Responsive,
  TabletNavigation,
} from "components/commons";
import IframeComponent from "components/commons/Iframes";
import { LandingPage } from "components/commons/LandingPage";
import { LeftSubContent } from "components/widget/LeftSubContent";
import { IS_DISABLE_PWA } from "config";
import { useAuth } from "contexts/authContext";
import slugs from "navigation/slugs";
import React, { useEffect } from "react";
import { isIOS } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { appConfig } from "services/blockchain";
import { formatAmountMnt } from "utils/number/number";

interface Props {
  children: React.ReactNode;
}

export const MainLayoutContent: React.FC<Props> = ({ children }) => {
  // const isInStandaloneMode =
  //   IS_DISABLE_PWA || window.matchMedia("(display-mode: standalone)").matches;
  const { pathname } = useLocation();
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const { balance, setBalance } = useAuth();
  useEffect(() => {
    const getBalanceBase = async () => {
      if (!embeddedWallet) return;
      const balance = await appConfig.provider.getBalance(
        embeddedWallet?.address
      );
      setBalance(formatAmountMnt(balance ?? "0", 18, 1));
    };
    getBalanceBase();
  }, [embeddedWallet]);

  const isShowLeftContent =
    pathname.includes(slugs.profile) || pathname === slugs.searchUser;
  return (
    <>
      <div className="main-layout--wrapper">
        <div
          className={`relative flex flex-col justify-start items-stretch text-white h-full w-full`}
        >
          <Responsive from={"lg"}>
            <div className="w-60 fixed top-0 bottom-0 transition-all z-50 h-full">
              <MainNavigation balance={balance} />
            </div>
          </Responsive>
          <Responsive from={"md"} to={"lg"}>
            <div className="w-20 fixed top-0 l-0 transition-all z-50 h-full">
              <TabletNavigation />
            </div>
          </Responsive>
          {isShowLeftContent && (
            <Responsive from={"md"}>
              <div className="w-[360px] absolute left-20 lg:left-60 top-0 transition-all z-50 h-full">
                <LeftSubContent />
              </div>
            </Responsive>
          )}
          {/* <div
          className={`flex flex-col justify-start items-stretch text-gray-100 relative md:pl-64 min-h-[100dvh] h-full`}
        > */}
          {/* ${
              isHiddenHeader ? "" : "pt-[52px]"
            } ${isIOS ? "pb-[68px]" : "pb-14"} ${
              isHasIframe ? "min-h-[100dvh]" : ""
            } */}
          <div
            className={`flex flex-col justify-start items-stretch text-white relative overflow-x-hidden overscroll-y-none overflow-y-auto min-h-[100dvh]
          ${
            isShowLeftContent
              ? "md:pl-[440px] lg:pl-[600px]"
              : "md:pl-20 lg:pl-60 md:pr-[360px]"
          }
            `}
          >
            {children}
          </div>
        </div>
      </div>

      {/* <LandingPage /> */}
    </>
  );
};
