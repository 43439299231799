import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { ReactNode } from "react";

export const Perks = () => {
  return (
    <div className="flex-1 flex flex-col h-full w-full p-4">
      <div className="flex flex-col">
        <div className="text-gray-500">
          Gain exclusive benefits by owning this account's Accesses.
        </div>
        <div className="flex flex-col">
          <div className="text-gray-100 flex gap-1 items-center font-medium py-3 border-b border-dk-gray-800">
            <div>
              Own <span className="text-[#FF1156]">1</span> Access
            </div>{" "}
            <ArrowRightIcon className="w-4 h-4 text-gray-500 stroke-2" />
            <div>To be announced</div>
          </div>
          <div className="text-gray-100 flex gap-1 items-center font-medium py-3 border-b border-dk-gray-800">
            <div>
              Own <span className="text-[#FF1156]">3</span> Accesses
            </div>{" "}
            <ArrowRightIcon className="w-4 h-4 text-gray-500 stroke-2" />
            <div>To be announced</div>
          </div>
          <div className="text-gray-100 flex gap-1 items-center font-medium py-3 border-b border-dk-gray-800">
            <div>
              Own <span className="text-[#FF1156]">5</span> Accesses
            </div>{" "}
            <ArrowRightIcon className="w-4 h-4 text-gray-500 stroke-2" />
            <div>To be announced</div>
          </div>
        </div>
      </div>
    </div>
  );
};
