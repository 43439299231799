import { MainLayoutContent } from "components/layouts/MainLayoutContent";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";
import React from "react";

const SearchUser = React.lazy(() => import("../page/SearchUser"));
const routes: AppRouteType[] = [
  {
    path: slugs.searchUser,
    component: SearchUser,
    container: MainLayoutContent,
    isAuthNeeded: true,
  },
];

export default routes;
