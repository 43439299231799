export const NumberNoti = ({
  amount,
  customClass,
  onClick,
}: {
  amount: number;
  customClass: string;
  onClick?: () => void;
}) => {
  return (
    <div
      className={`flex items-center justify-center rounded-full ${customClass}`}
    >
      {amount < 100 ? amount : "99+"}
    </div>
  );
};
