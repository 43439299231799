import { BigNumber } from "ethers";
import { proxy } from "valtio";

interface State {
  data: {
    [key: string]: any;
  };
  accountShares: {
    [key: string]: number;
  };
  isInBidding: {
    [key: string]: boolean;
  };
  // buyPriceAfterFee: {
  //   [key: string]: BigNumber;
  // };
  buyPrice: {
    [key: string]: BigNumber;
  };
  sellPrice: {
    [key: string]: BigNumber;
  };
  holdings: {
    [key: string]: any;
  };
  biddingInfo: {
    [key: string]: any;
  };
  tradingFeeEarned: {
    [key: string]: BigNumber;
  };
  poolSupply: {
    [key: string]: number;
  };
  status: {
    [key: string]: string;
  };
  posts: {
    [key: string]: {
      data: Array<any>;
    };
  };
  activities: {
    [key: string]: Array<any>;
  };
  trades: {
    [key: string]: Array<any>;
  };
  isFTFetch: {
    [key: string]: boolean;
  };
  isLoading: boolean;
}

export const state = proxy<State>();

export const profileUserProxyService = {
  state,
  setData: (data: any, key: string) => {
    try {
      if (state.data === undefined) {
        state.data = {};
      }
      state.data[key] = data;
    } catch (error) {
      console.log("other-profile: ", error);
    }
  },

  setAccountShares: (value: number, key: string) => {
    if (state.accountShares === undefined) {
      state.accountShares = {};
    }
    state.accountShares[key] = value;
  },

  setIsInBidding: (value: boolean, key: string) => {
    if (state.isInBidding === undefined) {
      state.isInBidding = {};
    }
    state.isInBidding[key] = value;
  },

  // setbuyPriceAfterFee: (value: BigNumber, key: string) => {
  //   if (state.buyPriceAfterFee === undefined) {
  //     state.buyPriceAfterFee = {};
  //   }
  //   state.buyPriceAfterFee[key] = value;
  // },

  setBuyPrice: (value: BigNumber, key: string) => {
    if (state.buyPrice === undefined) {
      state.buyPrice = {};
    }
    state.buyPrice[key] = value;
  },

  setSellPrice: (value: BigNumber, key: string) => {
    if (state.sellPrice === undefined) {
      state.sellPrice = {};
    }
    state.sellPrice[key] = value;
  },

  setHoldings: (value: any, key: string) => {
    if (state.holdings === undefined) {
      state.holdings = {};
    }
    state.holdings[key] = value;
  },

  setBiddingInfo: (value: any, key: string) => {
    if (state.biddingInfo === undefined) {
      state.biddingInfo = {};
    }
    state.biddingInfo[key] = value;
  },

  setTradingFeeEarned: (value: BigNumber, key: string) => {
    if (state.tradingFeeEarned === undefined) {
      state.tradingFeeEarned = {};
    }
    state.tradingFeeEarned[key] = value;
  },

  setPoolSupply: (value: number, key: string) => {
    if (state.poolSupply === undefined) {
      state.poolSupply = {};
    }
    state.poolSupply[key] = value;
  },

  setIsFtFetch: (value: boolean, key: string) => {
    if (state.isFTFetch === undefined) {
      state.isFTFetch = {};
    }
    state.isFTFetch[key] = value;
  },

  setStatus: (value: string, key: string) => {
    if (state.status === undefined) {
      state.status = {};
    }
    state.status[key] = value;
  },

  setIsLoading: (value: boolean) => {
    state.isLoading = value;
  },

  setPosts: (data: Array<any>, key: string) => {
    if (state.posts === undefined) {
      state.posts = {};
    }
    if (state.posts[key] === undefined) {
      state.posts[key] = {} as any;
    }

    state.posts[key].data = data;
  },

  setActivities: (data: Array<any>, key: string) => {
    if (state.activities === undefined) {
      state.activities = {};
    }
    state.activities[key] = data;
  },

  setTrades: (data: Array<any>, key: string) => {
    if (state.trades === undefined) {
      state.trades = {};
    }
    state.trades[key] = data;
  },
};
