import { EntryMedium, EntryLarge, EntrySmall } from "./EntrySize";

export enum SizeEntry {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export enum TypeEntry {
  MEDIUM_DEFAULT = "MEDIUM_DEFAULT",
  MEDIUM_TOP_STAKES = "MEDIUM_TOP_STAKES",
  MEDIUM_STAKES = "MEDIUM_STAKES",
  MEDIUM_BANNER_STAKES = "MEDIUM_BANNER_STAKES",
  SMALL_LEADERBOARD = "SMALL_LEADERBOARD",
  SMALL_TRADER_LEADERBOARD = "SMALL_TRADER_LEADERBOARD",
  SMALL_WHALES_LEADERBOARD = "SMALL_WHALES_LEADERBOARD",
  SMALL_EXPLORE = "SMALL_EXPLORE",
  SMALL_HOME_EXPLORE = "SMALL_HOME_EXPLORE",
  SMALL_HOME_LEADERBOARD = "SMALL_HOME_LEADERBOARD",
  SMALL_HOME_TRENDING_TODAY = "SMALL_HOME_TRENDING_TODAY",
  SMALL_HOME_TRADERS_LEADERBOARD = "SMALL_HOME_TRADERS_LEADERBOARD",
  SMALL_HOME_WHALES_LEADERBOARD = "SMALL_HOME_WHALES_LEADERBOARD",
  // {SizeEntry}_{TYPE} ...
}

interface Props {
  size: SizeEntry;
  type: TypeEntry;
  injectProps: any;
  findIndex?: any;
}

export function Entry(props: Props) {
  const { size, injectProps, type, findIndex } = props;
  const commonProps = { ...injectProps, type, findIndex };
  switch (size) {
    case SizeEntry.SMALL:
      return <EntrySmall {...commonProps} />;
    case SizeEntry.MEDIUM:
      return <EntryMedium {...commonProps} />;
    case SizeEntry.LARGE:
    default:
      return <EntryLarge {...commonProps} />;
  }
}
