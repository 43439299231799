export function deepArrayCompare(arr1: any[], arr2: any[]) {
  // Check if both inputs are arrays
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Recursively compare each element in the arrays
  for (let i = 0; i < arr1.length; i++) {
    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
      // If both elements are arrays, recursively compare them
      if (!deepArrayCompare(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      // If elements are not arrays, compare them directly
      return false;
    }
  }

  // If we reached this point, the arrays are deeply equal
  return true;
}
