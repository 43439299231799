import { ReactComponent as Gift } from "assets/icons/coming-soon.svg";
import { ReactComponent as Speaker } from "assets/icons/speaker.svg";
import { ReactComponent as Camera } from "assets/icons/camera.svg";
import { ReactComponent as Eyes } from "assets/icons/eyes.svg";
import { ReactComponent as Lock } from "assets/icons/lock.svg";

import { ReactNode } from "react";

interface Props {
  type?: "gift" | "speaker" | "camera" | "eyes" | "lock";
  title?: string;
  des?: string | ReactNode;
}
export const ComingSoon = ({ type = "gift", title, des }: Props) => {
  return (
    <div className="p-6 flex-1 flex flex-col justify-center items-center gap-4 mx-auto h-full">
      {type === "gift" ? (
        <Gift className="w-24" />
      ) : type === "camera" ? (
        <Camera className="w-24" />
      ) : type === "speaker" ? (
        <Speaker className="w-24" />
      ) : type === "lock" ? (
        <Lock className="w-24" />
      ) : (
        <Eyes className="w-24" />
      )}
      <div className="gap-2 flex flex-col justify-center items-center">
        {title && <div className="text-xl text-center">{title}</div>}
        {des && <div className="text-slate-300 text-center ">{des}</div>}
      </div>
    </div>
  );
};
