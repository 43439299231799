import { MainLayoutSimple } from "components/layouts/MainLayoutSimple";
import { AppRouteType } from "navigation/registryRoutes";
import slugs from "navigation/slugs";

import React from "react";
const AccountVerify = React.lazy(() => import("../page"));

const routes: AppRouteType[] = [
  {
    path: slugs.accountVerify,
    component: AccountVerify,
    container: MainLayoutSimple,
    isAuthNeeded: false,
  },
];

export default routes;
