import { DOMAIN, DOMAIN_PROFILE } from ".";

export const shareProfile = (username: string) => {
  console.log('username: ', username);
  let otherProfileUrl =
    "https://twitter.com/intent/tweet?url=" +
    // encodeURIComponent(`${DOMAIN_PROFILE}/${username}`);
    // encodeURIComponent(`${DOMAIN_PROFILE}/profile/${username}`);
    encodeURIComponent(`${DOMAIN_PROFILE}/s/${username}`);

  return otherProfileUrl;
};
export const sharePost = (content: string, slug: string) => {
  let postLinkUrl =
    "https://twitter.com/intent/tweet?url=" +
    // encodeURIComponent(content + "\n" + `${DOMAIN}/${slug}`);
    // encodeURIComponent(content + "\n" + `${DOMAIN}/feed/${slug}`);
    encodeURIComponent(content + "\n" + `${DOMAIN}/s/f/${slug}`);

  return postLinkUrl;
};
