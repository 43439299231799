import React from "react";

const LoadingItemActivity: React.FC = () => {
  return (
    <div className="w-full flex flex-col">
      <div
        className={`flex flex-col loadingAnimation gap-4 justify-between items-center w-full rounded-lg py-3 px-4`}
      >
        <div className="flex items-center gap-4 w-full ">
          <div className="h-[48px] min-w-[48px] rounded-full loadingAnimation"></div>

          <div className="w-full flex flex-col gap-3">
            <div className="flex items-center gap-4 w-full truncate">
              <div className="w-full rounded h-3 block loadingAnimation"></div>
              <div className="w-full rounded h-3 block loadingAnimation"></div>
            </div>
            <div className="w-full rounded h-2 block loadingAnimation"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingItemActivity;
