import { proxy } from "valtio";

interface State {
  data: {
    countSocial: number;
    countFinance: number;
    countSGE: number;
  };
}

const defaultState: State = {
  data: { countSocial: 0, countFinance: 0, countSGE: 0 },
};

export const notiProxyState = proxy<State>(defaultState);
