import { forYouService } from "./for-you";
import { hotService } from "./hot";
import { recentService } from "./recent";
import { buyBackService } from "./33";

export const feedState = {
  forYouService,
  hotService,
  recentService,
  buyBackService,
};
  